import React, { Component } from "react";
import CourseTable from "./GroupTable";
import CourseAdd from "./GroupAdd";
import { LinearProgress } from "@material-ui/core";
import Snack from "../../Snackbar/Snack";
import { PostData, GetData, PutData, DeleteData, GetDataExtra } from "../../../api/service";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css

export default class DepartmentUsers extends Component {
  constructor(props) {
    super(props);

    this.state = {
      show: false,
      isLoading: true,
      status: false,
      message: "",
      table_data: [],
      edit: false,
      edit_data: [],
      departments: [],
      isDeptLoaded: false,
      courses: [],
      isCourseLoaded: false,
      initialData: {
        courses: [],
        departments: [],
        streams: [],
        isInLoaded: false,
      },
    };
  }

  __getData() {
    GetData(`/${this.props.type}/getmessagebytype`).then((resp) => {
      if (resp.length > 0) {
        this.setState({
          table_data: resp,
          isLoading: false,
        });
      } else {
        this.setState({
          table_data: [],
          isLoading: false,
        });
      }
    });
  }

  componentDidMount() {
    this.__getData();

    // get other data
    GetData(`/getdepartments`).then((resp) => {
      //let data = resp.filter((el)=>el.stream==this.state.stream)
      this.setState({
        departments: resp,
        isDeptLoaded: true,
      });
    });

    GetDataExtra(`/getcourse`).then((resp) => {
      //let data = resp.filter((el)=>el.stream==this.state.stream)
      this.setState({
        courses: resp,
        isCourseLoaded: true,
      });
    });
  }

  componentDidUpdate(prevProps) {
    if (this.props.type != prevProps.type) {
      this.__getData();
    }
  }

  onToggleClick = (id, value) => {
    PostData(`/change-verification-status-message`, {
      id,
      new_status: value
    }).then(
      (resp) => {
        //console.log(resp);
        this.setState({
          show: false,
          status: true,
          message: resp,
        });
        this.__getData();
      }
    );
  }

  onAddClick() {
    this.setState({
      show: !this.state.show,
      edit: false,
    });
  }

  setData(data) {
    //send the data to the server

    PostData(`/add-message`, data).then((resp) => {
      //console.log(resp);
      this.setState({
        show: false,
        status: true,
        message: resp,
        edit: false,
      });
      this.__getData();
    });
  }

  actionClick(el) {
    this.setState({
      edit: true,
      edit_data: el,
      show: true,
    });
  }

  esetData(data) {
    //send the data to the server
    console.log(data);

    PutData(`/${this.props.apikey}/${data.id}/update-message`, data).then(
      (resp) => {
        //console.log(resp);
        this.setState({
          show: false,
          status: true,
          message: resp,
        });
        this.__getData();
      }
    );
  }

  onDeleteClick(id) {
    console.log(id);
    this.setState({
      show: false,
    });
    confirmAlert({
      title: "Confirm to delete",
      message: "Are you sure to do this.",
      buttons: [
        {
          label: "Yes",
          onClick: this.onDeleteConfirm.bind(this, id),
        },
        {
          label: "No",
        },
      ],
    });
  }

  onDeleteConfirm(id) {
    //console.log(id);
    DeleteData(`/${this.props.apikey}/${id}/delete-message`).then((resp) => {
      this.setState({
        show: false,
        status: true,
        message: resp,
      });
      this.__getData();
    });
  }

  render() {
    return (
      <div>
        {this.state.status ? (
          <Snack
            open={this.state.status}
            message={this.state.message}
            handleClose={() =>
              this.setState({
                status: false,
                message: "",
              })
            }
          />
        ) : null}
        {this.state.isLoading ? <LinearProgress color="secondary" /> : null}

        <CourseAdd
          show={this.state.show}
          handleClose={this.onAddClick.bind(this)}
          setData={this.setData.bind(this)}
          edit={this.state.edit}
          edit_data={this.state.edit_data}
          esetData={this.esetData.bind(this)}
          onDeleteClick={this.onDeleteClick.bind(this)}
          departments={this.state.departments}
          isDeptLoaded={this.state.isDeptLoaded}
          courses={this.state.courses}
          isCourseLoaded={this.state.isCourseLoaded}
          isInLoaded={this.state.isInLoaded}
          initialData={this.state.initialData}
          type={this.props.type}
        />

        {!this.state.isLoading ? (
          <CourseTable
            table_data={this.state.table_data}
            isLoading={this.state.isLoading}
            onAddClick={this.onAddClick.bind(this)}
            actionClick={this.actionClick.bind(this)}
            deleteClick={this.onDeleteClick.bind(this)}
            onToggleClick={this.onToggleClick.bind(this)}
          />
        ) : null}
      </div>
    );
  }
}
