import React, { Component } from 'react'
import {
  withStyles,
  Drawer,
  Typography,
  Divider,
  TextField,
  Button,
  AppBar,
  Toolbar,
  IconButton,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Grid,
} from '@material-ui/core'
import { GetData } from '../../../api/service'
import Sel from 'react-select'

const drawerWidth = 500

const styles = (theme) => ({
  root: {
    width: drawerWidth,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: '100%',
  },
  textStyle: {
    fontWeight: 400,
  },
  paddingForm: {
    padding: '20px',
  },
  formControl: {
    minWidth: '100%',
  },
})

class GroupAdd extends Component {
  constructor(props) {
    super(props)

    this.state = {
      name: '',
      id: '',
      
      
    }

    this.onSubmit = this.onSubmit.bind(this)
    this.onChange = this.onChange.bind(this)
  }

  onChange(e) {
    this.setState({
      [e.target.name]: e.target.value,
    })
  }

  componentDidMount() {
    if (this.props.edit) {
      this.setState({
        ...this.props.edit_data,
      })
    } else {
      this.setState({
        name: '',
        id: '',
        
      })
    }

   
  }

  componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {
      if (this.props.edit) {
        this.setState({
          ...this.props.edit_data,
        })
      } else {
        this.setState({
          name: '',
          id: '',
         
        })
      }
    }
  }

  onSubmit(e) {
    e.preventDefault()

    let { id, name, type } = this.state

    const data = {
      id,
      name,
      
    }

    if (this.props.edit) {
      this.props.esetData(data)
    } else {
      this.props.setData(data)
    }

    this.setState({
     name: '',
      id: '',
     
    })
  }

  onDeleteClick(id) {
    this.props.onDeleteClick(id)
  }

  render() {
    let { classes } = this.props
   
    return (
      <div>
        <Drawer
          className={classes.root}
          anchor="right"
          open={this.props.show}
          onClose={this.props.handleClose}
          classes={{
            paper: classes.drawerPaper,
          }}
        >
          <AppBar
            position="static"
            color={this.props.edit ? 'secondary' : 'primary'}
          >
            <Toolbar>
              <Typography variant="h6" color="inherit">
                {this.props.edit ? 'Edit DATA' : 'Add New'}
              </Typography>
            </Toolbar>
          </AppBar>

          <div className={classes.paddingForm}>
            <form onSubmit={this.onSubmit}>
              <Grid container spacing={2}>
                

                <Grid item xs={12} lg={12}>
                  <TextField
                    label="Name (SSR YYYY)"
                    name="name"
                    className={classes.textField}
                    type="text"
                    autoComplete="current-password"
                    margin="normal"
                    required
                    InputLabelProps={{
                      classes: {
                        root: classes.textStyle,
                      },
                    }}
                    value={this.state.name}
                    onChange={this.onChange}
                  />
                </Grid>
              </Grid>
              <br />
              <div>
                <Button
                  type="submit"
                  variant="contained"
                  color={this.props.edit ? 'secondary' : 'primary'}
                  className={classes.button}
                >
                  {this.props.edit ? 'Update' : 'Submit'}
                </Button>

                {this.props.edit ? (
                  <span>
                    &nbsp;
                    <Button
                      type="button"
                      variant="contained"
                      color="secondary"
                      className={classes.button}
                      onClick={this.onDeleteClick.bind(
                        this,
                        this.props.edit_data.id,
                      )}
                    >
                      Delete
                    </Button>
                  </span>
                ) : null}
              </div>
            </form>
          </div>
        </Drawer>
      </div>
    )
  }
}

export default withStyles(styles)(GroupAdd)
