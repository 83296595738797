import React from 'react'
import ProfileInputForm from './Profile/ProfileInputForm'
import ProfileSingleInputForm from './Profile/ProfileSingleInputForm'

export default function DepartmentalProfile({dept_code, data}) {
  console.log("🚀 ~ file: DepartmentalProfile.js ~ line 6 ~ DepartmentalProfile ~ data", data)
  return (
    <div>

        <ProfileSingleInputForm
            dept_code={dept_code}
            inputs={[{
                name: 'estd',
                label:'Date of establishment',
                value: data.estd
            },{
                name: 'examination_system',
                label:'Examination System: Annual/Semester/Choice Based Credit System',
                value: data.examination_system
            }]}
        />
        <ProfileInputForm
            dept_code={dept_code}
            title="Names of programmes offered"
            mainValue={`programs_offered`}
            inputs={[
                {
                    name: 'Title',
                    label:'Title',
                    value: ''
                },
                {
                    name: 'Field of Specialisation',
                    label:'Field of Specialisation',
                    value: ''
                },
                {
                    name: 'Sanctioned Intake',
                    label:'Sanctioned Intake',
                    value: ''
                },
                {
                    name: 'Duration(Year)',
                    label:'Duration(Year)',
                    value: ''
                },
                {
                    name: 'Year of Starting',
                    label:'Year of Starting',
                    value: ''
                }
            ]}
            data={data.programs_offered}
        />

<ProfileInputForm
            dept_code={dept_code}
            title="Courses in collaboration with other universities, industries, foreign institutions, etc."
            mainValue={`collaboration_course`}
            inputs={[
                {
                    name: 'Title',
                    label:'Title',
                    value: ''
                },
                {
                    name: 'Field of Specialisation',
                    label:'Field of Specialisation',
                    value: ''
                },
                {
                    name: 'University/Industry/Institute name',
                    label:'University/Industry/Institute name',
                    value: ''
                },
                {
                    name: 'Sanctioned Intake',
                    label:'Sanctioned Intake',
                    value: ''
                },
                {
                    name: 'Duration(Year)',
                    label:'Duration(Year)',
                    value: ''
                },
                {
                    name: 'Year of Starting',
                    label:'Year of Starting',
                    value: ''
                },
                {
                    name: 'file',
                    label:'file',
                    value: ''
                }
            ]}
            data={data.collaboration_course}
        />


<ProfileInputForm
            dept_code={dept_code}
            title="Number of teaching posts sanctioned, filled and actual (Associate Professors/Asst.
                Professors/others)"
            mainValue={`teaching_post`}
            inputs={[
                {
                    name: 'Positions',
                    label:'Positions',
                    value: ''
                },
                {
                    name: 'Sanctioned',
                    label:'Sanctioned',
                    value: ''
                },
                {
                    name: 'Filled',
                    label:'Filled',
                    value: ''
                },
                {
                    name: 'Actual',
                    label:'Actual',
                    value: ''
                }
                
            ]}
            data={data.teaching_post}
        />


<ProfileInputForm
            dept_code={dept_code}
            title="Programme-wise Student Teacher Ratio"
            mainValue={`student_teacher_ratio`}
            inputs={[
                {
                    name: 'Name of Programme',
                    label:'Name of Programme',
                    value: ''
                },
                {
                    name: 'Student teacher ratio',
                    label:'Student teacher ratio',
                    value: ''
                }
                
            ]}
            data={data.student_teacher_ratio}
        />



<ProfileInputForm
            dept_code={dept_code}
            title="Number of academic support staff (technical)"
            mainValue={`academic_support_staff_technical`}
            inputs={[
                {
                    name: 'Name of Post',
                    label:'Name of Post',
                    value: ''
                },
                {
                    name: 'Sanctioned',
                    label:'Sanctioned',
                    value: ''
                },
                {
                    name: 'Filled',
                    label:'Filled',
                    value: ''
                },
                {
                    name: 'Actual',
                    label:'Actual',
                    value: ''
                }
                
            ]}
            data={data.academic_support_staff_technical}
        />

<ProfileInputForm
            dept_code={dept_code}
            title="Number of academic support staff (non-Technical)"
            mainValue={`academic_support_staff_non_technical`}
            inputs={[
                {
                    name: 'Name of Post',
                    label:'Name of Post',
                    value: ''
                },
                {
                    name: 'Sanctioned',
                    label:'Sanctioned',
                    value: ''
                },
                {
                    name: 'Filled',
                    label:'Filled',
                    value: ''
                },
                {
                    name: 'Actual',
                    label:'Actual',
                    value: ''
                }
                
            ]}
            data={data.academic_support_staff_non_technical}
        />


<ProfileInputForm
            dept_code={dept_code}
            title="Ongoing Research Projects"
            mainValue={`ongoing_research_projects`}
            inputs={[
                {
                    name: 'Project Title',
                    label:'Project Title',
                    value: ''
                },
                {
                    name: 'Funding Agency',
                    label:'Funding Agency',
                    value: ''
                },
                {
                    name: 'Year',
                    label:'Year',
                    value: ''
                },
                {
                    name: 'Grant (Rs. Lakhs)',
                    label:'Grant (Rs. Lakhs)',
                    value: ''
                },
                {
                    name: 'Principal Investigator',
                    label:'Principal Investigator',
                    value: ''
                },
                {
                    name: 'file',
                    label:'file',
                    value: ''
                }
                
            ]}
            data={data.ongoing_research_projects}
        />


<ProfileInputForm
            dept_code={dept_code}
            title="Completed Research Projects"
            mainValue={`completed_research_projects`}
            inputs={[
                {
                    name: 'Project Title',
                    label:'Project Title',
                    value: ''
                },
                {
                    name: 'Funding Agency',
                    label:'Funding Agency',
                    value: ''
                },
                {
                    name: 'Year',
                    label:'Year',
                    value: ''
                },
                {
                    name: 'Grant (Rs. Lakhs)',
                    label:'Grant (Rs. Lakhs)',
                    value: ''
                },
                {
                    name: 'Principal Investigator',
                    label:'Principal Investigator',
                    value: ''
                },
                {
                    name: 'file',
                    label:'file',
                    value: ''
                }
                
            ]}
            data={data.completed_research_projects}
        />



<ProfileInputForm
            dept_code={dept_code}
            title="Departmental/Inter-institutional collaborative projects"
            mainValue={`collaborative_projects`}
            inputs={[
                {
                    name: 'Project Title',
                    label:'Project Title',
                    value: ''
                },
                {
                    name: 'Funding Agency',
                    label:'Funding Agency',
                    value: ''
                },

                {
                    name: 'National/International',
                    label:'National/International',
                    value: ''
                },
                {
                    name: 'Year',
                    label:'Year',
                    value: ''
                },
                {
                    name: 'Grant (Rs. Lakhs)',
                    label:'Grant (Rs. Lakhs)',
                    value: ''
                },
                {
                    name: 'Principal Investigator',
                    label:'Principal Investigator',
                    value: ''
                },
                {
                    name: 'file',
                    label:'file',
                    value: ''
                }
                
            ]}
            data={data.collaborative_projects}
        />





<ProfileInputForm
            dept_code={dept_code}
            title="Research Publications"
            mainValue={`research_publications`}
            inputs={[
                {
                    name: 'Project Title',
                    label:'Project Title',
                    value: ''
                },
                {
                    name: 'Author (s)',
                    label:'Author (s)',
                    value: ''
                },

                {
                    name: 'Journal',
                    label:'Journal',
                    value: ''
                },
                {
                    name: 'National/International',
                    label:'National/International',
                    value: ''
                },
                {
                    name: 'Citation Index',
                    label:'Citation Index',
                    value: ''
                },
                {
                    name: 'Impact Factor',
                    label:'Impact Factor',
                    value: ''
                },
                {
                    name: 'H Index',
                    label:'H Index',
                    value: ''
                },
                {
                    name: 'file',
                    label:'file',
                    value: ''
                }
                
            ]}
            data={data.research_publications}
        />



<ProfileInputForm
            dept_code={dept_code}
            title="Chapter in Books"
            mainValue={`chapter_books`}
            inputs={[
                {
                    name: 'Project Title',
                    label:'Project Title',
                    value: ''
                },
                {
                    name: 'Author (s)',
                    label:'Author (s)',
                    value: ''
                },

                {
                    name: 'Book',
                    label:'Book',
                    value: ''
                },
                {
                    name: 'National/International',
                    label:'National/International',
                    value: ''
                },
               
                {
                    name: 'ISBN',
                    label:'ISBN',
                    value: ''
                },
                {
                    name: 'file',
                    label:'file',
                    value: ''
                }
                
            ]}
            data={data.chapter_books}
        />




<ProfileInputForm
            dept_code={dept_code}
            title="Faculty selected nationally / internationally to visit other laboratories/institutions industries in India and abroad"
            mainValue={`selected_faculty`}
            inputs={[
                {
                    name: 'Faculty Name',
                    label:'Faculty Name',
                    value: ''
                },
                {
                    name: 'National/International',
                    label:'National/International',
                    value: ''
                },
               
                {
                    name: 'Laboratories/Institutions',
                    label:'Laboratories/Institutions',
                    value: ''
                },
                {
                    name: 'file',
                    label:'file',
                    value: ''
                }
                
            ]}
            data={data.selected_faculty}
        />



<ProfileInputForm
            dept_code={dept_code}
            title="Faculty serving in a) National committees b) International committees c) Editorial Boards d) any other (please	specify"
            mainValue={`serving_faculty`}
            inputs={[
                {
                    name: 'Faculty Name',
                    label:'Faculty Name',
                    value: ''
                },
                {
                    name: 'National/International',
                    label:'National/International',
                    value: ''
                },
               
                {
                    name: 'Editorial Board',
                    label:'Editorial Board',
                    value: ''
                },
                {
                    name: 'file',
                    label:'file',
                    value: ''
                }
                
            ]}
            data={data.serving_faculty}
        />



<ProfileInputForm
            dept_code={dept_code}
            title="Faculty Improvement Programs (UGC, ASC, Refresher/orientation programs, workshops, training programs and similar programs)"
            mainValue={`involvement_faculty`}
            inputs={[
                {
                    name: 'Faculty Name',
                    label:'Faculty Name',
                    value: ''
                },
                {
                    name: 'Attended Workshops/orientation programmes',
                    label:'Attended Workshops/orientation programmes',
                    value: ''
                },
                {
                    name: 'file',
                    label:'file',
                    value: ''
                }
               
               
                
            ]}
            data={data.involvement_faculty}
        />


<ProfileInputForm
            dept_code={dept_code}
            title="Awards/recognitions received by faculties"
            mainValue={`awards_faculty`}
            inputs={[
                {
                    name: 'Faculty Name',
                    label:'Faculty Name',
                    value: ''
                },
                {
                    name: 'Awards/recognitions',
                    label:'Awards/recognitions',
                    value: ''
                },
                {
                    name: 'Year',
                    label:'Year',
                    value: ''
                },
                {
                    name: 'file',
                    label:'file',
                    value: ''
                }
               
                
            ]}
            data={data.awards_faculty}
        />


<ProfileInputForm
            dept_code={dept_code}
            title="Seminars/Conferences/Workshops organized by the department"
            mainValue={`seminar_department`}
            inputs={[
                {
                    name: 'Date',
                    label:'Date',
                    value: ''
                },
                {
                    name: 'Title of Workshop/Conference/Seminar',
                    label:'Title of Workshop/Conference/Seminar',
                    value: ''
                },
                {
                    name: 'Source of Funding and Participants',
                    label:'Source of Funding and Participants',
                    value: ''
                },
                {
                    name: 'file',
                    label:'file',
                    value: ''
                }
               
                
            ]}
            data={data.seminar_department}
        />

<ProfileInputForm
            dept_code={dept_code}
            title="Departmental Publications"
            mainValue={`publications_department`}
            inputs={[
                {
                    name: 'Type of Publications',
                    label:'Type of Publications',
                    value: ''
                },
                {
                    name: 'Name',
                    label:'Name',
                    value: ''
                },
                {
                    name: 'Year',
                    label:'Year',
                    value: ''
                },
                {
                    name: 'file',
                    label:'file',
                    value: ''
                }
               
                
            ]}
            data={data.publications_department}
            
        />



<ProfileInputForm
            dept_code={dept_code}
            title="Student projects : In-house projects including inter-departmental projects/Internships/Summer Project"
            mainValue={`student_internal`}
            inputs={[
                {
                    name: 'Name of the Student',
                    label:'Name of the Student',
                    value: ''
                },
                
                {
                    name: 'Project Title',
                    label:'Project Title',
                    value: ''
                },
                {
                    name: 'Year',
                    label:'Year',
                    value: ''
                },
               
               
                
            ]}
            data={data.student_internal}
            
        />


<ProfileInputForm
            dept_code={dept_code}
            title="Student projects : Projects in collaboration with other institutions/ Internship/ Summer Project"
            mainValue={`student_other`}
            inputs={[
                {
                    name: 'Name of the Student',
                    label:'Name of the Student',
                    value: ''
                },
                {
                    name: 'Name of the Laboratory/Institution',
                    label:'Name of the Laboratory/Institution',
                    value: ''
                },
                {
                    name: 'Project Title',
                    label:'Project Title',
                    value: ''
                },
                {
                    name: 'Year',
                    label:'Year',
                    value: ''
                },
                {
                    name: 'file',
                    label:'file',
                    value: ''
                }
               
               
                
            ]}
            data={data.student_other}
            
        />


<ProfileInputForm
            dept_code={dept_code}
            title="Student profile programme-wise"
            mainValue={`student_profile_programme`}
            inputs={[
                {
                    name: 'Name of the Programme',
                    label:'Name of the Programme',
                    value: ''
                },
                {
                    name: 'No. of students (1st Year) (MALE)',
                    label:'No. of students (1st Year) (MALE)',
                    value: ''
                },
                {
                    name: 'No. of students (1st Year) (FEMALE)',
                    label:'No. of students (1st Year) (FEMALE)',
                    value: ''
                },
                {
                    name: 'No. of students (Final Year) (MALE)',
                    label:'No. of students (Final Year) (MALE)',
                    value: ''
                },
                {
                    name: 'No. of students (Final Year) (FEMALE)',
                    label:'No. of students (Final Year) (FEMALE)',
                    value: ''
                },
                {
                    name: 'Pass Percentage (MALE)',
                    label:'Pass Percentage (MALE)',
                    value: ''
                },
                {
                    name: 'Pass Percentage (FEMALE)',
                    label:'Pass Percentage (FEMALE)',
                    value: ''
                },
               
               
                
            ]}
            data={data.student_profile_programme}
            
        />



<ProfileInputForm
            dept_code={dept_code}
            title="Diversity of students"
            mainValue={`student_diversity`}
            inputs={[
                {
                    name: 'Name of the Programme',
                    label:'Name of the Programme',
                    value: ''
                },
                {
                    name: 'No students from the same university',
                    label:'No students from the same university',
                    value: ''
                },
                {
                    name: 'No of students from other universities within the State',
                    label:'No of students from other universities within the State',
                    value: ''
                },
                {
                    name: 'No of students from universities outside the State',
                    label:'No of students from universities outside the State',
                    value: ''
                },
                {
                    name: 'No of students from other countries',
                    label:'No of students from other countries',
                    value: ''
                }
               
               
                
            ]}
            data={data.student_diversity}
            
        />
        


<ProfileInputForm
            dept_code={dept_code}
            title="How many students have cleared Civil Services and Defence Services examinations, NET, SET, GATE and other competitive examinations? Give details category-wise."
            mainValue={`student_cleared`}
            inputs={[
                {
                    name: 'Examinations',
                    label:'Examinations',
                    value: ''
                },
                {
                    name: 'No. of Students cleared examination',
                    label:'No. of Students cleared examination',
                    value: ''
                },
                
               
               
                
            ]}
            data={data.student_cleared}
            
        />

<ProfileInputForm
            dept_code={dept_code}
            title="Student progressions: UG to PG / PG to M. Phil / PG to Ph.D. / Employed"
            mainValue={`student_progressions`}
            inputs={[
                {
                    name: 'Year',
                    label:'Year',
                    value: ''
                },
                {
                    name: 'Student progression',
                    label:'Student progression',
                    value: ''
                },
                {
                    name: 'Number',
                    label:'Number',
                    value: ''
                },
                {
                    name: 'Percentage against enrolled',
                    label:'Percentage against enrolled',
                    value: ''
                },
                
               
               
                
            ]}
            data={data.student_progressions}
            
        />

    </div>
  )
}
