import React, { Component } from "react";
import {
  Typography,
  Paper,
  Tabs as Tabs1,
  Tab as Tab1,
} from "@material-ui/core";
import { Tabs, Tab } from "react-bootstrap";
import { GetData } from "../../api/service";
import CollegeEvent from "./CollegeEvent";
import CollegeOthers from "./CollegeOthers";
import CollegeFaculty from "./CollegeFaculty";
import CollegeCommittee from "./CollegeCommittee";
import CollegeFacilities from "./CollegeFacilities";
import CollegeHoliday from "./CollegeHoliday";
import CollegeGB from "./CollegeGB";
import CollegeFeatured from "./CollegeFeatured";
import CollegeAlumni from "./CollegeAlumni";
import AcademicAbout from "../Academics/AcademicAbout";
import CollegeOtherPrograms from "./CollegeOtherPrograms";
import CollegeSwoc from "./CollegeSwoc";

const smallTab = [
  {
    title: "Notifications",
    active: 0,
  },
  {
    title: "Others",
    active: 1,
  },
  {
    title: "Staff",
    active: 2,
  },
  {
    title: "Committee",
    active: 3,
  },
  {
    title: "Facilities",
    active: 4,
  },
  {
    title: "Holiday List",
    active: 5,
  },
  {
    title: "Governing Body",
    active: 6,
  },
  {
    title: "Featured Notification",
    active: 7,
  },
  {
    title: "Prominent Alumni",
    active: 8,
  },
  {
    title: "Alumni Committee",
    active: 9,
  },
  {
    title: "Other Programs",
    active: 10,
  },
  {
    title: "SWOC",
    active: 11,
  },
 
];

class CollegeHomeLanding extends Component {
  state = {
    active: 0,
    dept_code: "PRAG",

    data: {},
    isDeptLoaded: false,
  };

  __getDeptData = (dept_code) => {
    GetData(`/admin/${dept_code}/getsingledeptdata`).then((resp) => {
      //console.log(resp)
      if (resp != false) {
        this.setState({
          data: resp,
          active: 0,
          isDeptLoaded: true,
        });
      } else {
        this.setState({
          data: {},
          active: 0,
          isDeptLoaded: true,
        });
      }
    });
  };

  componentDidMount() {
    this.__getDeptData(this.state.dept_code);
  }

  render() {
    return (
      <div>
        <Typography variant="h6">Pragjyotish College</Typography>
        <Typography variant="subtitle2">
          Code: {this.state.dept_code}
        </Typography>

        <br />

        {this.state.isDeptLoaded && <Paper square >
          <Tabs1
            value={this.state.active}
            indicatorColor="primary"
            textColor="primary"
            size="small"
            variant="scrollable"
           
            onChange={(e, newValue) =>
              this.setState({
                active: newValue,
              })
            }
            aria-label=""
          >
            {smallTab.map((el, index) => (
              <Tab1 value={el.active} key={el.active} label={el.title} />
            ))}
          </Tabs1>
         
        </Paper>}
        <br />
        {this.state.active == 0 ? (
          <CollegeEvent {...this.state.data} dept_code={this.state.dept_code} />
        ) : null}

        {this.state.active == 1 ? (
          <CollegeOthers
            {...this.state.data}
            dept_code={this.state.dept_code}
          />
        ) : null}

        {this.state.active == 2 ? (
          <CollegeFaculty
            {...this.state.data}
            dept_code={this.state.dept_code}
          />
        ) : null}

        {this.state.active == 3 ? (
          <CollegeCommittee
            {...this.state.data}
            dept_code={this.state.dept_code}
          />
        ) : null}

        {this.state.active == 4 ? (
          <CollegeFacilities
            {...this.state.data}
            dept_code={this.state.dept_code}
          />
        ) : null}

        {this.state.active == 5 ? (
          <CollegeHoliday
            {...this.state.data}
            dept_code={this.state.dept_code}
          />
        ) : null}

        {this.state.active == 6 ? (
          <CollegeGB title="Governing Body" {...this.state.data} dept_code={`GOVERNING_BODY`} />
        ) : null}

        {this.state.active == 7 ? (
          <CollegeFeatured
            {...this.state.data}
            dept_code={this.state.dept_code}
          />
        ) : null}

        {this.state.active == 8 ? (
          <CollegeAlumni {...this.state.data} dept_code={`PRAG`} />
        ) : null}


        {this.state.active == 10 ? (
          <CollegeOtherPrograms {...this.state.data} dept_code={this.state.dept_code} />
        ) : null}

        {this.state.active == 11 ? (
          <CollegeSwoc {...this.state.data} dept_code={this.state.dept_code} />
        ) : null}

        {/* {this.state.isDeptLoaded ? (<Tabs activeKey={this.state.active} onSelect={k => this.setState({ active: k })}>


               


                    {smallTab.map((el, index) =>
                        <Tab key={index} eventKey={index} title={el.title}>
                            <br />

                            {this.state.active == 0 ? (<CollegeEvent
                                {...this.state.data}
                                dept_code={this.state.dept_code}
                            />) : null}

                            {this.state.active == 1 ? (<CollegeOthers
                                {...this.state.data}
                                dept_code={this.state.dept_code}
                            />) : null}

                            {this.state.active == 2 ? (<CollegeFaculty
                                {...this.state.data}
                                dept_code={this.state.dept_code}
                            />) : null}

                            {this.state.active == 3 ? (<CollegeCommittee
                                {...this.state.data}
                                dept_code={this.state.dept_code}
                            />) : null}

                            {this.state.active == 4 ? (<CollegeFacilities
                                {...this.state.data}
                                dept_code={this.state.dept_code}
                            />) : null}

                            {this.state.active == 5 ? (<CollegeHoliday
                                {...this.state.data}
                                dept_code={this.state.dept_code}
                            />) : null}

                            {this.state.active == 6 ? (<CollegeGB
                                {...this.state.data}
                                dept_code={`GOVERNING_BODY`}
                            />) : null}


{this.state.active == 7 ? (<CollegeFeatured
                                {...this.state.data}
                                dept_code={this.state.dept_code}
                            />) : null}


{this.state.active == 8 ? (<CollegeAlumni
                                {...this.state.data}
                                dept_code={`PRAG`}
                            />) : null}


                        </Tab>

                    )}
                </Tabs>) : "Loading . . ."} */}


                {/* {this.state.active == 9 ? (
          <AcademicAbout title="Alumni Association" type={`ALUMNI_ASSOCIATION`} />
        ) : null} */}

        {this.state.active == 9 ? (
          <CollegeGB title="Alumnii Committee" {...this.state.data} dept_code={`ALUMNI_COMMITTEE`} />
        ) : null}
      </div>
    );
  }
}

export default CollegeHomeLanding;
