import { Paper, Tab, Tabs, Typography } from "@material-ui/core";
import React from "react";
import IQACForm from "./IQACComponent/IQACForm";
import Stretegies from "./IQACComponent/Stretegies";
import FileGroup from "./FileGroupContainer/FileGroup";
import Group from "./GroupContainer/Group";
import SubGroup from "./SubGroupContainer/SubGroup";
import SubSubGroup from "./SubSubGroupContainer/SubSubGroup";
import { Tabs as Tabs1, Tab as Tab1 } from "react-bootstrap";
import IqacAbout from "./IQACComponent/IqacAbout";
import SsrGroup from "./SsrGroupContainer/SsrGroup";
import SsrSubGroup from "./SsrSubGroupContainer/SsrSubGroup";
import SsrSubSubGroup from "./SsrSubSubGroupContainer/SsrSubSubGroup";
import SsrFileGroup from "./SsrFileGroupContainer/SsrFileGroup";
const smallTab = [
  {
    title: "IQAC Notifications",
    active: 0,
  },
  {
    title: "AQAR NAAC Matrices",
    active: 1,
  },
  {
    title: "IQAC About",
    active: 2,
  },
  {
    title: "SSR Matrices",
    active: 3,
  },
];

export default function IqacLanding({ apikey, username, role, history }) {
  const [value, setValue] = React.useState(0);
  const [valueSsr, setValueSsr] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeSsr = (event, newValue) => {
    setValueSsr(newValue);
  };

  const [active, setActive] = React.useState(0);

  return (
    <div>
      <Typography variant="h6">Pragjyotish College</Typography>
      <Typography variant="subtitle2">IQAC</Typography>
      <br />
      <Tabs1 activeKey={active} onSelect={(k) => setActive(k)}>
        {smallTab.map((el, index) => (
          <Tab1 key={index} eventKey={index} title={el.title}>
            <br />

            {active == 0 ? <IQACForm apikey={apikey} /> : null}

            {active == 1 && (
              <>
                <Paper square>
                  <Tabs
                    value={value}
                    indicatorColor="primary"
                    textColor="primary"
                    onChange={handleChange}
                    aria-label=""
                  >
                    <Tab label="Group" />
                    <Tab label="Sub-Group" />
                    <Tab label="Sub-Sub-Group" />
                    <Tab label="Files" />
                  </Tabs>
                </Paper>

                <div style={{ paddingTop: 10 }}>
                  {value == 0 && <Group apikey={apikey} />}
                  {value == 1 && <SubGroup apikey={apikey} />}
                  {value == 2 && <SubSubGroup apikey={apikey} />}
                  {value == 3 && <FileGroup apikey={apikey} />}
                </div>
              </>
            )}

            {active == 2 ? <IqacAbout apikey={apikey} /> : null}


            {active == 3 && (
              <>
                <Paper square>
                  <Tabs
                    value={value}
                    indicatorColor="primary"
                    textColor="primary"
                    onChange={handleChangeSsr}
                    aria-label=""
                  >
                    <Tab label="Group" />
                    <Tab label="Sub-Group" />
                    <Tab label="Sub-Sub-Group" />
                    <Tab label="Files" />
                  </Tabs>
                </Paper>

                <div style={{ paddingTop: 10 }}>
                  {valueSsr == 0 && <SsrGroup apikey={apikey} />}
                  {valueSsr == 1 && <SsrSubGroup apikey={apikey} />}
                  {valueSsr == 2 && <SsrSubSubGroup apikey={apikey} />}
                  {valueSsr == 3 && <SsrFileGroup apikey={apikey} />}
                </div>
              </>
            )}
          </Tab1>
        ))}
      </Tabs1>
    </div>
  );
}
