import React, { Component } from "react";
import {
  FormControlLabel,
  FormGroup,
  Switch,
  Typography,
} from "@material-ui/core";
import { Button } from "react-bootstrap";
import { PostData } from "../../api/service";
import Snack from "../Snackbar/Snack";

class FacultyHomePage extends Component {
  state = {
    value: this.props.value,
    employee_id: this.props.employee_id,
    message: "",
    open: false,
  };

  handleChange = (name, value) => {
    this.setState({
      [name]: value,
    });
  };

  componentDidMount() {
    this.setState({
      value: this.props.value,
    });
  }

  onSubmit = (e) => {
    e.preventDefault();

    let d = {
      value: this.state.value,
      type: this.props.type,
      employee_id: this.props.employee_id,
    };

    PostData(`/admin/${this.props.employee_id}/uploademployeedata`, d).then(
      (resp) => {
        //console.log(resp)
        this.setState({
          message: resp,
          open: true,
        });
      }
    );
  };

  handleChange = (event) => {
    this.setState({ value: event.target.checked ? 1 : 0 });
  };

  render() {
    return (
      <div>
        {this.state.open ? (
          <Snack
            open={this.state.open}
            message={this.state.message}
            handleClose={() => {
              this.setState({
                open: false,
                message: "",
              });
            }}
          />
        ) : null}

        <form onSubmit={this.onSubmit}>
          <Typography variant="h6" gutterBottom>
            {this.props.title}
          </Typography>

          <FormGroup row>
            <FormControlLabel
              control={
                <Switch
                  checked={this.state.value == 1 ? true : false}
                  onChange={this.handleChange}
                  name="1"
                  color="primary"
                />
              }
              label="Live"
            />
          </FormGroup>

          <br />

          <div align="right">
            <Button size="sm" variant="info" type="submit">
              Submit
            </Button>
          </div>
        </form>
      </div>
    );
  }
}

export default FacultyHomePage;
