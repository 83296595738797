import React, { Component } from "react";
import {
  Typography,
  Grid,
  Table,
  TableHead,
  TableCell,
  TableRow,
  TableBody,
  Chip,
  IconButton,
} from "@material-ui/core";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { Button, FormControl, Card } from "react-bootstrap";
import { PostData, GetData, DeleteData, PutData } from "../../api/service";
import Snack from "../Snackbar/Snack";
import FileUpload from "../AddNew/FileUpload";
import Edit from "@material-ui/icons/Edit";
import Delete from "@material-ui/icons/Delete";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css

import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import FacultyCommitteeTask from "./FacultyCommitteeTask";



export default class FacultyCommittee extends Component {
  state = {
    id: "",
    edit: false,
    name: "",
    committee_code: "",
    editor_email: this.props.email,
    members: [],
    member_name: "",
    tasks: "",
    objectives: "",
    minutes: [],
    member_designation: "",
    data: [],
    isDataLoaded: false,
    dept_code: this.props.dept_code,
    message: "",
    open: false,
    loadFile: true,
  };

  handleChange = (name, value) => {
    this.setState({
      [name]: value,
    });
  };

  onChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  __getDeptData = () => {
    GetData(`/${this.props.email}/getcommitteeforme`).then((resp) => {
      //console.log(resp)
      this.setState({
        data: resp,
        isDataLoaded: true,
      });
    });
  };

  componentDidMount() {
    this.__getDeptData();
  }

  onSubmit = (e) => {
    e.preventDefault();

    let d = {
      id: this.state.id,
      name: this.state.name,
      editor_email: this.state.editor_email,
      members: this.state.members,
      committee_code: this.state.committee_code,
      tasks: this.state.tasks,
      objectives: this.state.objectives,
      minutes: this.state.minutes,
    };

    if (this.state.edit) {
      PutData(`/admin/${this.state.id}/editcommittee`, d).then((resp) => {
        //console.log(resp)
        this.setState(
          {
            message: resp,
            open: true,
          },
          () => this.__getDeptData()
        );
        this.cancelEdit();
      });
    } else {
      PostData(`/admin/addcommittee`, d).then((resp) => {
        //console.log(resp)
        this.setState(
          {
            message: resp,
            open: true,
          },
          () => this.__getDeptData()
        );
        this.cancelEdit();
      });
    }
  };

  onDeleteClick = (el) => {
    confirmAlert({
      title: "Confirm to delete",
      message: "Are you sure to do this.",
      buttons: [
        {
          label: "Yes",
          onClick: this.onDeleteConfirm.bind(this, el.id),
        },
        {
          label: "No",
        },
      ],
    });
  };

  onDeleteConfirm = (id) => {
    DeleteData(`/${this.props.apikey}/${id}/deletecommittee`).then((resp) => {
      this.setState(
        {
          message: resp,
          open: true,
        },
        () => this.__getDeptData()
      );
    });
  };

  onEditClick = (el) => {
    this.setState(
      {
        ...el,
        edit: true,
        loadFile: false,
      },
      () => {
        this.setState({
          loadFile: true,
        });
      }
    );
  };

  cancelEdit = () => {
    this.setState(
      {
        id: "",
        name: "",
        committee_code: "",
        editor_email: "",
        members: [],
        member_name: "",
        member_designation: "",
        objectives: "",
        minutes: [],
        tasks: "",
        edit: false,
        loadFile: false,
      },
      () => {
        this.setState({
          loadFile: true,
        });
      }
    );
  };

  addMember = () => {
    let members = this.state.members;

    if (this.state.memeber_name != "" && this.state.member_designation != "") {
      let d = {
        member_name: this.state.member_name,
        member_designation: this.state.member_designation,
      };
      members.push(d);
      this.setState({
        members: members,
        member_name: "",
        member_designation: "",
      });
    }
  };

  onDelete = (index) => {
    let members = this.state.members;

    members.splice(index, 1);
    this.setState({
      members: members,
    });
  };


  onChangeMember = (name, value, index) => {
    let members1 = this.state.members;

    members1[index][name] = value;

    
    this.setState({
      members: members1,
    });
  };

  render() {
    return (
      <div>
        {this.state.open ? (
          <Snack
            open={this.state.open}
            message={this.state.message}
            handleClose={() => {
              this.setState({
                open: false,
                message: "",
              });
            }}
          />
        ) : null}

        <form onSubmit={this.onSubmit}>
          <Typography variant="h6" gutterBottom>
            College Committee
          </Typography>

          <Typography variant="subtitle2" gutterBottom>
            These are the list of assigned committees to your username. You can
            modify the list and update objectives, tasks / activities and upload
            minutes accordingly.
          </Typography>

          <Card>
            <Card.Body>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={3} lg={3}>
                  <label style={{ fontWeight: 500 }}>Committee Code</label>
                  <FormControl
                    as="input"
                    name="committee_code"
                    required
                    onChange={this.onChange}
                    value={this.state.committee_code}
                    placeholder="Committee Code"
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={3} lg={3}>
                  <label style={{ fontWeight: 500 }}>Name</label>
                  <FormControl
                    as="input"
                    name="name"
                    required
                    onChange={this.onChange}
                    value={this.state.name}
                    placeholder="Name"
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <label style={{ fontWeight: 500 }}>
                    Editor Email (Put the email of the person who is going to
                    update the other details.)
                  </label>
                  <FormControl
                    as="input"
                    type="email"
                    name="editor_email"
                    required
                    readOnly={true}
                    onChange={this.onChange}
                    value={this.state.editor_email}
                    placeholder="Editor Email"
                  />
                </Grid>
              </Grid>
              <Grid
                container
                spacing={2}
                style={{
                  backgroundColor: "lightgrey",
                  marginTop: 10,
                  marginBottom: 10,
                }}
              >
                <Grid item xs={12} sm={12} md={2} lg={2}>
                  <label style={{ fontWeight: 500 }}>Member Name</label>
                  <FormControl
                    as="input"
                    name="member_name"
                    onChange={this.onChange}
                    value={this.state.member_name}
                    placeholder="Member Name"
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={2} lg={2}>
                  <label style={{ fontWeight: 500 }}>Designation</label>
                  <FormControl
                    as="input"
                    name="member_designation"
                    onChange={this.onChange}
                    value={this.state.member_designation}
                    placeholder="Designation"
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={2} lg={2}>
                  <Button
                    size="sm"
                    onClick={this.addMember}
                    style={{ marginTop: 35 }}
                  >
                    Add Member
                  </Button>
                </Grid>

                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <label style={{ fontWeight: 500 }}>List of Members</label>
                  <br />
                  {this.state.members.map((el, index) => (
                    <div key={index}>

                        <Grid container>
                            <Grid item xs={5} lg={5} md={5} sm={5}>
                                <input
                                    className="form-control form-control-sm"
                                    value={el.member_name}
                                    onChange={e => this.onChangeMember('member_name', e.target.value,index)}
                                    placeholder="Member Name"
                                 />
                            </Grid>
                            <Grid item xs={5} lg={5} md={5} sm={5}>
                                <input
                                className="form-control form-control-sm"
                                    value={el.member_designation}
                                    onChange={e => this.onChangeMember('member_designation', e.target.value,index)}
                                    placeholder="Designation"
                                 />
                            </Grid>
                            <Grid item xs={2} lg={2} md={2} sm={2}>
                                
                                    <Delete onClick={this.onDelete.bind(this, index)} />
                               
                            </Grid>
                        </Grid>
                      
                    </div>
                  ))}
                </Grid>
              </Grid>

              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <label style={{ fontWeight: 500 }}>Objectives</label>
                  <CKEditor
                    editor={ClassicEditor}
                    config={{
                        simpleUpload: {
                            uploadUrl: `http://localhost/prag-website-new/api/public/addfile-ckeditor`
                        }
                    }}
                    data={this.state.objectives}
                    onReady={(editor) => {
                      // You can store the "editor" and use when it is needed.
                      console.log("Editor is ready to use!", editor);
                    }}
                    
                    onChange={(event, editor) => {
                      const data = editor.getData();
                      this.handleChange(`objectives`, data);
                      console.log({ event, editor, data });
                    }}
                    onBlur={(event, editor) => {
                      console.log("Blur.", editor);
                    }}
                    onFocus={(event, editor) => {
                      console.log("Focus.", editor);
                    }}
                  />
                </Grid>
                
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <label style={{ fontWeight: 500 }}>
                    Upload Minutes (if any)
                  </label>
                  {this.state.loadFile ? (
                    <FileUpload
                      dp={this.state.minutes}
                      type={"COLLEGE_COMMITTEE"}
                      setDp={this.handleChange.bind(this, "minutes")}
                    />
                  ) : null}
                </Grid>

                {this.state.edit && <Grid item xs={12} sm={12} md={12} lg={12}>
                 <FacultyCommitteeTask committee_id={this.state.id} />
                </Grid>}
              </Grid>

              <br />
              <br />

              {this.state.edit ? (
                <div align="right">
                  <Button size="sm" variant="danger" type="submit">
                    Update
                  </Button>
                  &nbsp;
                  <Button
                    size="sm"
                    variant="danger"
                    type="button"
                    onClick={this.cancelEdit}
                  >
                    Cancel
                  </Button>
                </div>
              ) : (
                <div align="right">
                  <Button size="sm" variant="info" type="submit">
                    Final Submit
                  </Button>
                </div>
              )}
            </Card.Body>
          </Card>

          <br />

          <Card>
            <Card.Body>
              {this.state.isDataLoaded ? (
                <div>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell width="2%">#</TableCell>
                        <TableCell width="20%">Name</TableCell>
                        <TableCell width="70%">Members</TableCell>
                        <TableCell>Edit</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {this.state.data.map((el, index) => (
                        <TableRow key={index}>
                          <TableCell>{index + 1}</TableCell>
                          <TableCell>
                            {el.name}
                            <br />
                            Editor:{" "}
                            <span style={{ color: "blue" }}>
                              {el.editor_email
                                ? el.editor_email
                                : "Not-Assigned"}
                            </span>
                          </TableCell>

                          <TableCell>
                            {Array.isArray(el.members) &&
                              el.members.map((el1, index1) => (
                                <Chip
                                  key={index1}
                                  label={`${el1.member_name} : ${el1.member_designation}`}
                                />
                              ))}
                          </TableCell>

                          <TableCell>
                            <Edit onClick={this.onEditClick.bind(this, el)} />
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </div>
              ) : (
                <Typography>Loading . . .</Typography>
              )}
            </Card.Body>
          </Card>
        </form>
      </div>
    );
  }
}
