import React, { Component } from "react";
import {
  Typography,
  Grid,
  Table,
  TableHead,
  TableCell,
  TableRow,
  TableBody,
  Chip,
} from "@material-ui/core";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { Button, FormControl, Card } from "react-bootstrap";
import { PostData, GetData, DeleteData, PutData } from "../../api/service";
import Snack from "../Snackbar/Snack";
import FileUpload from "../AddNew/FileUpload";
import Edit from "@material-ui/icons/Edit";
import Delete from "@material-ui/icons/Delete";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import history from "../../history";
import FileUploadFaculty from "./FileUploadFaculty";
import { SERVER_UPLOAD } from "../../Helpers/GlobalVariables";

class FacultyAward extends Component {
  state = {
    m_type: this.props.m_type,
    employee_id: this.props.employee_id,
    email:this.props.email,
    message: "",
    open: false,
    isLoaded: false,
   area:'',
   achievement:'',
   award:'',
   other_info:'',
   
   awards:[]
  };

  handleChange = (name, value) => {
    this.setState({
      [name]: value,
    });
  };

  onChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };


  onChange1 = (name, index, value) => {
    let d = this.state.awards;
    d[index][name] = value
    this.setState({
      awards: d
    })
  }

  onSetFile = (index, data) => {
    //console.log(data)
    let aw = this.state.awards;
    aw[index]['file'] = data
    this.setState({
      awards: aw
    })
  }

  componentDidMount() {
    //console.log(this.props);

    GetData(`/admin/${this.props.employee_id}/getsingleempolyeedataapi`)
    .then((resp)=>{
        if(resp){
            this.setState({
                ...resp,
          
               
              });
        }
        this.setState({
          isLoaded: true
        })
    })

    
  }

//   componentDidUpdate(prevProps){
//     if(this.props !== prevProps){
//         this.setState({
//             seminar:this.props.seminar
      
//         })
//     }
// }


  onSubmit = (e) => {
    e.preventDefault();

    let d = this.state;

    PostData(`/admin/${this.props.employee_id}/addemployeeapi`, d).then(
      (resp) => {
        //console.log(resp)
        this.setState({
          message: resp,
          open: true,
        });
      }
    );
  };


  onAddClick = () => {
    let d = {
        area: this.state.area,
        achievement: this.state.achievement,
        award: this.state.award,
        other_info: this.state.other_info,

    }

    let awards = this.state.awards != null ? this.state.awards : [];
    awards.push(d);
    this.setState({
        awards
    }, () => {
            this.setState({
                area:'',
                achievement:'',
                award:'',
                other_info:'',
            })
    })
}

onDelete = (index) => {
    let awards = this.state.awards;
    awards.splice(index,1);

    this.setState({
        awards
    })
}

  render() {
    return (
      <div>
        {this.state.open ? (
          <Snack
            open={this.state.open}
            message={this.state.message}
            handleClose={() => {
              this.setState({
                open: false,
                message: "",
              });
            }}
          />
        ) : null}

{this.state.isLoaded && <form onSubmit={this.onSubmit}>
          <Typography variant="h6" gutterBottom>
            {this.props.title}
          </Typography>
          
           <table width="100%" className="table">
          <thead>
            <tr>
              <td>Name of the Award</td>
              <td>Given By</td>
              <td>Year</td>
              <td>Other Information</td>
              <td>File</td>
              
              <td>Click + to Add</td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <input
                  className="form-control"
                  type="text"
                  name="area"
                  value={this.state.area}
                  onChange={this.onChange}
                  placeholder="Name of the Award"
                />
              </td>
              <td>
                <input
                  className="form-control"
                  type="text"
                  name="achievement"
                  value={this.state.achievement}
                  onChange={this.onChange}
                  placeholder="Given By"
                />
              </td>
              <td>
                <input
                  className="form-control"
                  type="text"
                  name="award"
                  value={this.state.award}
                  onChange={this.onChange}
                  placeholder="Year"
                />
              </td>

              <td>
                <input
                  className="form-control"
                  type="text"
                  name="other_info"
                  value={this.state.other_info}
                  onChange={this.onChange}
                  placeholder="Other Information"
                />
              </td>
             
              <td>
                {/* <input
                  className="form-control"
                  type="text"
                  name="other_info"
                  value={this.state.other_info}
                  onChange={this.onChange}
                  placeholder="Other Information"
                /> */}
                You can upload the files after additions
              </td>
             
              
              <td>
                <Button
                  variant="outlined"
                  onClick={this.onAddClick}
                  type="button"
                >
                  +
                </Button>
              </td>
            </tr>
          </tbody>
          <tbody>
            {Array.isArray(this.state.awards) && this.state.awards.map((el, index) => (
              <tr key={index}>
                <td>
                <textarea
                      className="form-control"
                      type="text"
                      name="area"
                      value={el.area}
                      onChange={(e) => this.onChange1('area', index, e.target.value)}
                      placeholder="area"
                    /></td>
                <td><textarea
                      className="form-control"
                      type="text"
                      name="achievement"
                      value={el.achievement}
                      onChange={(e) => this.onChange1('achievement', index, e.target.value)}
                      placeholder="achievement"
                    /></td>
                <td><textarea
                      className="form-control"
                      type="text"
                      name="award"
                      value={el.award}
                      onChange={(e) => this.onChange1('award', index, e.target.value)}
                      placeholder="award"
                    /></td>
                <td><textarea
                      className="form-control"
                      type="text"
                      name="other_info"
                      value={el.other_info}
                      onChange={(e) => this.onChange1('other_info', index, e.target.value)}
                      placeholder="other_info"
                    /></td>
                <td style={{whiteSpace: 'nowrap'}}>
                  {Array.isArray(el.file) && el.file.map((el1,index1) => <div key={index1}><a target="_blank" href={`${SERVER_UPLOAD}/${el1.type}/${el1.path}`} style={{fontSize: 10}}>[uploaded] {el1.path}</a></div>)}
                  <FileUploadFaculty files={el.file} onSetFile={(data) => this.onSetFile(index,data)} type={`FACULTY_AWARDS`} dp={el.file} apikey={`1234`} />
                  </td>
                
                <td>
                  <Delete onClick={this.onDelete.bind(this, index)} />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
                <br />
        <div align="right">
                <Button size="sm" variant="info" type="submit">
                  Submit
                </Button>
              </div>
        
        </form>}
      </div>
    );
  }
}

export default FacultyAward;
