import React, { Component } from "react";
import {
  withStyles,
  Drawer,
  Typography,
  Divider,
  TextField,
  Button,
  AppBar,
  Toolbar,
  IconButton,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Grid,
} from "@material-ui/core";
import { GetData } from "../../../api/service";
import Sel from "react-select";
import FileUpload from "../../AddNew/FileUpload";

const drawerWidth = 500;

const styles = (theme) => ({
  root: {
    width: drawerWidth,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: "100%",
  },
  textStyle: {
    fontWeight: 400,
  },
  paddingForm: {
    padding: "20px",
  },
  formControl: {
    minWidth: "100%",
    paddingLeft: 10
  },
});

class GroupAdd extends Component {
  constructor(props) {
    super(props);

    this.state = {
      id: "",
      dept_code: "",
      course: "",
      name: "",
      batch: "",
      current_profession: "",
      company_name: "",
      email: "",
      message: "",
      dp: [],
      loadFile: true
    };

    this.onSubmit = this.onSubmit.bind(this);
    this.onChange = this.onChange.bind(this);
  }

  onChange(e) {
    this.setState({
      [e.target.name]: e.target.value,
    });
  }


  handleChange = (name, value) => {
    this.setState({
      [name]: value,
    });
  };

  componentDidMount() {
    if (this.props.edit) {
      this.setState({
        ...this.props.edit_data,
      });
    } else {
      this.setState({
        id: "",
        dept_code: "",
        course: "",
        name: "",
        batch: "",
        current_profession: "",
        company_name: "",
        email: "",
        message: "",
        dp: [],
      });
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {
      if (this.props.edit) {
        this.setState({
          ...this.props.edit_data,
        });
      } else {
        this.setState({
          id: "",
          dept_code: "",
          course: "",
          name: "",
          batch: "",
          current_profession: "",
          company_name: "",
          email: "",
          message: "",
          dp: [],
        });
      }
    }
  }

  onSubmit(e) {
    e.preventDefault();

    let {
      id,
      dept_code,
      course,
      name,
      batch,
      current_profession,
      company_name,
      email,
      message,
      dp,
    } = this.state;

    const data = {
      id,
      department: dept_code,
      course,
      name,
      batch,
      occupation: current_profession,
      company_name,
      email,
      message,
      dp,
      type: this.props.type,
    };

    if (this.props.edit) {
      this.props.esetData(data);
    } else {
      this.props.setData(data);
    }

    this.setState({
      id: "",
      dept_code: "",
      course: "",
      name: "",
      batch: "",
      current_profession: "",
      company_name: "",
      email: "",
      message: "",
      dp: [],
    });
  }

  onDeleteClick(id) {
    this.props.onDeleteClick(id);
  }

  render() {
    let { classes } = this.props;

    return (
      <div>
        <Drawer
          className={classes.root}
          anchor="right"
          open={this.props.show}
          onClose={this.props.handleClose}
          classes={{
            paper: classes.drawerPaper,
          }}
        >
          <AppBar
            position="static"
            color={this.props.edit ? "secondary" : "primary"}
          >
            <Toolbar>
              <Typography variant="h6" color="inherit">
                {this.props.edit ? "Edit DATA" : "Add New"}
              </Typography>
            </Toolbar>
          </AppBar>

          <div className={classes.paddingForm}>
            <form onSubmit={this.onSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={12} lg={12}>
                  <TextField
                    label="Name"
                    name="name"
                    className={classes.textField}
                    type="text"
                    autoComplete="current-password"
                    margin="normal"
                    required
                    variant="outlined"
                    InputLabelProps={{
                      classes: {
                        root: classes.textStyle,
                      },
                    }}
                    value={this.state.name}
                    onChange={this.onChange}
                  />
                </Grid>

                <Grid item xs={12} lg={12}>
                  <TextField
                    label="Email"
                    name="email"
                     variant="outlined"
                    className={classes.textField}
                    type="text"
                    autoComplete="current-password"
                    margin="normal"
                    required
                    InputLabelProps={{
                      classes: {
                        root: classes.textStyle,
                      },
                    }}
                    value={this.state.email}
                    onChange={this.onChange}
                  />
                </Grid>

                <Grid item xs={12} lg={12}>
                  <FormControl  variant="outlined" className={classes.formControl}>
                    <InputLabel shrink className={classes.textStyle}>
                      Course
                    </InputLabel>
                    <Select
                      value={this.state.course}
                      onChange={this.onChange}
                      name="course"
                      inputProps={{
                        name: "course",
                      }}
                    >
                      <MenuItem value="">
                        <em>None</em>
                      </MenuItem>

                      {this.props.courses.map((el, index) => (
                        <MenuItem key={el} value={el.name}>
                          {el.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={12} lg={12}>
                  <FormControl  variant="outlined" className={classes.formControl}>
                    <InputLabel shrink className={classes.textStyle}>
                      Department
                    </InputLabel>
                    <Select
                      value={this.state.dept_code}
                      onChange={this.onChange}
                      name="dept_code"
                      inputProps={{
                        name: "dept_code",
                      }}
                    >
                      <MenuItem value="">
                        <em>None</em>
                      </MenuItem>

                      {this.props.departments.map((el, index) => (
                        <MenuItem key={el} value={el.dept_code}>
                          {el.dept_name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={12} lg={12}>
                  <TextField
                    label="Batch"
                    name="batch"
                    className={classes.textField}
                    type="text"
                    autoComplete="current-password"
                    margin="normal"
                     variant="outlined"
                    required
                    InputLabelProps={{
                      classes: {
                        root: classes.textStyle,
                      },
                    }}
                    value={this.state.batch}
                    onChange={this.onChange}
                  />
                </Grid>

                <Grid item xs={12} lg={12}>
                  <TextField
                    label="Current Profession"
                    name="current_profession"
                    className={classes.textField}
                    type="text"
                    autoComplete="current-password"
                    margin="normal"
                     variant="outlined"
                    
                    InputLabelProps={{
                      classes: {
                        root: classes.textStyle,
                      },
                    }}
                    value={this.state.current_profession}
                    onChange={this.onChange}
                  />
                </Grid>

                <Grid item xs={12} lg={12}>
                  <TextField
                    label="Company Name"
                    name="company_name"
                    className={classes.textField}
                    type="text"
                    autoComplete="current-password"
                    margin="normal"
                     variant="outlined"
                    
                    InputLabelProps={{
                      classes: {
                        root: classes.textStyle,
                      },
                    }}
                    value={this.state.company_name}
                    onChange={this.onChange}
                  />
                </Grid>

                <Grid item xs={12} lg={12}>
                  <TextField
                    label="Message "
                    name="message"
                    className={classes.textField}
                    type="text"
                    autoComplete="current-password"
                    margin="normal"
                     variant="outlined"
                    required
                    multiline
                    rows={4}
                    InputLabelProps={{
                      classes: {
                        root: classes.textStyle,
                      },
                    }}
                    value={this.state.message}
                    onChange={this.onChange}
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <label>Upload Pictures/ Files</label>
                  {this.state.loadFile ? (
                    <FileUpload
                      dp={this.state.dp}
                      type={`MESSAGE_DP`}
                      setDp={this.handleChange.bind(this, "dp")}
                    />
                  ) : null}
                </Grid>
              </Grid>
              <br />
              <div>
                <Button
                  type="submit"
                  variant="contained"
                  color={this.props.edit ? "secondary" : "primary"}
                  className={classes.button}
                >
                  {this.props.edit ? "Update" : "Submit"}
                </Button>

                {this.props.edit ? (
                  <span>
                    &nbsp;
                    <Button
                      type="button"
                      variant="contained"
                      color="secondary"
                      className={classes.button}
                      onClick={this.onDeleteClick.bind(
                        this,
                        this.props.edit_data.id
                      )}
                    >
                      Delete
                    </Button>
                  </span>
                ) : null}
              </div>
            </form>
          </div>
        </Drawer>
      </div>
    );
  }
}

export default withStyles(styles)(GroupAdd);
