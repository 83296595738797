import React, { Component } from 'react'
import MUIDataTable from "mui-datatables";
import { IconButton } from '@material-ui/core';
import Launch from '@material-ui/icons/Launch';
import Delete from '@material-ui/icons/Delete';

import Add from '@material-ui/icons/Add';
import {Switch} from '@material-ui/core'



export default class GroupTable extends Component {

    constructor(props) {
        super(props);
        this.state = {
            data: [],
            isLoaded: false
        }
    }



    handleDelete(rowsDeleted) {
        console.log(rowsDeleted)
    }

    onActionClick(el){
        this.props.actionClick(el);
    }

    onDeleteClick(el){
        this.props.deleteClick(el);
    }

    onClick() {
        console.log("hey");
    }

    showData(value){
        if(Array.isArray(value) && value.length > 0){
            let new_arr = value.map((el,index) => el.label);
            
            return new_arr.join(",");
        }else{
            return '';
        }
    }

    



    render() {
        const columns = [
            {
                name: "Approve",
                options: {
                    filter: false,
                    sort: false,
                    download: false,
                    customBodyRender: (rr) => {
                        return <><Switch color="primary" checked={rr.is_verified == 1} onChange={(e) => this.props.onToggleClick(rr.id, e.target.checked == true ? 1 : 0)} name="gilad" /></>
                    }
                }
            },
            {
                name: "#",
                options: {
                    filter: true,
                    sort: true,
                }
            },
            
            {
                name: "Name",
                options: {
                    filter: true,
                    sort: true,
                }
            },
            
            {
                name: "Email",
                options: {
                    filter: true,
                    sort: true,
                }
            },
            {
                name: "Course",
                options: {
                    filter: true,
                    sort: true,
                }
            },
            {
                name: "Department",
                options: {
                    filter: true,
                    sort: true,
                }
            },
            {
                name: "Occupation",
                options: {
                    filter: true,
                    sort: true,
                }
            },
            {
                name: "Company",
                options: {
                    filter: true,
                    sort: true,
                }
            },
            {
                name: "Message",
                options: {
                    filter: true,
                    sort: true,
                }
            },
            {
                name: "Time",
                options: {
                    filter: true,
                    sort: true,
                }
            },
            {
                name: "DP",
                options: {
                    filter: true,
                    sort: true,
                    customBodyRender: (rr) => {
                        return <>
                            <img src={rr} style={{width: 80}} />
                        </>
                    }
                }
            },
            {
                name: "Action",
                options: {
                    filter: false,
                    sort: false,
                    download: false
                }
            },
            // {
            //     name: "Delete",
            //     options: {
            //         filter: false,
            //         sort: false,
            //         download: false
            //     }
            // }
        ];

        const options = {
            filterType: 'checkbox',
            selectableRows: false,
            responsive: 'scroll',
            onRowsDelete: (rowsDeleted) => {
                this.handleDelete(rowsDeleted)
            },
            customToolbar: () => {
                return (
                    <IconButton onClick={this.props.onAddClick}><Add  /></IconButton>
                )
            }
        };

        let data = [];
        let table_data = this.props.table_data;
        if(table_data.length > 0){
            data = table_data.map((el,index)=>
                [
                    el,
                    el.id,
                    el.name,
                    el.email,
                    el.course,
                    el.dept_code,
                    el.current_profession,
                    el.company_name,
                    el.message,
                    el.time_stamp,
                    el.dp_url,
                    <Launch onClick={this.onActionClick.bind(this,el)} />
                    // <Delete onClick={this.onDeleteClick.bind(this,el.id)} />
                ]
            )
        }else{
            data= [];
        }

        return (
            <div>
                <MUIDataTable
                    title={`Messages`}
                    data={data}
                    columns={columns}
                    options={options}
                />
            </div>
        )


    }
}
