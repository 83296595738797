import React, { Component } from 'react';
import { Typography, Grid, Table, TableHead, TableCell, TableRow, TableBody, Chip } from '@material-ui/core';
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css';
import { Button, FormControl, Card } from 'react-bootstrap';
import { PostData, GetData, DeleteData, PutData } from '../../api/service';
import Snack from '../Snackbar/Snack';
import FileUpload from '../AddNew/FileUpload';
import Edit from '@material-ui/icons/Edit'
import Delete from '@material-ui/icons/Delete'
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css' // Import css
import history from '../../history';


class DepartmentFaculty extends Component {
    state = {
        id: '',
        edit: false,
        name: '',
        email: '',
        password: '',
        phone: '',
        type: '',
        appointment_type:'',
        about: '',
        date_of_joining: '',
        date_of_leaving: '',
        experience: '',
        dp: [],
        cv: [],
        data: [],
        is_hod: 0,
        isDataLoaded: false,
        dept_code: this.props.dept_code,
        message: '',
        open: false,
        loadFile: true,
        order_no:1,
    }

    handleChange = (name, value) => {
        this.setState({
            [name]: value
        })
    }

    onChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    __getDeptData = () => {
        console.log(this.props)
        GetData(`/${this.props.dept_code}/getdepartmentemployee`)
            .then((resp) => {
                //console.log(resp)
                this.setState({
                    data: resp,
                    isDataLoaded: true
                })
            })
    }

    componentDidMount() {
        this.__getDeptData();

    }


    onSubmit = (e) => {
        e.preventDefault();

        let d = {
            id: this.state.id,
            name: this.state.name,
            type: this.state.type,
            appointment_type:this.state.appointment_type,
            password:this.state.password,
            
            email: this.state.email,
            phone: this.state.phone,
            is_hod: this.state.is_hod,
            designation: this.state.designation,
            date_of_joining: this.state.date_of_joining,
            date_of_leaving: this.state.date_of_leaving,
            experience: this.state.experience,
            about: this.state.about,
            dp: this.state.dp,
            cv: this.state.cv,
            dept_code: this.props.dept_code,
            order_no:this.state.order_no
        }

        if (this.state.edit) {
            PutData(`/admin/${this.state.id}/editemployee`, d)
                .then((resp) => {
                    //console.log(resp)
                    this.setState({
                        message: resp,
                        open: true
                    }, () => this.__getDeptData())
                    this.cancelEdit();
                })
        } else {
            PostData(`/admin/addemployee`, d)
                .then((resp) => {
                    //console.log(resp)
                    this.setState({
                        message: resp,
                        open: true
                    }, () => this.__getDeptData())
                    this.cancelEdit();
                })
        }


    }

    onDeleteClick = (el) => {
        confirmAlert({
            title: 'Confirm to delete',
            message: 'Are you sure to do this.',
            buttons: [
                {
                    label: 'Yes',
                    onClick: this.onDeleteConfirm.bind(this, el.id)
                },
                {
                    label: 'No'
                }
            ]
        })
    }

    onDeleteConfirm = (id) => {
        DeleteData(`/${this.props.apikey}/${id}/deleteemployee`)
            .then((resp) => {
                this.setState({
                    message: resp,
                    open: true
                }, () => this.__getDeptData())
            })
    }

    onEditClick = (el) => {
        this.setState({
            ...el,
            edit: true,
            loadFile: false
        }, () => {
            this.setState({
                loadFile: true
            })
        })
    }

    cancelEdit = () => {
        this.setState({
            id: '',
            name: '',
            designation: '',
            email: '',
            password: '',
            is_hod: 0,
            phone: '',
            type: '',
            about: '',
            date_of_joining: '',
            date_of_leaving: '',
            experience: '',
            order_no:1,
            dp: [],
            cv: [],
            edit: false,
            loadFile: false
        }, () => {
            this.setState({
                loadFile: true
            })
        })
    }


    render() {
        return (
            <div>

                {this.state.open ? (
                    <Snack
                        open={this.state.open}
                        message={this.state.message}
                        handleClose={() => {
                            this.setState({
                                open: false,
                                message: ''
                            })
                        }}
                    />
                ) : null}

                <form onSubmit={this.onSubmit}>
                    <Typography
                        variant="h6"
                        gutterBottom
                    >
                        Department Employee
                    </Typography>


                    <Card>

                        <Card.Body>


                            <Grid container spacing={2}>
                            <Grid item xs={12} sm={12} md={1} lg={1}>
                                    <label>Index</label>
                                    <FormControl
                                        as="input"
                                        type='number'
                                        min={1}
                                        name="order_no"
                                        onChange={this.onChange}
                                        value={this.state.order_no}
                                        placeholder="Index"
                                        required
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} md={3} lg={3}>
                                    <label>Type</label>
                                    <FormControl
                                        as="select"
                                        name="type"
                                        required
                                        onChange={this.onChange}
                                        value={this.state.type}
                                    >
                                        <option value="">Select Type</option>
                                        <option value="PRINCIPAL">PRINCIPAL</option>
                                        <option value="VICE PRINCIPAL">VICE PRINCIPAL</option>
                                        <option value="FACULTY">FACULTY</option>
                                        <option value="OFFICE">OFFICE ASSISTANT</option>
                                        <option value="LIB">LIBRARY STAFF</option>
                                        <option value="BEARER">BEARER</option>
                                        <option value="FORMER_FACULTY">Former Faculty</option>
                                    </FormControl>
                                </Grid>

                                <Grid item xs={12} sm={12} md={3} lg={3}>
                                    <label>Nature of Appointment</label>
                                    <FormControl
                                        as="select"
                                        name="appointment_type"
                                        required
                                        onChange={this.onChange}
                                        value={this.state.appointment_type}
                                    >
                                        <option value="">Select Nature of Appointment</option>
                                        <option value="REGULAR">REGULAR</option>
                                        <option value="TEMPORARY">TEMPORARY</option>
                                        <option value="NON SANCTIONED">NON SANCTIONED</option>
                                    </FormControl>
                                </Grid>

                                <Grid item xs={12} sm={12} md={4} lg={4}>
                                    <label>Name</label>
                                    <FormControl
                                        as="input"
                                        name="name"
                                        required
                                        onChange={this.onChange}
                                        value={this.state.name}
                                        placeholder="Name"
                                    />
                                </Grid>

                                <Grid item xs={12} sm={12} md={5} lg={5}>
                                    <label>Designation</label>
                                    <FormControl
                                        as="input"
                                        name="designation"
                                        onChange={this.onChange}
                                        value={this.state.designation}
                                        placeholder="Designation"
                                    />
                                </Grid>

                                <Grid item xs={12} sm={12} md={3} lg={3}>
                                    <label>Email</label>
                                    <FormControl
                                        as="input"
                                        name="email"
                                        onChange={this.onChange}
                                        value={this.state.email}
                                        placeholder="Email"
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} md={3} lg={3}>
                                    <label>Password</label>
                                    <FormControl
                                        as="input"
                                        name="password"
                                        onChange={this.onChange}
                                        value={this.state.password}
                                        placeholder="Password"
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} md={3} lg={3}>
                                    <label>Phone</label>
                                    <FormControl
                                        as="input"
                                        name="phone"
                                        onChange={this.onChange}
                                        value={this.state.phone}
                                        placeholder="phone"
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} md={3} lg={3}>
                                    <label>Date of Joining</label>
                                    <FormControl
                                        as="input"
                                        name="date_of_joining"
                                        onChange={this.onChange}
                                        value={this.state.date_of_joining}
                                        placeholder="Date of Joining"
                                    />
                                </Grid>

                                <Grid item xs={12} sm={12} md={3} lg={3}>
                                    <label>Date of Leaving (you can write Currently Working)</label>
                                    <FormControl
                                        as="input"
                                        name="date_of_leaving"
                                        onChange={this.onChange}
                                        value={this.state.date_of_leaving}
                                        placeholder="Date of Leaving or Currently Working"
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} md={3} lg={3}>
                                    <label>Experience</label>
                                    <FormControl
                                        as="input"
                                        name="experience"
                                        onChange={this.onChange}
                                        value={this.state.experience}
                                        placeholder="Experience"
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} md={3} lg={3}>
                                    <label>is HOD ?</label>
                                    <FormControl
                                        as="select"
                                        name="is_hod"
                                        onChange={this.onChange}
                                        value={this.state.is_hod}
                                        
                                    >
                                        <option value="0">No</option>
                                        <option value="1">Yes</option>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={12} md={12} lg={12}>
                                    <label>About the teacher</label>
                                    <ReactQuill
                                        placeholder="Type something here"
                                        value={this.state.about}
                                        onChange={this.handleChange.bind(this, 'about')}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={6}>
                                    <label>Upload Display Picture</label>
                                    {this.state.loadFile ? (<FileUpload
                                        dp={this.state.dp}
                                        type={`EMPLOYEE_DP`}
                                        setDp={this.handleChange.bind(this, 'dp')}
                                    />) : null}
                                </Grid>

                                <Grid item xs={12} sm={12} md={6} lg={6}>
                                    <label>Upload CV</label>
                                    {this.state.loadFile ? (<FileUpload
                                        dp={this.state.cv}
                                        type={`EMPLOYEE_CV`}
                                        setDp={this.handleChange.bind(this, 'cv')}
                                    />) : null}
                                </Grid>
                            </Grid>



                            {this.state.edit ? (
                                <div align="right">
                                    <Button
                                        size="sm"
                                        variant="danger"
                                        type="submit"
                                    >
                                        Update
                                    </Button>
                                    &nbsp;
                                    <Button
                                        size="sm"
                                        variant="danger"
                                        type="button"
                                        onClick={this.cancelEdit}
                                    >
                                        Cancel
                                    </Button>
                                </div>
                            ) : (<div align="right">
                                <Button
                                    size="sm"
                                    variant="info"
                                    type="submit"
                                >
                                    Submit
                    </Button>
                            </div>)}

                        </Card.Body>

                    </Card>

                    <br />


                    <Card>

                        <Card.Body>
                            {this.state.isDataLoaded ? (
                                <div>
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>#</TableCell>
                                                <TableCell>Index</TableCell>
                                                <TableCell width="30%">Name</TableCell>
                                                <TableCell wodth='20%'>Contact</TableCell>
                                                
                                                <TableCell wodth='20%'>Experience</TableCell>
                                                <TableCell wodth='20%'>DP</TableCell>
                                                <TableCell wodth='20%'>CV</TableCell>
                                                <TableCell>Edit</TableCell>
                                                <TableCell>Delete</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {this.state.data.map((el, index) =>

                                                <TableRow key={index}>
                                                    <TableCell>{index + 1}</TableCell>
                                                    <TableCell>{el.order_no}</TableCell>
                                                    <TableCell>
                                                        <a style={{
                                                            color: 'blue',
                                                            cursor: 'pointer'
                                                        }} onClick={() => history.push(`/faculty/${el.id}/home`)}>{el.name}</a>
                                                        <br />
                                                        {el.designation}
                                                    </TableCell>
                                                    <TableCell>
                                                        {el.phone}<br />
                                                        {el.email}
                                                    </TableCell>
                                                    
                                                    <TableCell>
                                                        Joining Date: {el.date_of_joining}<br />
                                                        Exp: {el.experience}
                                                    </TableCell>
                                                    <TableCell>
                                                        {Array.isArray(el.dp) && el.dp.map((el1, index1) =>
                                                            <Chip
                                                                key={index1}
                                                                label={el1.path}
                                                            />
                                                        )}
                                                    </TableCell>
                                                    <TableCell>
                                                        {Array.isArray(el.cv) && el.cv.map((el1, index1) =>
                                                            <Chip
                                                                key={index1}
                                                                label={el1.path}
                                                            />
                                                        )}
                                                    </TableCell>
                                                    <TableCell><Edit onClick={this.onEditClick.bind(this, el)} /></TableCell>
                                                    <TableCell><Delete onClick={this.onDeleteClick.bind(this, el)} /></TableCell>
                                                </TableRow>

                                            )}
                                        </TableBody>
                                    </Table>
                                </div>
                            ) : <Typography>
                                    Loading . . .
                            </Typography>}
                        </Card.Body>

                    </Card>

                </form>
            </div>
        );
    }
}


export default DepartmentFaculty;