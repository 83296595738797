import {
  ExpansionPanel,
  ExpansionPanelSummary,
  Typography,
  Button,
  ExpansionPanelDetails,
} from '@material-ui/core'
import React from 'react'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import Delete from '@material-ui/icons/Delete'
import { PostData } from '../../../api/service'
import Snack from '../../Snackbar/Snack'
import FileUploadFaculty from '../../Faculty/FileUploadFaculty'
import { SERVER_UPLOAD } from '../../../Helpers/GlobalVariables'

export default function ProfileInputForm({
  dept_code,
  title,
  mainValue,
  inputs,
  data,
}) {
  const [inData, setInData] = React.useState([])

  React.useEffect(() => {
    if (data != null) {
      setInData(data)
    } else {
      setInData([])
    }
  }, [data])

  const [expand, setExpand] = React.useState(false)

  let names = {}

  for (let value of Object.values(inputs)) {
    names[value.name] = value.value
  }

  let err = {}

  for (let value of Object.values(inputs)) {
    err[value.name] = ''
  }

  const [open, setOpen] = React.useState(false)
  const [message, setMessage] = React.useState('')

  const [state, setState] = React.useState({
    ...names,
  })

  function onClear() {
    setState({
      ...names,
    })
  }

  const [error, setError] = React.useState({
    ...err,
  })

  function onChange(name, value) {
    setState({
      ...state,
      [name]: value,
    })
  }


  function onChangeAfter(index, name, value) {


    let s = inData;
    s[index][name] = value
    setState({
     ...s
    })
  }

  function handleValidation() {
    let isValid = true
    let err = []

    if (Array.isArray(inputs)) {
      inputs.map((el, index) => {
        if(el.name != 'file'){
        if (state[el.name] == null || state[el.name] == '') {
          isValid = false
          err[el.name] = '* Mandatory field'
        } else {
          err[el.name] = ''
        }
      }
      })
    }

    setError(err)
    //console.log("🚀 ~ file: FormBuilder.tsx ~ line 71 ~ handleValidation ~ err", err)
    return isValid
  }

  function onDelete(index) {

    let cc = inData;
    cc.splice(index, 1);
    setInData([...cc])

  }

  function onAddClick() {
    if (handleValidation()) {
      let cc = inData
      cc = [...inData, state]
      setInData(cc)

      onClear()
    }
  }

  function onSubmit() {
      if(inData.length > 0){
          PostData(`/admin/${dept_code}/updatedeptdatafromform`, {
              [mainValue]: inData
          }).then((resp) => {
              setMessage(resp)
              setOpen(true)
          }).catch((err) => {
              alert('Error')
          })
      }
  }

  function onSetFile(index, data){
    //console.log(data)
    let aw = inData;
    aw[index]['file'] = data
    setInData([...aw])
  }

  return (
    <div  style={{marginBottom: 20}}>
        {open ? (
                    <Snack
                        open={open}
                        message={message}
                        handleClose={() => {
                            setOpen(false)
                            setMessage('')
                        }}
                    />
                ) : null}
      <ExpansionPanel expanded={expand} onChange={() => setExpand(!expand)}>
        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
          <Typography style={{fontWeight: 500, fontSize: 14}}>{title}</Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <table width="100%" className="table table-sm table-bordered table-striped">
            <thead>
              <tr>
                <td colSpan={inputs.length + 1} align="center" >
                  <Typography style={{fontWeight: 500, fontSize: 13}}>{title}</Typography>
                </td>
              </tr>
              <tr>
                {Array.isArray(inputs) &&
                  inputs.map((el, index) => <td style={{fontWeight: 500, fontSize: 12}} key={index}>{el.label}</td>)}

                <td style={{textAlign: 'center', fontWeight: 500, fontSize: 12}}>Click + to Add</td>
              </tr>
            </thead>
            <tbody>
              <tr>
                {Array.isArray(inputs) &&
                  inputs.map((el, index) => (
                    <td>
                      {el.name != 'file' && <input
                        className="form-control"
                        type="text"
                        name={el.name}
                        value={state[el.name]}
                        onChange={(e) => onChange(el.name, e.target.value)}
                        placeholder={el.label}
                      />}
                    </td>
                  ))}

                <td style={{textAlign: 'center'}}>
                  <Button
                    variant="contained"
                    //color="primary"
                    onClick={() => onAddClick()}
                    type="button"
                  >
                    +
                  </Button>
                </td>
              </tr>
            </tbody>
            <tbody>
              {Array.isArray(inData) &&
                inData.map((el, index) => (
                  <tr key={index}>
                    {Array.isArray(inputs) &&
                      inputs.map((el1, index1) => {
                        if(el1.name != 'file'){
                          return <td>
                          <input
                        className="form-control"
                        type="text"
                        size="small"
                        value={el[el1.name]}
                        onChange={(e) => onChangeAfter(index, el1.name, e.target.value)}
                        placeholder={el.label}
                      />
                        </td>
                        }else{
                          return <td style={{whiteSpace: 'nowrap'}}>
                          {Array.isArray(el.file) && el.file.map((el1,index1) => <div key={index1}><a target="_blank" href={`${SERVER_UPLOAD}/${el1.type}/${el1.path}`} style={{fontSize: 10}}>[uploaded] {el1.path}</a></div>)}
                          <FileUploadFaculty files={el.file} onSetFile={(data) => onSetFile(index,data)} type={`DEPARTMENT_FILE_UPLOAD`} dp={el.file} apikey={`1234`} />
                          </td>
                        }
                      })}

                    <td style={{textAlign: 'center'}}>
                      <Delete onClick={() => onDelete(index)} />
                    </td>
                  </tr>
                ))}
            </tbody>

            <tbody>
              <tr>
                <td colSpan={inputs.length + 1}>
                  <div style={{ textAlign: 'right' }}>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => onSubmit()}
                    >
                      Update
                    </Button>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>

          <br />
        </ExpansionPanelDetails>
      </ExpansionPanel>
    </div>
  )
}
