import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button } from '@material-ui/core'
import React from 'react'
import { Tab, Tabs } from 'react-bootstrap'
import FileUpload from '../AddNew/FileUpload'

export default function FileUploadFaculty({ apikey, type, onSetFile, files }) {
    const [open, setOpen] = React.useState(false)
    function handleClose(){
        setOpen(false)
    }


//     ext: "png"
// mime: "image/png"
// path: "Screenshot from 2022-03-24 14-38-23_jUG18CYPVP.png"
// type: "FACULTY_RESEARCH_PAPER"


const [fileLink, setFileLink] = React.useState([])

const [fileLinkText, setFileLinkText] =  React.useState('')


React.useEffect(() => {
  if(Array.isArray(files)){
    

    setFileLink([...files])
  }
}, [files])

function addLink(){
  if(fileLinkText != ""){
    let fl = fileLink;
    fl.push({
      ext: 'link',
      mime: 'link',
      path: fileLinkText,
      type: type
    })

    setFileLink([...fl])
    onSetFile([...fl])
    setFileLinkText('')
  }
}

function deleteThisLink(pp){
  let fl = fileLink;
  let fl1 = fileLink.filter((el) => el.path != pp);
  setFileLink([...fl1])
  onSetFile([...fl1])
}


// React.useEffect(() => {
//   onSetFile([...fileLink])
// }, [fileLink])

   
  return (
    <div>
        <a style={{fontSize: 10, color: 'blue', cursor: 'pointer'}} onClick={() => setOpen(true)}><u>+ Upload New File</u></a>

        <Dialog
        open={open}
        fullWidth
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Upload file"}</DialogTitle>
        <DialogContent>

        <Tabs defaultActiveKey="file" id="uncontrolled-tab-example" className="mb-3">
  <Tab eventKey="file" title="File Upload">
  <FileUpload
                    dp={files}
                    apikey={apikey}
                    type={type}
                    setDp={onSetFile}
                  />
  </Tab>
  <Tab eventKey="link" title="Link">


    <ul>
      {Array.isArray(fileLink.filter(el => el.mime == 'link')) && fileLink.filter(el => el.mime == 'link').map((el,index) => <li key={index}>{el.path}  &nbsp;&nbsp; <a style={{color: 'red', fontSize: 10}} onClick={() => deleteThisLink(el.path)}>Delete Link</a></li>)}
   
      </ul>

    <div style={{display: 'flex', justifyContent: 'space-between'}}>
   <input className="form-control" placeholder="Paste Your Link here" value={fileLinkText} onChange={(e) => setFileLinkText(e.target.value)}  />
   <Button type="primary" onClick={() => addLink()}>Add</Button>
   </div>
  </Tab>
  
</Tabs>
        
         
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Close
          </Button>
          <Button onClick={handleClose} color="primary" autoFocus>
            Submit
          </Button>
        </DialogActions>
      </Dialog>


    </div>
  )
}
