import React, { Component } from 'react';
import { Grid, Typography } from '@material-ui/core';
import { Button } from 'react-bootstrap';
import { GetData, PostData } from '../../api/service';
import Snack from '../Snackbar/Snack';


import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

class FacultyOthers extends Component {
    state = {
        value: {
            facebook_link: '',
            google_scholar: '',
            linkedin_link: '',
            orchid_link: '',
        },
        m_type: this.props.m_type,
        employee_id: this.props.employee_id,
        email:this.props.email,        
        message: '',
        open: false,
        isLoaded: false,
        facebook_link: '',
        linkedin_link: '',
        google_scholar: '',
        orchid_link: '',
        wiki_link: '',
        
    }

    handleChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    componentDidMount() {
        //console.log(this.props);
    
        GetData(`/admin/${this.props.employee_id}/getsingleempolyeedataapi`)
        .then((resp)=>{
            if(resp){

                let others = resp.others != null ? resp.others : []

                this.setState({
                    ...resp,
                    value:resp.others,
                    ...others
                    
                  });
            }
            this.setState({
                isLoaded: true
              })
        })
    
        
      }


    onSubmit = (e) => {
        e.preventDefault();
    
        
        let v = {
            facebook_link: this.state.facebook_link,
            linkedin_link: this.state.linkedin_link,
            google_scholar: this.state.google_scholar,
            orchid_link: this.state.orchid_link,
            wiki_link: this.state.wiki_link,
        }
        let d = {
            ...this.state,
            value: v
        };

        

        
    
        PostData(`/admin/${this.props.employee_id}/addemployeeapi`, d).then(
          (resp) => {
            //console.log(resp)
            this.setState({
              message: resp,
              open: true,
            });
          }
        );
      };


    render() {
        return (
            <div>

                {this.state.open ? (
                    <Snack
                        open={this.state.open}
                        message={this.state.message}
                        handleClose={() => {
                            this.setState({
                                open: false,
                                message: ''
                            })
                        }}
                    />
                ) : null}

                <form onSubmit={this.onSubmit}>
                    <Typography
                        variant="h6"
                        gutterBottom
                    >
                        {this.props.title}
                    </Typography>

                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={12} md={4} lg={4}>
                            <div>Facebook Link</div>
                        <input
                        className="form-control"
                        type="text"
                        name="facebook_link"
                        value={this.state.facebook_link}
                        onChange={this.handleChange}
                        placeholder="Facebook Link"
                        />
                        </Grid>
                        <Grid item xs={12} sm={12} md={4} lg={4}>
                            <div>LinkedIn Link</div>
                        <input
                        className="form-control"
                        type="text"
                        name="linkedin_link"
                        value={this.state.linkedin_link}
                        onChange={this.handleChange}
                        placeholder="LinkedIn Link"
                        />
                        </Grid>

                        <Grid item xs={12} sm={12} md={4} lg={4}>
                            <div>Google Scholars' Link</div>
                        <input
                        className="form-control"
                        type="text"
                        name="google_scholar"
                        value={this.state.google_scholar}
                        onChange={this.handleChange}
                        placeholder="Google Scholar"
                        />
                        </Grid>

                        <Grid item xs={12} sm={12} md={4} lg={4}>
                            <div>Orcid ID </div>
                        <input
                        className="form-control"
                        type="text"
                        name="orchid_link"
                        value={this.state.orchid_link}
                        onChange={this.handleChange}
                        placeholder="Orcid ID"
                        />
                        </Grid>

                        <Grid item xs={12} sm={12} md={4} lg={4}>
                            <div>Wikipedia Link</div>
                        <input
                        className="form-control"
                        type="text"
                        name="wiki_link"
                        value={this.state.wiki_link}
                        onChange={this.handleChange}
                        placeholder="Wikipedia Link"
                        />
                        </Grid>
                        </Grid>
                    

{/* {this.state.isLoaded && <CKEditor
                    editor={ ClassicEditor }
                    data={this.state.value}
                    onReady={ editor => {
                        // You can store the "editor" and use when it is needed.
                       // console.log( 'Editor is ready to use!', editor );
                    } }
                    onChange={ ( event, editor ) => {
                        const data = editor.getData();
                        this.handleChange(`value`, data)
                        //console.log( { event, editor, data } );
                    } }
                    onBlur={ ( event, editor ) => {
                        //console.log( 'Blur.', editor );
                    } }
                    onFocus={ ( event, editor ) => {
                        //console.log( 'Focus.', editor );
                    } }
                />} */}

                    <br />

                    <div align="right">
                        <Button
                            size="sm"
                            variant="info"
                            type="submit"
                        >
                            Submit
                    </Button>
                    </div>

                </form>
            </div>
        );
    }
}


export default FacultyOthers;