import React, { Component } from 'react'
import {
  Typography,
  Grid,
  Table,
  TableHead,
  TableCell,
  TableRow,
  TableBody,
  Chip,
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
} from '@material-ui/core'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import { Button, FormControl, Card } from 'react-bootstrap'
import { PostData, GetData, DeleteData, PutData } from '../../api/service'
import Snack from '../Snackbar/Snack'
import FileUpload from '../AddNew/FileUpload'
import Edit from '@material-ui/icons/Edit'
import Delete from '@material-ui/icons/Delete'
import { confirmAlert } from 'react-confirm-alert' // Import
import 'react-confirm-alert/src/react-confirm-alert.css' // Import css
import history from '../../history'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import FileUploadFaculty from './FileUploadFaculty'
import { Q_LIST, SERVER_UPLOAD } from '../../Helpers/GlobalVariables'

class FacultyPublications extends Component {
  state = {
    m_type: this.props.m_type,
    employee_id: this.props.employee_id,
    email: this.props.email,
    message: '',
    open: false,
    isLoaded: false,
    date_year: '',
    title_paper: '',
    title_book: '',
    published_by: '',
    isbn_no: '',
    issn_no: '',
    other_info: '',
    quarter: '',
    research_paper: [],
    reserarch_paper_panel: false,
    journal: [],
    journal_panel: false,
    text_book: [],
    text_book_panel: false,
    magazine: [],
    magazine_panel: false,
    popular_book: [],
    popular_book_panel: false,
    news_paper: [],
    news_paper_panel: false,
    other_publication: [],
    other_publication_panel: false,
    econtent: [],
    econtent_panel: false,
  }

  clearData = () => {
    this.setState({
      date_year: '',
      title_paper: '',
      title_book: '',
      published_by: '',
      isbn_no: '',
      issn_no: '',
      other_info: '',
      quarter: '',
    })
  }

  onPanelClick = (type, flag) => {
    this.clearData()
    this.setState({
      reserarch_paper_panel: type == 'reserarch_paper_panel' ? flag : false,
      journal_panel: type == 'journal_panel' ? flag : false,
      text_book_panel: type == 'text_book_panel' ? flag : false,
      magazine_panel: type == 'magazine_panel' ? flag : false,
      popular_book_panel: type == 'popular_book_panel' ? flag : false,
      news_paper_panel: type == 'news_paper_panel' ? flag : false,
      other_publication_panel: type == 'other_publication_panel' ? flag : false,
      econtent_panel: type == 'econtent_panel' ? flag : false,
    })
  }

  handleChange = (name, value) => {
    this.setState({
      [name]: value,
    })
  }

  onChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    })
  }

  componentDidMount() {
    //console.log(this.props);

    GetData(`/admin/${this.props.employee_id}/getsingleempolyeedataapi`).then(
      (resp) => {
        if (resp) {
          this.setState({
            ...resp,
          })
        }
        this.setState({
          isLoaded: true,
        })
      },
    )
  }

  //   componentDidUpdate(prevProps){
  //     if(this.props !== prevProps){
  //         this.setState({
  //             seminar:this.props.seminar

  //         })
  //     }
  // }

  onSubmit = (e) => {
    e.preventDefault()

    let d = this.state

    PostData(`/admin/${this.props.employee_id}/addemployeeapi`, d).then(
      (resp) => {
        //console.log(resp)
        this.setState({
          message: resp,
          open: true,
        })
      },
    )
  }

  onAddClick = (type) => {
    if (type == 'research_paper') {
      let d = {
        date_year: this.state.date_year,
        title_paper: this.state.title_paper,
        title_book: this.state.title_book,
        published_by: this.state.published_by,
        isbn_no: this.state.isbn_no,
        volume: this.state.volume,
        page_nos: this.state.page_nos,
        quarter: this.state.quarter,
      }

      let research_paper =
        this.state.research_paper != null ? this.state.research_paper : []
      research_paper.push(d)
      this.setState(
        {
          research_paper,
        },
        () => {
          this.setState({
            date_year: '',
            title_paper: '',
            title_book: '',
            published_by: '',
            isbn_no: '',
            volume: '',
            page_nos: '',
            quarter: '',
          })
        },
      )
    } else if (type == 'journal') {
      let d = {
        date_year: this.state.date_year,
        title_paper: this.state.title_paper,
        title_book: this.state.title_book,
        published_by: this.state.published_by,
        issn_no: this.state.issn_no,
        quarter: this.state.quarter,
      }

      let journal = this.state.journal != null ? this.state.journal : []
      journal.push(d)
      this.setState(
        {
          journal,
        },
        () => {
          this.setState({
            date_year: '',
            title_paper: '',
            title_book: '',
            published_by: '',
            issn_no: '',
            quarter: '',
          })
        },
      )
    } else if (type == 'text_book') {
      let d = {
        date_year: this.state.date_year,
        title_book: this.state.title_book,
        published_by: this.state.published_by,
        isbn_no: this.state.isbn_no,
        volume: this.state.volume,
        page_nos: this.state.page_nos,
        edition: this.state.edition,
        name_of_authors: this.state.name_of_authors,
        quarter: this.state.quarter,
      }

      let text_book = this.state.text_book != null ? this.state.text_book : []
      text_book.push(d)
      this.setState(
        {
          text_book,
        },
        () => {
          this.setState({
            date_year: '',
            title_book: '',
            published_by: '',
            isbn_no: '',
            volume: '',
            page_nos: '',
            edition: '',
            name_of_authors: '',
            quarter: '',
          })
        },
      )
    } else if (type == 'magazine') {
      let d = {
        date_year: this.state.date_year,
        title_book: this.state.title_book,
        published_by: this.state.published_by,
        isbn_no: this.state.isbn_no,
        other_info: this.state.other_info,
        quarter: this.state.quarter,
      }

      let magazine = this.state.magazine != null ? this.state.magazine : []
      magazine.push(d)
      this.setState(
        {
          magazine,
        },
        () => {
          this.setState({
            date_year: '',
            title_book: '',
            published_by: '',
            isbn_no: '',
            other_info: '',
            quarter: '',
          })
        },
      )
    } else if (type == 'popular_book') {
      let d = {
        date_year: this.state.date_year,
        title_paper: this.state.title_paper,
        title_book: this.state.title_book,
        published_by: this.state.published_by,
        isbn_no: this.state.isbn_no,
        volume: this.state.volume,
        page_nos: this.state.page_nos,
        quarter: this.state.quarter,
      }

      let popular_book =
        this.state.popular_book != null ? this.state.popular_book : []
      popular_book.push(d)
      this.setState(
        {
          popular_book,
        },
        () => {
          this.setState({
            date_year: '',
            title_paper: '',
            title_book: '',
            published_by: '',
            isbn_no: '',
            volume: '',
            page_nos: '',
            quarter: '',
          })
        },
      )
    } else if (type == 'news_paper') {
      let d = {
        date_year: this.state.date_year,
        title_paper: this.state.title_paper,
        title_book: this.state.title_book,
        quarter: this.state.quarter,
      }

      let news_paper =
        this.state.news_paper != null ? this.state.news_paper : []
      news_paper.push(d)
      this.setState(
        {
          news_paper,
        },
        () => {
          this.setState({
            date_year: '',
            title_paper: '',
            title_book: '',
            quarter: '',
          })
        },
      )
    } else if (type == 'other_publication') {
      let d = {
        date_year: this.state.date_year,
        title_book: this.state.title_book,
        published_by: this.state.published_by,
        isbn_no: this.state.isbn_no,
        other_info: this.state.other_info,
        volume: this.state.volume,
        page_nos: this.state.page_nos,
        quarter: this.state.quarter,
      }

      let other_publication =
        this.state.other_publication != null ? this.state.other_publication : []
      other_publication.push(d)
      this.setState(
        {
          other_publication,
        },
        () => {
          this.setState({
            date_year: '',
            title_book: '',
            published_by: '',
            isbn_no: '',
            other_info: '',
            volume: '',
            page_nos: '',
            quarter: '',
          })
        },
      )
    } else if (type == 'econtent') {
      let d = {
        date_year: this.state.date_year,
        title_paper: this.state.title_paper,
        title_book: this.state.title_book,
        published_by: this.state.published_by,
        other_info: this.state.other_info,
        quarter: this.state.quarter,
      }

      let econtent = this.state.econtent != null ? this.state.econtent : []
      econtent.push(d)
      this.setState(
        {
          econtent,
        },
        () => {
          this.setState({
            date_year: '',
            title_paper: '',
            title_book: '',
            published_by: '',

            other_info: '',
            quarter: '',
          })
        },
      )
    }
  }

  onDelete = (index, type) => {
    if (type == 'research_paper') {
      let research_paper = this.state.research_paper
      research_paper.splice(index, 1)

      this.setState({
        research_paper,
      })
    } else if (type == 'journal') {
      let journal = this.state.journal
      journal.splice(index, 1)

      this.setState({
        journal,
      })
    } else if (type == 'text_book') {
      let text_book = this.state.text_book
      text_book.splice(index, 1)

      this.setState({
        text_book,
      })
    } else if (type == 'magazine') {
      let magazine = this.state.magazine
      magazine.splice(index, 1)

      this.setState({
        magazine,
      })
    } else if (type == 'popular_book') {
      let popular_book = this.state.popular_book
      popular_book.splice(index, 1)

      this.setState({
        popular_book,
      })
    } else if (type == 'news_paper') {
      let news_paper = this.state.news_paper
      news_paper.splice(index, 1)

      this.setState({
        news_paper,
      })
    } else if (type == 'other_publication') {
      let other_publication = this.state.other_publication
      other_publication.splice(index, 1)

      this.setState({
        other_publication,
      })
    } else if (type == 'econtent') {
      let econtent = this.state.econtent
      econtent.splice(index, 1)

      this.setState({
        econtent,
      })
    }
  }

  onChange1 = (name, index, value, type) => {
  console.log("🚀 ~ file: FacultyPublications.js ~ line 445 ~ FacultyPublications ~ type", type)
    if (type == 'research_paper') {
      let d = this.state.research_paper
      d[index][name] = value
      this.setState({
        research_paper: d,
      })
    }
    if (type == 'journal') {
      let d = this.state.journal
      d[index][name] = value
      this.setState({
        journal: d,
      })
    }

    if (type == 'text_book') {
      let d = this.state.text_book
      d[index][name] = value
      this.setState({
        text_book: d,
      })
    }

    if (type == 'magazine') {
      let d = this.state.magazine
      d[index][name] = value
      this.setState({
        magazine: d,
      })
    }
    if (type == 'popular_book') {
      let d = this.state.popular_book
      d[index][name] = value
      this.setState({
        popular_book: d,
      })
    }

    if (type == 'news_paper') {
      let d = this.state.news_paper
      d[index][name] = value
      this.setState({
        news_paper: d,
      })
    }

    if (type == 'other_publication') {
      let d = this.state.other_publication
      d[index][name] = value
      this.setState({
        other_publication: d,
      })
    }

    if (type == 'econtent') {
      let d = this.state.econtent
      d[index][name] = value
      this.setState({
        econtent: d,
      })
    }
  }

  onSetFile = (index, type, data) => {
    if (type == 'research_paper') {
      let research_paper = this.state.research_paper
      research_paper[index]['file'] = data

      this.setState({
        research_paper,
      })
    } else if (type == 'journal') {
      let journal = this.state.journal
      journal[index]['file'] = data

      this.setState({
        journal,
      })
    } else if (type == 'text_book') {
      let text_book = this.state.text_book
      text_book[index]['file'] = data

      this.setState({
        text_book,
      })
    } else if (type == 'magazine') {
      let magazine = this.state.magazine
      magazine[index]['file'] = data

      this.setState({
        magazine,
      })
    } else if (type == 'popular_book') {
      let popular_book = this.state.popular_book
      popular_book[index]['file'] = data

      this.setState({
        popular_book,
      })
    } else if (type == 'news_paper') {
      let news_paper = this.state.news_paper
      news_paper[index]['file'] = data

      this.setState({
        news_paper,
      })
    } else if (type == 'other_publication') {
      let other_publication = this.state.other_publication
      other_publication[index]['file'] = data

      this.setState({
        other_publication,
      })
    } else if (type == 'econtent') {
      let econtent = this.state.econtent
      econtent[index]['file'] = data

      this.setState({
        econtent,
      })
    }
  }

  onChange11 = (index, type, name, value) => {
    if (type == 'research_paper') {
      let research_paper = this.state.research_paper
      research_paper[index][name] = value

      this.setState({
        research_paper,
      })
    } else if (type == 'journal') {
      let journal = this.state.journal
      journal[index][name] = value

      this.setState({
        journal,
      })
    } else if (type == 'text_book') {
      let text_book = this.state.text_book
      text_book[index][name] = value

      this.setState({
        text_book,
      })
    } else if (type == 'magazine') {
      let magazine = this.state.magazine
      magazine[index][name] = value

      this.setState({
        magazine,
      })
    } else if (type == 'popular_book') {
      let popular_book = this.state.popular_book
      popular_book[index][name] = value

      this.setState({
        popular_book,
      })
    } else if (type == 'news_paper') {
      let news_paper = this.state.news_paper
      news_paper[index][name] = value

      this.setState({
        news_paper,
      })
    } else if (type == 'other_publication') {
      let other_publication = this.state.other_publication
      other_publication[index][name] = value

      this.setState({
        other_publication,
      })
    } else if (type == 'econtent') {
      let econtent = this.state.econtent
      econtent[index][name] = value

      this.setState({
        econtent,
      })
    }
  }

  render() {
    return (
      <div>
        {this.state.open ? (
          <Snack
            open={this.state.open}
            message={this.state.message}
            handleClose={() => {
              this.setState({
                open: false,
                message: '',
              })
            }}
          />
        ) : null}

        {this.state.isLoaded && (
          <form onSubmit={this.onSubmit}>
            <Typography variant="h6" gutterBottom>
              {this.props.title}
            </Typography>

            <ExpansionPanel
              expanded={this.state.reserarch_paper_panel}
              onChange={() =>
                this.onPanelClick(
                  'reserarch_paper_panel',
                  !this.state.reserarch_paper_panel,
                )
              }
            >
              <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                <Typography>Click here to Add Research Publications</Typography>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                <table width="100%" className="table table-sm">
                  <thead>
                    <tr>
                      <td colSpan={6} align="center">
                        <Typography>Research Publications</Typography>
                      </td>
                    </tr>
                    <tr>
                      <td>Title of the paper</td>

                      <td>Title of the Book/Journal</td>
                      <td>Published By</td>
                      <td>ISBN/ISSN</td>
                      <td>Volume</td>
                      <td>Page Nos</td>
                      <td>Year</td>
                      <td>Quarter</td>
                      <td></td>

                      <td>Click + to Add</td>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <input
                          className="form-control"
                          type="text"
                          name="title_paper"
                          value={this.state.title_paper}
                          onChange={this.onChange}
                          placeholder="Title of the Paper"
                        />
                      </td>

                      <td>
                        <input
                          className="form-control"
                          type="text"
                          name="title_book"
                          value={this.state.title_book}
                          onChange={this.onChange}
                          placeholder="Title of the Book/Journal"
                        />
                      </td>
                      <td>
                        <input
                          className="form-control"
                          type="text"
                          name="published_by"
                          value={this.state.published_by}
                          onChange={this.onChange}
                          placeholder="Published By"
                        />
                      </td>

                      <td>
                        <input
                          className="form-control"
                          type="text"
                          name="isbn_no"
                          value={this.state.isbn_no}
                          onChange={this.onChange}
                          placeholder="ISBN"
                        />
                      </td>
                      <td>
                        <input
                          className="form-control"
                          type="text"
                          name="volume"
                          value={this.state.volume}
                          onChange={this.onChange}
                          placeholder="Volume"
                        />
                      </td>

                      <td>
                        <input
                          className="form-control"
                          type="text"
                          name="page_nos"
                          value={this.state.page_nos}
                          onChange={this.onChange}
                          placeholder="Page Nos"
                        />
                      </td>

                      <td>
                        <input
                          className="form-control"
                          type="text"
                          name="date_year"
                          value={this.state.date_year}
                          onChange={this.onChange}
                          placeholder="Date / Year"
                        />
                      </td>
                      <td>
                        <select
                          className="form-control"
                          type="text"
                          name="quarter"
                          value={this.state.quarter}
                          onChange={this.onChange}
                          placeholder="Quarter"
                        >
                          <option>select</option>
                          {Array.isArray(Q_LIST) &&
                            Q_LIST.map((el, index) => (
                              <option key={index} value={el}>
                                {el}
                              </option>
                            ))}
                        </select>
                      </td>
                      <td>You can upload the files after additions</td>

                      <td>
                        <Button
                          variant="outlined"
                          onClick={this.onAddClick.bind(this, 'research_paper')}
                          type="button"
                        >
                          +
                        </Button>
                      </td>
                    </tr>
                  </tbody>
                  <tbody>
                    {Array.isArray(this.state.research_paper) &&
                      this.state.research_paper.map((el, index) => (
                        <tr key={index}>
                          <td>
                          <textarea
                      className="form-control"
                      type="text"
                      name="title_paper"
                      value={el.title_paper}
                      onChange={(e) => this.onChange1('title_paper', index, e.target.value,'research_paper')}
                      placeholder="title_paper"
                    />
                    
                          </td>
                          <td>
                          <textarea
                      className="form-control"
                      type="text"
                      name="title_book"
                      value={el.title_book}
                      onChange={(e) => this.onChange1('title_book', index, e.target.value,'research_paper')}
                      placeholder="title_book"
                    />
                            </td>
                          <td>
                          <textarea
                      className="form-control"
                      type="text"
                      name="published_by"
                      value={el.published_by}
                      onChange={(e) => this.onChange1('published_by', index, e.target.value,'research_paper')}
                      placeholder="published_by"
                    />
                    
                   </td>
                          <td>
                          <input
                      className="form-control"
                      type="text"
                      name="isbn_no"
                      value={el.isbn_no}
                      onChange={(e) => this.onChange1('isbn_no', index, e.target.value,'research_paper')}
                      placeholder="isbn_no"
                    /></td>
                          <td><input
                      className="form-control"
                      type="text"
                      name="volume"
                      value={el.volume}
                      onChange={(e) => this.onChange1('volume', index, e.target.value,'research_paper')}
                      placeholder="volume"
                    /></td>
                          <td><input
                      className="form-control"
                      type="text"
                      name="page_nos"
                      value={el.page_nos}
                      onChange={(e) => this.onChange1('page_nos', index, e.target.value,'research_paper')}
                      placeholder="page_nos"
                    /></td>
                          <td><input
                      className="form-control"
                      type="text"
                      name="date_year"
                      value={el.date_year}
                      onChange={(e) => this.onChange1('date_year', index, e.target.value,'research_paper')}
                      placeholder="date_year"
                    /></td>
                          <td>
                            <select
                              className="form-control"
                              type="text"
                              name="quarter"
                              value={el.quarter}
                              onChange={(e) =>
                                this.onChange11(
                                  index,
                                  'research_paper',
                                  'quarter',
                                  e.target.value,
                                )
                              }
                              placeholder="quarter"
                            >
                              <option>select</option>
                              {Array.isArray(Q_LIST) &&
                                Q_LIST.map((el, index) => (
                                  <option key={index} value={el}>
                                    {el}
                                  </option>
                                ))}
                            </select>
                          </td>
                          <td style={{ whiteSpace: 'nowrap' }}>
                            {Array.isArray(el.file) &&
                              el.file.map((el1, index1) => (
                                <div key={index1}>
                                  <a
                                    target="_blank"
                                    href={`${SERVER_UPLOAD}/${el1.type}/${el1.path}`}
                                    style={{ fontSize: 10 }}
                                  >
                                    [uploaded] {el1.path}
                                  </a>
                                </div>
                              ))}
                            <FileUploadFaculty
                              files={el.file}
                              onSetFile={(data) =>
                                this.onSetFile(index, 'research_paper', data)
                              }
                              type={`FACULTY_RESEARCH_PAPER`}
                              dp={el.file}
                              apikey={`1234`}
                            />
                          </td>
                          <td>
                            <Delete
                              onClick={this.onDelete.bind(
                                this,
                                index,
                                'research_paper',
                              )}
                            />
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </ExpansionPanelDetails>
            </ExpansionPanel>
            <br />

            {/* <ExpansionPanel expanded={this.state.journal_panel} onChange={() => this.onPanelClick('journal_panel',!this.state.journal_panel)}>
                                        <ExpansionPanelSummary
                                            expandIcon={<ExpandMoreIcon />}
                                        >
                                            <Typography>Click here to Add Seminar/Research Paper in Journals</Typography>
                                        </ExpansionPanelSummary>
                                        <ExpansionPanelDetails>

            <table width="100%" className="table table-sm">
              <thead>
                <tr>
                  <td colSpan={6} align="center">
                    <Typography>Seminar/Research Paper in Journals</Typography>
                  </td>
                </tr>
                <tr>
                  <td>Date / Year</td>

                  <td>Title of the paper</td>

                  <td>Title of the Journal</td>
                  <td>Published By</td>
                  <td>ISSN</td>

                  <td>Click + to Add</td>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <input
                      className="form-control"
                      type="text"
                      name="date_year"
                      value={this.state.date_year}
                      onChange={this.onChange}
                      placeholder="Date / Year"
                    />
                  </td>
                  <td>
                    <input
                      className="form-control"
                      type="text"
                      name="title_paper"
                      value={this.state.title_paper}
                      onChange={this.onChange}
                      placeholder="Title of the Paper"
                    />
                  </td>

                  <td>
                    <input
                      className="form-control"
                      type="text"
                      name="title_book"
                      value={this.state.title_book}
                      onChange={this.onChange}
                      placeholder="Title of the Journal"
                    />
                  </td>
                  <td>
                    <input
                      className="form-control"
                      type="text"
                      name="published_by"
                      value={this.state.published_by}
                      onChange={this.onChange}
                      placeholder="Published By"
                    />
                  </td>

                  <td>
                    <input
                      className="form-control"
                      type="text"
                      name="issn_no"
                      value={this.state.issn_no}
                      onChange={this.onChange}
                      placeholder="ISSN"
                    />
                  </td>

                  <td>
                    <Button
                      variant="outlined"
                      onClick={this.onAddClick.bind(this, "journal")}
                      type="button"
                    >
                      +
                    </Button>
                  </td>
                </tr>
              </tbody>
              <tbody>
                {Array.isArray(this.state.journal) && this.state.journal.map((el, index) => (
                  <tr key={index}>
                    <td>{index+1}. {el.date_year}</td>
                    <td>{el.title_paper}</td>
                    <td>{el.title_book}</td>
                    <td>{el.published_by}</td>
                    <td>{el.issn_no}</td>

                    <td>
                      <Delete
                        onClick={this.onDelete.bind(
                          this,
                          index,
                          "journal"
                        )}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            </ExpansionPanelDetails>
            </ExpansionPanel> */}

            <ExpansionPanel
              expanded={this.state.text_book_panel}
              onChange={() =>
                this.onPanelClick(
                  'text_book_panel',
                  !this.state.text_book_panel,
                )
              }
            >
              <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                <Typography>Click here to Add Text Book/Books</Typography>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                <table width="100%" className="table table-sm">
                  <thead>
                    <tr>
                      <td colSpan={5} align="center">
                        <Typography>Text Book/Book</Typography>
                      </td>
                    </tr>
                    <tr>
                      <td>Name of the Book/Text Book</td>
                      <td>Published By</td>
                      <td>ISBN</td>
                      <td>Volume</td>
                      {/* <td>Page Nos</td> */}
                      <td>Edition</td>
                      <td>Name of the Authors</td>
                      <td>Year</td>
                      <td>Quarter</td>
                      <td></td>

                      <td>Click + to Add</td>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <input
                          className="form-control"
                          type="text"
                          name="title_book"
                          value={this.state.title_book}
                          onChange={this.onChange}
                          placeholder="Title of the Book"
                        />
                      </td>
                      <td>
                        <input
                          className="form-control"
                          type="text"
                          name="published_by"
                          value={this.state.published_by}
                          onChange={this.onChange}
                          placeholder="Published By"
                        />
                      </td>

                      <td>
                        <input
                          className="form-control"
                          type="text"
                          name="isbn_no"
                          value={this.state.isbn_no}
                          onChange={this.onChange}
                          placeholder="ISBN"
                        />
                      </td>

                      <td>
                        <input
                          className="form-control"
                          type="text"
                          name="volume"
                          value={this.state.volume}
                          onChange={this.onChange}
                          placeholder="Volume"
                        />
                      </td>

                      {/* <td>
                    <input
                      className="form-control"
                      type="text"
                      name="page_nos"
                      value={this.state.page_nos}
                      onChange={this.onChange}
                      placeholder="Page Nos"
                    />
                  </td> */}

                      <td>
                        <input
                          className="form-control"
                          type="text"
                          name="edition"
                          value={this.state.edition}
                          onChange={this.onChange}
                          placeholder="Edition"
                        />
                      </td>

                      <td>
                        <input
                          className="form-control"
                          type="text"
                          name="name_of_authors"
                          value={this.state.name_of_authors}
                          onChange={this.onChange}
                          placeholder="Name of Authors"
                        />
                      </td>

                      <td>
                        <input
                          className="form-control"
                          type="text"
                          name="date_year"
                          value={this.state.date_year}
                          onChange={this.onChange}
                          placeholder="Year"
                        />
                      </td>
                      <td>
                        <select
                          className="form-control"
                          type="text"
                          name="quarter"
                          value={this.state.quarter}
                          onChange={this.onChange}
                          placeholder="Quarter"
                        >
                          <option>select</option>
                          {Array.isArray(Q_LIST) &&
                            Q_LIST.map((el, index) => (
                              <option key={index} value={el}>
                                {el}
                              </option>
                            ))}
                        </select>
                      </td>

                      <td>You can upload the files after additions</td>

                      <td>
                        <Button
                          variant="outlined"
                          onClick={this.onAddClick.bind(this, 'text_book')}
                          type="button"
                        >
                          +
                        </Button>
                      </td>
                    </tr>
                  </tbody>
                  <tbody>
                    {Array.isArray(this.state.text_book) &&
                      this.state.text_book.map((el, index) => (
                        <tr key={index}>
                          <td>
                          <textarea
                      className="form-control"
                      type="text"
                      name="title_book"
                      value={el.title_book}
                      onChange={(e) => this.onChange1('title_book', index, e.target.value,'text_book')}
                      placeholder="title_book"
                    />
                          </td>
                          <td>
                          <textarea
                      className="form-control"
                      type="text"
                      name="published_by"
                      value={el.published_by}
                      onChange={(e) => this.onChange1('published_by', index, e.target.value,'text_book')}
                      placeholder="published_by"
                    />
                    </td>
                          <td><input
                      className="form-control"
                      type="text"
                      name="isbn_no"
                      value={el.isbn_no}
                      onChange={(e) => this.onChange1('isbn_no', index, e.target.value,'text_book')}
                      placeholder="isbn_no"
                    /></td>
                          <td><input
                      className="form-control"
                      type="text"
                      name="volume"
                      value={el.volume}
                      onChange={(e) => this.onChange1('volume', index, e.target.value,'text_book')}
                      placeholder="volume"
                    /></td>
                          {/* <td>{el.page_nos}</td> */}
                          <td><input
                      className="form-control"
                      type="text"
                      name="edition"
                      value={el.edition}
                      onChange={(e) => this.onChange1('edition', index, e.target.value,'text_book')}
                      placeholder="edition"
                    /></td>
                          <td><input
                      className="form-control"
                      type="text"
                      name="name_of_authors"
                      value={el.name_of_authors}
                      onChange={(e) => this.onChange1('name_of_authors', index, e.target.value,'text_book')}
                      placeholder="name_of_authors"
                    /></td>

                          <td><input
                      className="form-control"
                      type="text"
                      name="date_year"
                      value={el.date_year}
                      onChange={(e) => this.onChange1('date_year', index, e.target.value,'text_book')}
                      placeholder="date_year"
                    /></td>
                          <td>
                            <select
                              className="form-control"
                              type="text"
                              name="quarter"
                              value={el.quarter}
                              onChange={(e) =>
                                this.onChange11(
                                  index,
                                  'text_book',
                                  'quarter',
                                  e.target.value,
                                )
                              }
                              placeholder="quarter"
                            >
                              <option>select</option>
                              {Array.isArray(Q_LIST) &&
                                Q_LIST.map((el, index) => (
                                  <option key={index} value={el}>
                                    {el}
                                  </option>
                                ))}
                            </select>
                          </td>
                          <td style={{ whiteSpace: 'nowrap' }}>
                            {Array.isArray(el.file) &&
                              el.file.map((el1, index1) => (
                                <div key={index1}>
                                  <a
                                    target="_blank"
                                    href={`${SERVER_UPLOAD}/${el1.type}/${el1.path}`}
                                    style={{ fontSize: 10 }}
                                  >
                                    [uploaded] {el1.path}
                                  </a>
                                </div>
                              ))}
                            <FileUploadFaculty
                              files={el.file}
                              onSetFile={(data) =>
                                this.onSetFile(index, 'text_book', data)
                              }
                              type={`FACULTY_TEXT_BOOK`}
                              dp={el.file}
                              apikey={`1234`}
                            />
                          </td>
                          <td>
                            <Delete
                              onClick={this.onDelete.bind(
                                this,
                                index,
                                'text_book',
                              )}
                            />
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </ExpansionPanelDetails>
            </ExpansionPanel>

            <br />
            <ExpansionPanel
              expanded={this.state.magazine_panel}
              onChange={() =>
                this.onPanelClick('magazine_panel', !this.state.magazine_panel)
              }
            >
              <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                <Typography>
                  Click here to Add Book/Journal/ Magazine Edited
                </Typography>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                <table width="100%" className="table table-sm">
                  <thead>
                    <tr>
                      <td colSpan={6} align="center">
                        <Typography>Book/Journal/ Magazine Edited</Typography>
                      </td>
                    </tr>
                    <tr>
                      <td>Name of the Book/journal/Magazine</td>
                      <td>Published By</td>
                      <td>ISBN / ISSN</td>
                      <td>Any other information</td>
                      <td>Year</td>
                      <td>Quarter</td>
                      <td></td>
                      <td>Click + to Add</td>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <input
                          className="form-control"
                          type="text"
                          name="title_book"
                          value={this.state.title_book}
                          onChange={this.onChange}
                          placeholder="Title of the
                      Book/journal/Magazine"
                        />
                      </td>
                      <td>
                        <input
                          className="form-control"
                          type="text"
                          name="published_by"
                          value={this.state.published_by}
                          onChange={this.onChange}
                          placeholder="Published By"
                        />
                      </td>

                      <td>
                        <input
                          className="form-control"
                          type="text"
                          name="isbn_no"
                          value={this.state.isbn_no}
                          onChange={this.onChange}
                          placeholder="ISBN / ISSN"
                        />
                      </td>

                      <td>
                        <input
                          className="form-control"
                          type="text"
                          name="other_info"
                          value={this.state.other_info}
                          onChange={this.onChange}
                          placeholder="Any other
                      information"
                        />
                      </td>
                      <td>
                        <input
                          className="form-control"
                          type="text"
                          name="date_year"
                          value={this.state.date_year}
                          onChange={this.onChange}
                          placeholder="Year"
                        />
                      </td>
                      <td>
                        <select
                          className="form-control"
                          type="text"
                          name="quarter"
                          value={this.state.quarter}
                          onChange={this.onChange}
                          placeholder="Quarter"
                        >
                          <option>select</option>
                          {Array.isArray(Q_LIST) &&
                            Q_LIST.map((el, index) => (
                              <option key={index} value={el}>
                                {el}
                              </option>
                            ))}
                        </select>
                      </td>
                      <td>You can upload the files after additions</td>

                      <td>
                        <Button
                          variant="outlined"
                          onClick={this.onAddClick.bind(this, 'magazine')}
                          type="button"
                        >
                          +
                        </Button>
                      </td>
                    </tr>
                  </tbody>
                  <tbody>
                    {Array.isArray(this.state.magazine) &&
                      this.state.magazine.map((el, index) => (
                        <tr key={index}>
                          <td>
                          <input
                      className="form-control"
                      type="text"
                      name="title_book"
                      value={el.title_book}
                      onChange={(e) => this.onChange1('title_book', index, e.target.value,'magazine')}
                      placeholder="title_book"
                    />
                          </td>
                          <td> <input
                      className="form-control"
                      type="text"
                      name="published_by"
                      value={el.published_by}
                      onChange={(e) => this.onChange1('published_by', index, e.target.value,'magazine')}
                      placeholder="published_by"
                    /></td>
                          <td> <input
                      className="form-control"
                      type="text"
                      name="isbn_no"
                      value={el.isbn_no}
                      onChange={(e) => this.onChange1('isbn_no', index, e.target.value,'magazine')}
                      placeholder="isbn_no"
                    /></td>
                          <td><input
                      className="form-control"
                      type="text"
                      name="other_info"
                      value={el.other_info}
                      onChange={(e) => this.onChange1('other_info', index, e.target.value,'magazine')}
                      placeholder="other_info"
                    /></td>
                          <td><input
                      className="form-control"
                      type="text"
                      name="date_year"
                      value={el.date_year}
                      onChange={(e) => this.onChange1('date_year', index, e.target.value,'magazine')}
                      placeholder="date_year"
                    /></td>
                          <td>
                            <select
                              className="form-control"
                              type="text"
                              name="quarter"
                              value={el.quarter}
                              onChange={(e) =>
                                this.onChange11(
                                  index,
                                  'magazine',
                                  'quarter',
                                  e.target.value,
                                )
                              }
                              placeholder="quarter"
                            >
                              <option>select</option>
                              {Array.isArray(Q_LIST) &&
                                Q_LIST.map((el, index) => (
                                  <option key={index} value={el}>
                                    {el}
                                  </option>
                                ))}
                            </select>
                          </td>
                          <td style={{ whiteSpace: 'nowrap' }}>
                            {Array.isArray(el.file) &&
                              el.file.map((el1, index1) => (
                                <div key={index1}>
                                  <a
                                    target="_blank"
                                    href={`${SERVER_UPLOAD}/${el1.type}/${el1.path}`}
                                    style={{ fontSize: 10 }}
                                  >
                                    [uploaded] {el1.path}
                                  </a>
                                </div>
                              ))}
                            <FileUploadFaculty
                              files={el.file}
                              onSetFile={(data) =>
                                this.onSetFile(index, 'magazine', data)
                              }
                              type={`FACULTY_MAGAZINE`}
                              dp={el.file}
                              apikey={`1234`}
                            />
                          </td>
                          <td>
                            <Delete
                              onClick={this.onDelete.bind(
                                this,
                                index,
                                'magazine',
                              )}
                            />
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </ExpansionPanelDetails>
            </ExpansionPanel>
            <br />
            <ExpansionPanel
              expanded={this.state.popular_book_panel}
              onChange={() =>
                this.onPanelClick(
                  'popular_book_panel',
                  !this.state.popular_book_panel,
                )
              }
            >
              <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                <Typography>
                  Click here to Add Article in Popular Book/Magazine/Edited Book
                </Typography>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                <table width="100%" className="table table-sm">
                  <thead>
                    <tr>
                      <td colSpan={6} align="center">
                        <Typography>
                          Article in Popular Book/Magazine/Edited Book
                        </Typography>
                      </td>
                    </tr>
                    <tr>
                      <td>Title of the paper</td>

                      <td>Title of the Book</td>
                      <td>Published By</td>
                      <td>ISBN</td>
                      <td>Volume</td>
                      <td>Page Nos</td>
                      <td>Date / Year</td>
                      <td>Quarter</td>
                      <td></td>

                      <td>Click + to Add</td>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <input
                          className="form-control"
                          type="text"
                          name="title_paper"
                          value={this.state.title_paper}
                          onChange={this.onChange}
                          placeholder="Title of the Paper"
                        />
                      </td>

                      <td>
                        <input
                          className="form-control"
                          type="text"
                          name="title_book"
                          value={this.state.title_book}
                          onChange={this.onChange}
                          placeholder="Title of the Book"
                        />
                      </td>
                      <td>
                        <input
                          className="form-control"
                          type="text"
                          name="published_by"
                          value={this.state.published_by}
                          onChange={this.onChange}
                          placeholder="Published By"
                        />
                      </td>

                      <td>
                        <input
                          className="form-control"
                          type="text"
                          name="isbn_no"
                          value={this.state.isbn_no}
                          onChange={this.onChange}
                          placeholder="ISBN No"
                        />
                      </td>

                      <td>
                        <input
                          className="form-control"
                          type="text"
                          name="volume"
                          value={this.state.volume}
                          onChange={this.onChange}
                          placeholder="Volume"
                        />
                      </td>

                      <td>
                        <input
                          className="form-control"
                          type="text"
                          name="page_nos"
                          value={this.state.page_nos}
                          onChange={this.onChange}
                          placeholder="Page Nos"
                        />
                      </td>

                      <td>
                        <input
                          className="form-control"
                          type="text"
                          name="date_year"
                          value={this.state.date_year}
                          onChange={this.onChange}
                          placeholder="Year"
                        />
                      </td>
                      <td>
                        <select
                          className="form-control"
                          type="text"
                          name="quarter"
                          value={this.state.quarter}
                          onChange={this.onChange}
                          placeholder="Quarter"
                        >
                          <option>select</option>
                          {Array.isArray(Q_LIST) &&
                            Q_LIST.map((el, index) => (
                              <option key={index} value={el}>
                                {el}
                              </option>
                            ))}
                        </select>
                      </td>

                      <td>You can upload the files after additions</td>

                      <td>
                        <Button
                          variant="outlined"
                          onClick={this.onAddClick.bind(this, 'popular_book')}
                          type="button"
                        >
                          +
                        </Button>
                      </td>
                    </tr>
                  </tbody>
                  <tbody>
                    {Array.isArray(this.state.popular_book) &&
                      this.state.popular_book.map((el, index) => (
                        <tr key={index}>
                          <td>
                          <textarea
                      className="form-control"
                      type="text"
                      name="title_paper"
                      value={el.title_paper}
                      onChange={(e) => this.onChange1('title_paper', index, e.target.value,'popular_book')}
                      placeholder="title_paper"
                    /> 
                          </td>
                          <td>  <textarea
                      className="form-control"
                      type="text"
                      name="title_book"
                      value={el.title_book}
                      onChange={(e) => this.onChange1('title_book', index, e.target.value,'popular_book')}
                      placeholder="title_book"
                    /> </td>
                          <td><textarea
                      className="form-control"
                      type="text"
                      name="published_by"
                      value={el.published_by}
                      onChange={(e) => this.onChange1('published_by', index, e.target.value,'popular_book')}
                      placeholder="published_by"
                    /></td>
                          <td><input
                      className="form-control"
                      type="text"
                      name="isbn_no"
                      value={el.isbn_no}
                      onChange={(e) => this.onChange1('isbn_no', index, e.target.value,'popular_book')}
                      placeholder="isbn_no"
                    /></td>
                          <td><input
                      className="form-control"
                      type="text"
                      name="volume"
                      value={el.volume}
                      onChange={(e) => this.onChange1('volume', index, e.target.value,'popular_book')}
                      placeholder="volume"
                    /></td>
                          <td><input
                      className="form-control"
                      type="text"
                      name="page_nos"
                      value={el.page_nos}
                      onChange={(e) => this.onChange1('page_nos', index, e.target.value,'popular_book')}
                      placeholder="page_nos"
                    /></td>
                          <td><input
                      className="form-control"
                      type="text"
                      name="date_year"
                      value={el.date_year}
                      onChange={(e) => this.onChange1('date_year', index, e.target.value,'popular_book')}
                      placeholder="date_year"
                    /></td>
                          <td>
                            <select
                              className="form-control"
                              type="text"
                              name="quarter"
                              value={el.quarter}
                              onChange={(e) =>
                                this.onChange11(
                                  index,
                                  'popular_book',
                                  'quarter',
                                  e.target.value,
                                )
                              }
                              placeholder="quarter"
                            >
                              <option>select</option>
                              {Array.isArray(Q_LIST) &&
                                Q_LIST.map((el, index) => (
                                  <option key={index} value={el}>
                                    {el}
                                  </option>
                                ))}
                            </select>
                          </td>
                          <td style={{ whiteSpace: 'nowrap' }}>
                            {Array.isArray(el.file) &&
                              el.file.map((el1, index1) => (
                                <div key={index1}>
                                  <a
                                    target="_blank"
                                    href={`${SERVER_UPLOAD}/${el1.type}/${el1.path}`}
                                    style={{ fontSize: 10 }}
                                  >
                                    [uploaded] {el1.path}
                                  </a>
                                </div>
                              ))}
                            <FileUploadFaculty
                              files={el.file}
                              onSetFile={(data) =>
                                this.onSetFile(index, 'popular_book', data)
                              }
                              type={`FACULTY_POPULAR_BOOK`}
                              dp={el.file}
                              apikey={`1234`}
                            />
                          </td>
                          <td>
                            <Delete
                              onClick={this.onDelete.bind(
                                this,
                                index,
                                'popular_book',
                              )}
                            />
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </ExpansionPanelDetails>
            </ExpansionPanel>

            <br />
            <ExpansionPanel
              expanded={this.state.news_paper_panel}
              onChange={() =>
                this.onPanelClick(
                  'news_paper_panel',
                  !this.state.news_paper_panel,
                )
              }
            >
              <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                <Typography>
                  Click here to Add Publication in News Paper
                </Typography>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                <table width="100%" className="table table-sm">
                  <thead>
                    <tr>
                      <td colSpan={4} align="center">
                        <Typography>Publication in News Paper</Typography>
                      </td>
                    </tr>
                    <tr>
                      <td>Date / Year</td>

                      <td>Title of the Article</td>

                      <td>Name of the News Paper</td>
                      <td>Quarter</td>

                      <td></td>
                      <td>Click + to Add</td>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <input
                          className="form-control"
                          type="text"
                          name="date_year"
                          value={this.state.date_year}
                          onChange={this.onChange}
                          placeholder="Date / Year"
                        />
                      </td>
                      <td>
                        <input
                          className="form-control"
                          type="text"
                          name="title_paper"
                          value={this.state.title_paper}
                          onChange={this.onChange}
                          placeholder="Title of the Article"
                        />
                      </td>

                      <td>
                        <input
                          className="form-control"
                          type="text"
                          name="title_book"
                          value={this.state.title_book}
                          onChange={this.onChange}
                          placeholder="Name of the News Paper"
                        />
                      </td>
                      <td>
                        <select
                          className="form-control"
                          type="text"
                          name="quarter"
                          value={this.state.quarter}
                          onChange={this.onChange}
                          placeholder="Quarter"
                        >
                          <option>select</option>
                          {Array.isArray(Q_LIST) &&
                            Q_LIST.map((el, index) => (
                              <option key={index} value={el}>
                                {el}
                              </option>
                            ))}
                        </select>
                      </td>

                      <td>You can upload the files after additions</td>

                      <td>
                        <Button
                          variant="outlined"
                          onClick={this.onAddClick.bind(this, 'news_paper')}
                          type="button"
                        >
                          +
                        </Button>
                      </td>
                    </tr>
                  </tbody>
                  <tbody>
                    {Array.isArray(this.state.news_paper) &&
                      this.state.news_paper.map((el, index) => (
                        <tr key={index}>
                          <td>
                          <input
                      className="form-control"
                      type="text"
                      name="date_year"
                      value={el.date_year}
                      onChange={(e) => this.onChange1('date_year', index, e.target.value,'news_paper')}
                      placeholder="date_year"
                    />
                          </td>
                          <td>
                          <input
                      className="form-control"
                      type="text"
                      name="title_paper"
                      value={el.title_paper}
                      onChange={(e) => this.onChange1('title_paper', index, e.target.value,'news_paper')}
                      placeholder="title_paper"
                    />
                            </td>
                          <td> <input
                      className="form-control"
                      type="text"
                      name="title_book"
                      value={el.title_book}
                      onChange={(e) => this.onChange1('title_book', index, e.target.value,'news_paper')}
                      placeholder="title_book"
                    /></td>
                          <td>
                            <select
                              className="form-control"
                              type="text"
                              name="quarter"
                              value={el.quarter}
                              onChange={(e) =>
                                this.onChange11(
                                  index,
                                  'news_paper',
                                  'quarter',
                                  e.target.value,
                                )
                              }
                              placeholder="quarter"
                            >
                              <option>select</option>
                              {Array.isArray(Q_LIST) &&
                                Q_LIST.map((el, index) => (
                                  <option key={index} value={el}>
                                    {el}
                                  </option>
                                ))}
                            </select>
                          </td>

                          <td style={{ whiteSpace: 'nowrap' }}>
                            {Array.isArray(el.file) &&
                              el.file.map((el1, index1) => (
                                <div key={index1}>
                                  <a
                                    target="_blank"
                                    href={`${SERVER_UPLOAD}/${el1.type}/${el1.path}`}
                                    style={{ fontSize: 10 }}
                                  >
                                    [uploaded] {el1.path}
                                  </a>
                                </div>
                              ))}
                            <FileUploadFaculty
                              files={el.file}
                              onSetFile={(data) =>
                                this.onSetFile(index, 'news_paper', data)
                              }
                              type={`FACULTY_NEWS_PAPER`}
                              dp={el.file}
                              apikey={`1234`}
                            />
                          </td>
                          <td>
                            <Delete
                              onClick={this.onDelete.bind(
                                this,
                                index,
                                'news_paper',
                              )}
                            />
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </ExpansionPanelDetails>
            </ExpansionPanel>
            <br />

            <ExpansionPanel
              expanded={this.state.other_publication_panel}
              onChange={() =>
                this.onPanelClick(
                  'other_publication_panel',
                  !this.state.other_publication_panel,
                )
              }
            >
              <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                <Typography>
                  Click here to Add Other Publication with details
                </Typography>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                <table width="100%" className="table table-sm">
                  <thead>
                    <tr>
                      <td colSpan={6} align="center">
                        <Typography>Other Publication with details</Typography>
                      </td>
                    </tr>
                    <tr>
                      <td>Title of the Publication</td>
                      <td>Published By</td>
                      <td>ISBN / ISSN</td>
                      <td>Volume</td>
                      <td>Page Nos</td>
                      <td>Year</td>
                      <td>Quarter</td>
                      <td>Any other information</td>
                      <td></td>
                      <td>Click + to Add</td>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <input
                          className="form-control"
                          type="text"
                          name="title_book"
                          value={this.state.title_book}
                          onChange={this.onChange}
                          placeholder="Title of the
                      Publication"
                        />
                      </td>
                      <td>
                        <input
                          className="form-control"
                          type="text"
                          name="published_by"
                          value={this.state.published_by}
                          onChange={this.onChange}
                          placeholder="Published By"
                        />
                      </td>

                      <td>
                        <input
                          className="form-control"
                          type="text"
                          name="isbn_no"
                          value={this.state.isbn_no}
                          onChange={this.onChange}
                          placeholder="ISBN / ISSN"
                        />
                      </td>

                      <td>
                        <input
                          className="form-control"
                          type="text"
                          name="volume"
                          value={this.state.volume}
                          onChange={this.onChange}
                          placeholder="Volume"
                        />
                      </td>

                      <td>
                        <input
                          className="form-control"
                          type="text"
                          name="page_nos"
                          value={this.state.page_nos}
                          onChange={this.onChange}
                          placeholder="Page Nos"
                        />
                      </td>
                      <td>
                        <input
                          className="form-control"
                          type="text"
                          name="date_year"
                          value={this.state.date_year}
                          onChange={this.onChange}
                          placeholder="Year"
                        />
                      </td>
                      <td>
                        <select
                          className="form-control"
                          type="text"
                          name="quarter"
                          value={this.state.quarter}
                          onChange={this.onChange}
                          placeholder="Quarter"
                        >
                          <option>select</option>
                          {Array.isArray(Q_LIST) &&
                            Q_LIST.map((el, index) => (
                              <option key={index} value={el}>
                                {el}
                              </option>
                            ))}
                        </select>
                      </td>

                      <td>
                        <input
                          className="form-control"
                          type="text"
                          name="other_info"
                          value={this.state.other_info}
                          onChange={this.onChange}
                          placeholder="Any other
                      information"
                        />
                      </td>

                      <td>You can upload the files after additions</td>

                      <td>
                        <Button
                          variant="outlined"
                          onClick={this.onAddClick.bind(
                            this,
                            'other_publication',
                          )}
                          type="button"
                        >
                          +
                        </Button>
                      </td>
                    </tr>
                  </tbody>
                  <tbody>
                    {Array.isArray(this.state.other_publication) &&
                      this.state.other_publication.map((el, index) => (
                        <tr key={index}>
                          <td>
                          <input
                      className="form-control"
                      type="text"
                      name="title_book"
                      value={el.title_book}
                      onChange={(e) => this.onChange1('title_book', index, e.target.value,'other_publication')}
                      placeholder="title_book"
                    />
                          </td>
                          <td><input
                      className="form-control"
                      type="text"
                      name="published_by"
                      value={el.published_by}
                      onChange={(e) => this.onChange1('published_by', index, e.target.value,'other_publication')}
                      placeholder="published_by"
                    /></td>
                          <td><input
                      className="form-control"
                      type="text"
                      name="isbn_no"
                      value={el.isbn_no}
                      onChange={(e) => this.onChange1('isbn_no', index, e.target.value,'other_publication')}
                      placeholder="isbn_no"
                    /></td>
                          <td><input
                      className="form-control"
                      type="text"
                      name="volume"
                      value={el.volume}
                      onChange={(e) => this.onChange1('volume', index, e.target.value,'other_publication')}
                      placeholder="volume"
                    /></td>
                          <td><input
                      className="form-control"
                      type="text"
                      name="page_nos"
                      value={el.page_nos}
                      onChange={(e) => this.onChange1('page_nos', index, e.target.value,'other_publication')}
                      placeholder="page_nos"
                    /></td>
                          <td><input
                      className="form-control"
                      type="text"
                      name="date_year"
                      value={el.date_year}
                      onChange={(e) => this.onChange1('date_year', index, e.target.value,'other_publication')}
                      placeholder="date_year"
                    /></td>
                          <td>
                            <select
                              className="form-control"
                              type="text"
                              name="quarter"
                              value={el.quarter}
                              onChange={(e) =>
                                this.onChange11(
                                  index,
                                  'other_publication',
                                  'quarter',
                                  e.target.value,
                                )
                              }
                              placeholder="quarter"
                            >
                              <option>select</option>
                              {Array.isArray(Q_LIST) &&
                                Q_LIST.map((el, index) => (
                                  <option key={index} value={el}>
                                    {el}
                                  </option>
                                ))}
                            </select>
                          </td>
                          <td>{el.other_info}</td>
                          <td style={{ whiteSpace: 'nowrap' }}>
                            {Array.isArray(el.file) &&
                              el.file.map((el1, index1) => (
                                <div key={index1}>
                                  <a
                                    target="_blank"
                                    href={`${SERVER_UPLOAD}/${el1.type}/${el1.path}`}
                                    style={{ fontSize: 10 }}
                                  >
                                    [uploaded] {el1.path}
                                  </a>
                                </div>
                              ))}
                            <FileUploadFaculty
                              files={el.file}
                              onSetFile={(data) =>
                                this.onSetFile(index, 'other_publication', data)
                              }
                              type={`FACULTY_OTHER_PUBLICATION`}
                              dp={el.file}
                              apikey={`1234`}
                            />
                          </td>
                          <td>
                            <Delete
                              onClick={this.onDelete.bind(
                                this,
                                index,
                                'other_publication',
                              )}
                            />
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </ExpansionPanelDetails>
            </ExpansionPanel>

            <br />
            <ExpansionPanel
              expanded={this.state.econtent_panel}
              onChange={() =>
                this.onPanelClick('econtent_panel', !this.state.econtent_panel)
              }
            >
              <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                <Typography>Click here to Add E-CONTENT DEVELOPMENT</Typography>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                <table width="100%" className="table table-sm">
                  <thead>
                    <tr>
                      <td colSpan={6} align="center">
                        <Typography>E-CONTENT DEVELOPMENT</Typography>
                      </td>
                    </tr>
                    <tr>
                      <td>Year</td>

                      <td>Type of E-content</td>
                      <td>Title of the Publication</td>
                      <td>Published By</td>

                      <td>Remarks</td>
                      <td>Quarter</td>
                      <td></td>
                      <td>Click + to Add</td>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <input
                          className="form-control"
                          type="text"
                          name="date_year"
                          value={this.state.date_year}
                          onChange={this.onChange}
                          placeholder="Year"
                        />
                      </td>

                      <td>
                        <input
                          className="form-control"
                          type="text"
                          name="title_paper"
                          value={this.state.title_paper}
                          onChange={this.onChange}
                          placeholder="Type of
                      E-content"
                        />
                      </td>

                      <td>
                        <input
                          className="form-control"
                          type="text"
                          name="title_book"
                          value={this.state.title_book}
                          onChange={this.onChange}
                          placeholder="Title of the Publication"
                        />
                      </td>
                      <td>
                        <input
                          className="form-control"
                          type="text"
                          name="published_by"
                          value={this.state.published_by}
                          onChange={this.onChange}
                          placeholder="Published By"
                        />
                      </td>

                      <td>
                        <input
                          className="form-control"
                          type="text"
                          name="other_info"
                          value={this.state.other_info}
                          onChange={this.onChange}
                          placeholder="Remarks"
                        />
                      </td>

                      <td>
                        <select
                          className="form-control"
                          type="text"
                          name="quarter"
                          value={this.state.quarter}
                          onChange={this.onChange}
                          placeholder="Quarter"
                        >
                          <option>select</option>
                          {Array.isArray(Q_LIST) &&
                            Q_LIST.map((el, index) => (
                              <option key={index} value={el}>
                                {el}
                              </option>
                            ))}
                        </select>
                      </td>

                      <td>You can upload the files after additions</td>

                      <td>
                        <Button
                          variant="outlined"
                          onClick={this.onAddClick.bind(this, 'econtent')}
                          type="button"
                        >
                          +
                        </Button>
                      </td>
                    </tr>
                  </tbody>
                  <tbody>
                    {Array.isArray(this.state.econtent) &&
                      this.state.econtent.map((el, index) => (
                        <tr key={index}>
                          <td>
                          <input
                      className="form-control"
                      type="text"
                      name="date_year"
                      value={el.date_year}
                      onChange={(e) => this.onChange1('date_year', index, e.target.value,'econtent')}
                      placeholder="date_year"
                    />
                          </td>

                          <td>
                          <input
                      className="form-control"
                      type="text"
                      name="title_paper"
                      value={el.title_paper}
                      onChange={(e) => this.onChange1('title_paper', index, e.target.value,'econtent')}
                      placeholder="title_paper"
                    />
                           </td>
                          <td>
                          <input
                      className="form-control"
                      type="text"
                      name="title_book"
                      value={el.title_book}
                      onChange={(e) => this.onChange1('title_book', index, e.target.value,'econtent')}
                      placeholder="title_book"
                    />
                          </td>
                          <td> <input
                      className="form-control"
                      type="text"
                      name="published_by"
                      value={el.published_by}
                      onChange={(e) => this.onChange1('published_by', index, e.target.value,'econtent')}
                      placeholder="published_by"
                    /></td>
                          <td> <input
                      className="form-control"
                      type="text"
                      name="other_info"
                      value={el.other_info}
                      onChange={(e) => this.onChange1('other_info', index, e.target.value,'econtent')}
                      placeholder="other_info"
                    /></td>
                          <td>
                            <select
                              className="form-control"
                              type="text"
                              name="quarter"
                              value={el.quarter}
                              onChange={(e) =>
                                this.onChange11(
                                  index,
                                  'econtent',
                                  'quarter',
                                  e.target.value,
                                )
                              }
                              placeholder="quarter"
                            >
                              <option>select</option>
                              {Array.isArray(Q_LIST) &&
                                Q_LIST.map((el, index) => (
                                  <option key={index} value={el}>
                                    {el}
                                  </option>
                                ))}
                            </select>
                          </td>
                          <td style={{ whiteSpace: 'nowrap' }}>
                            {Array.isArray(el.file) &&
                              el.file.map((el1, index1) => (
                                <div key={index1}>
                                  <a
                                    target="_blank"
                                    href={`${SERVER_UPLOAD}/${el1.type}/${el1.path}`}
                                    style={{ fontSize: 10 }}
                                  >
                                    [uploaded] {el1.path}
                                  </a>
                                </div>
                              ))}
                            <FileUploadFaculty
                              files={el.file}
                              onSetFile={(data) =>
                                this.onSetFile(index, 'econtent', data)
                              }
                              type={`FACULTY_E_CONTENT`}
                              dp={el.file}
                              apikey={`1234`}
                            />
                          </td>
                          <td>
                            <Delete
                              onClick={this.onDelete.bind(
                                this,
                                index,
                                'econtent',
                              )}
                            />
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </ExpansionPanelDetails>
            </ExpansionPanel>
            <br />

            <div align="right">
              <Button size="sm" variant="info" type="submit">
                Submit
              </Button>
            </div>
          </form>
        )}
      </div>
    )
  }
}

export default FacultyPublications
