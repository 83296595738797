import { Paper, Tab, Tabs, Typography } from "@material-ui/core";
import React from "react";
import DepartmentUsers from "./VisitorsNote/DepartmentUsers";

export default function MessagesLanding({ apikey, username, role, history }) {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div>
      <div style={{ paddingTop: 10 }}>
        <Paper square>
          <Tabs
            value={value}
            indicatorColor="primary"
            textColor="primary"
            onChange={(e, n) =>
              setValue(n)
            }
            aria-label="disabled tabs example"
          >
            <Tab label="Alumni Messages" />
            <Tab label="Student Messages" />
          </Tabs>
          <DepartmentUsers apikey={apikey} type={value == 0 ? "ALUMNI" : "STUDENT"} />
        </Paper>
      </div>
    </div>
  );
}
