import React, { Component } from "react";
import {
  Typography,
  Paper,
  Tabs as Tabs1,
  Tab as Tab1,
} from "@material-ui/core";
import { Tabs, Tab } from "react-bootstrap";
import { GetData } from "../../api/service";
import AcademicAbout from "../Academics/AcademicAbout";
import PhotosOther from "../Photos/PhotosOther";
import DepartmentAchievements from "../Department/DepartmentAchievements";
import DepartmentActivity from "../Department/DepartmentActivity";
import CollegeEventShort from "../College/CollegeEventShort";
import CollegeGB from "../College/CollegeGB";

const smallTab = [
  {
    title: "Home",
    active: 0,
  },
  {
    title: "Banner",
    active: 1,
  },
  {
    title: "Activities",
    active: 2,
  },

  {
    title: "Achievements",
    active: 3,
  },
  {
    title: "Members",
    active: 4,
  },
];

class NssHomeLanding extends Component {
  state = {
    active: 0,
    dept_code: "PRAG",

    data: {},
    isDeptLoaded: false,
  };

  __getDeptData = (dept_code) => {
    GetData(`/admin/${dept_code}/getsingledeptdata`).then((resp) => {
      //console.log(resp)
      if (resp != false) {
        this.setState({
          data: resp,
          active: 0,
          isDeptLoaded: true,
        });
      } else {
        this.setState({
          data: {},
          active: 0,
          isDeptLoaded: true,
        });
      }
    });
  };

  componentDidMount() {
    this.__getDeptData(this.state.dept_code);
  }

  render() {
    return (
      <div>
        <Typography variant="h6">National Service Scheme (NSS)</Typography>
        <Typography variant="subtitle2">
          Code: {this.state.dept_code}
        </Typography>

        <br />

        {this.state.isDeptLoaded && (
          <Paper square>
            <Tabs1
              value={this.state.active}
              indicatorColor="primary"
              textColor="primary"
              onChange={(e, newValue) =>
                this.setState({
                  active: newValue,
                })
              }
              aria-label=""
            >
              {smallTab.map((el, index) => (
                <Tab1 value={el.active} key={el.active} label={el.title} />
              ))}
            </Tabs1>
          </Paper>
        )}
        <br />
        {this.state.active == 0 ? (
          <AcademicAbout type={`NSS_HOME`} title="Add NSS Details" />
        ) : null}

        {this.state.active == 1 ? (
          <div>
            <PhotosOther
              dept={"PRAG"}
              photos_list={["NSS_BANNER"]}
              photos_list_new={[
                {
                  title: "NSS Banner Images",
                  value: "NSS_BANNER",
                },
              ]}
            />
          </div>
        ) : null}

        {this.state.active == 3 ? (
          <div>
            <CollegeEventShort dept_code={"PRAG"} dept_name={"Webadmin"} {...this.props} title="Add Achievements" type={"NSS_ACHIEVEMENTS"} />
          </div>
        ) : null}

        {this.state.active == 2 ? (
          <div>
          <CollegeEventShort dept_code={"PRAG"} dept_name={"Webadmin"} {...this.props} title="Add Activities" type={"NSS_ACTIVITIES"} />
          </div>
        ) : null}

        {this.state.active == 4 ? (
          <CollegeGB title="NSS Members" {...this.state.data} dept_code={`NSS_MEMBERS`} />
        ) : null}
        
      </div>
    );
  }
}

export default NssHomeLanding;
