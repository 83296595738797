import React, { Component } from "react";
import {
  Typography,
  Grid,
  Table,
  TableHead,
  TableCell,
  TableRow,
  TableBody,
  Chip,
  FormControlLabel,
  Switch,
} from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { Button, FormControl, Card } from "react-bootstrap";
import { PostData, GetData, DeleteData, PutData } from "../../api/service";
import Snack from "../Snackbar/Snack";
import FileUpload from "../AddNew/FileUpload";
import Edit from "@material-ui/icons/Edit";
import Delete from "@material-ui/icons/Delete";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import DepartmentAlumniBulk from "./DepartmentAlumniBulk";

class DepartmentAlumni extends Component {
  state = {
    id: "",
    edit: false,
    name: "",
    batch: "",
    current_profession: "",
    qualification: "",
    email: "",
    phone: "",
    data: [],
    isDataLoaded: false,
    dept_code: this.props.dept_code,
    message: "",
    open: false,
    loadFile: true,
    tabValue: 0,
  };

  handleChange = (name, value) => {
    this.setState({
      [name]: value,
    });
  };

  onChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  __getDeptData = () => {
    GetData(`/${this.props.dept_code}/getdepartmentalumni`).then((resp) => {
      //console.log(resp)
      this.setState({
        data: resp,
        isDataLoaded: true,
      });
    });
  };

  componentDidMount() {
    this.__getDeptData();
  }

  onSubmit = (e) => {
    e.preventDefault();

    let d = {
      id: this.state.id,
      name: this.state.name,
      address: this.state.address,
      batch: this.state.batch,
      email: this.state.email,
      phone: this.state.phone,
      whatsapp_number: this.state.whatsapp_number,
      qualification: this.state.qualification,
      current_profession: this.state.current_profession,
      dept_code: this.props.dept_code,
    };

    if (this.state.edit) {
      PutData(`/admin/${this.state.id}/editalumni`, d).then((resp) => {
        //console.log(resp)
        this.setState(
          {
            message: resp,
            open: true,
          },
          () => this.__getDeptData()
        );
        this.cancelEdit();
      });
    } else {
      PostData(`/admin/addalumni`, d).then((resp) => {
        //console.log(resp)
        this.setState(
          {
            message: resp,
            open: true,
          },
          () => this.__getDeptData()
        );
        this.cancelEdit();
      });
    }
  };

  onDeleteClick = (el) => {
    confirmAlert({
      title: "Confirm to delete",
      message: "Are you sure to do this.",
      buttons: [
        {
          label: "Yes",
          onClick: this.onDeleteConfirm.bind(this, el.id),
        },
        {
          label: "No",
        },
      ],
    });
  };

  onDeleteConfirm = (id) => {
    DeleteData(`/${this.props.apikey}/${id}/deletealumni`).then((resp) => {
      this.setState(
        {
          message: resp,
          open: true,
        },
        () => this.__getDeptData()
      );
    });
  };

  onEditClick = (el) => {
    this.setState(
      {
        ...el,
        edit: true,
        loadFile: false,
      },
      () => {
        this.setState({
          loadFile: true,
        });
      }
    );
  };

  cancelEdit = () => {
    this.setState(
      {
        id: "",
        name: "",
        batch: "",
        email: "",
        whatsapp_number: "",
        address: "",
        phone: "",
        current_profession: "",
        qualification: "",
        edit: false,
        loadFile: false,
      },
      () => {
        this.setState({
          loadFile: true,
        });
      }
    );
  };

  toggleVerification = (data, new_status) => {
    PostData(`/change-verification-status`, {
      id: data.id,
      new_status,
    }).then((resp) => {
      if (resp == true) {
        // update data
        let dd = this.state.data;
        let index1 = dd.findIndex((el) => el.id == data.id);
        if (index1 > -1) {
          dd[index1]["is_verified"] = new_status;
        }

        this.setState({
          data: dd,
        });
      }
    });
  };

  render() {
    return (
      <div>
        {this.state.open ? (
          <Snack
            open={this.state.open}
            message={this.state.message}
            handleClose={() => {
              this.setState({
                open: false,
                message: "",
              });
            }}
          />
        ) : null}

        <form onSubmit={this.onSubmit}>
          <Typography variant="h6" gutterBottom>
            Department Alumni
          </Typography>

          <Card>
            <Card.Body>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={4} lg={4}>
                  <label>Name</label>
                  <FormControl
                    as="input"
                    name="name"
                    required
                    onChange={this.onChange}
                    value={this.state.name}
                    placeholder="Name of the alumni"
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4}>
                  <label>Batch</label>
                  <FormControl
                    as="input"
                    name="batch"
                    required
                    onChange={this.onChange}
                    value={this.state.batch}
                    placeholder="Batch e.g 2019-22"
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4}>
                  <label>Current Profession</label>
                  <FormControl
                    as="input"
                    name="current_profession"
                    onChange={this.onChange}
                    value={this.state.current_profession}
                    placeholder="Current Profession"
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={4} lg={4}>
                  <label>Highest Qualification</label>
                  <FormControl
                    as="input"
                    name="qualification"
                    onChange={this.onChange}
                    value={this.state.qualification}
                    placeholder="Highest Qualification"
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={4} lg={4}>
                  <label>Email</label>
                  <FormControl
                    as="input"
                    name="email"
                    onChange={this.onChange}
                    value={this.state.email}
                    placeholder="Email Address"
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4}>
                  <label>Phone</label>
                  <FormControl
                    as="input"
                    name="phone"
                    onChange={this.onChange}
                    value={this.state.phone}
                    placeholder="Phone"
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={4} lg={4}>
                  <label>WhatsApp Number</label>
                  <FormControl
                    as="input"
                    name="whatsapp_number"
                    onChange={this.onChange}
                    value={this.state.whatsapp_number}
                    placeholder="Whatsapp Number"
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={4} lg={4}>
                  <label>Address</label>
                  <FormControl
                    as="input"
                    name="address"
                    onChange={this.onChange}
                    value={this.state.address}
                    placeholder="Address"
                  />
                </Grid>
              </Grid>

              {this.state.edit ? (
                <div align="right">
                  <Button size="sm" variant="danger" type="submit">
                    Update
                  </Button>
                  &nbsp;
                  <Button
                    size="sm"
                    variant="danger"
                    type="button"
                    onClick={this.cancelEdit}
                  >
                    Cancel
                  </Button>
                </div>
              ) : (
                <div align="right">
                  <Button size="sm" variant="info" type="submit">
                    Submit
                  </Button>
                </div>
              )}
            </Card.Body>
          </Card>

          <br />

          <DepartmentAlumniBulk
            dept_code={this.props.dept_code}
            reloadFunction={this.__getDeptData}
          />
          <br />
          <Card>
            <Card.Body>
              {this.state.isDataLoaded ? (
                <div>
                  <Paper square>
                    <Tabs
                      value={this.state.tabValue}
                      indicatorColor="primary"
                      textColor="primary"
                      onChange={(e, n) =>
                        this.setState({
                          tabValue: n,
                        })
                      }
                      aria-label="disabled tabs example"
                    >
                      <Tab label="OFFLINE" />
                      <Tab label="ONLINE" />
                    </Tabs>

                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>#</TableCell>
                          <TableCell width="10%">Name</TableCell>
                          <TableCell width="10%">Batch</TableCell>
                          <TableCell wodth="20%">Profession</TableCell>
                          <TableCell wodth="20%">
                            Highest Qualification
                          </TableCell>
                          <TableCell wodth="20%">Address</TableCell>
                          <TableCell wodth="20%">Contact</TableCell>
                          <TableCell wodth="20%">Verification</TableCell>
                          <TableCell>Edit</TableCell>
                          <TableCell>Delete</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {this.state.data
                          .filter(
                            (el) =>
                              el.registration_type ==
                              (this.state.tabValue == 0 ? "OFFLINE" : "ONLINE")
                          )
                          .map((el, index) => (
                            <TableRow key={index}>
                              <TableCell>{index + 1}</TableCell>
                              <TableCell>{el.name}</TableCell>
                              <TableCell>{el.batch}</TableCell>
                              <TableCell>{el.current_profession}</TableCell>
                              <TableCell>{el.qualification}</TableCell>
                              <TableCell>{el.address}</TableCell>
                              <TableCell>
                                {el.email} <br /> {el.phone} <br />{" "}
                                {el.whatsapp_number} (WA)
                              </TableCell>
                              <TableCell>
                                
                                    <Switch
                                      checked={el.is_verified == 1}
                                      onChange={(e) => this.toggleVerification(el, e.target.checked == true ? 1 : 0)}
                                    />
                                 
                              </TableCell>

                              <TableCell>
                                <Edit
                                  onClick={this.onEditClick.bind(this, el)}
                                />
                              </TableCell>
                              <TableCell>
                                <Delete
                                  onClick={this.onDeleteClick.bind(this, el)}
                                />
                              </TableCell>
                            </TableRow>
                          ))}
                      </TableBody>
                    </Table>
                  </Paper>
                </div>
              ) : (
                <Typography>Loading . . .</Typography>
              )}
            </Card.Body>
          </Card>
        </form>
      </div>
    );
  }
}

export default DepartmentAlumni;
