import React, { Component } from "react";
import MUIDataTable from "mui-datatables";
import { IconButton } from "@material-ui/core";
import Launch from "@material-ui/icons/Launch";
import Add from "@material-ui/icons/Add";

export default class GroupTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      isLoaded: false,
    };
  }

  handleDelete(rowsDeleted) {
    console.log(rowsDeleted);
  }

  onActionClick(el) {
    this.props.actionClick(el);
  }

  onClick() {
    console.log("hey");
  }

  showData(value) {
    if (Array.isArray(value) && value.length > 0) {
      let new_arr = value.map((el, index) => el.label);

      return new_arr.join(",");
    } else {
      return "";
    }
  }

  render() {
    const columns = [
      {
        name: "#",
        options: {
          filter: true,
          sort: true,
        },
      },
      {
        name: "SSR",
        options: {
          filter: true,
          sort: true,
        },
      },

      {
        name: "Sub-Group",
        options: {
          filter: true,
          sort: true,
        },
      },
      {
        name: "Sr No",
        options: {
          filter: true,
          sort: true,
        },
      },
      {
        name: "Title",
        options: {
          filter: true,
          sort: true,
        },
      },
      {
        name: "File",
        options: {
          filter: true,
          sort: true,
        },
      },

      {
        name: "Action",
        options: {
          filter: false,
          sort: false,
          download: false,
        },
      },
    ];

    const options = {
      filterType: "checkbox",
      selectableRows: false,
      responsive: "scroll",
      onRowsDelete: (rowsDeleted) => {
        this.handleDelete(rowsDeleted);
      },
      customToolbar: () => {
        return (
          <IconButton onClick={this.props.onAddClick}>
            <Add />
          </IconButton>
        );
      },
    };

    let data = [];
    let table_data = this.props.table_data;
    if (table_data.length > 0) {
      data = table_data.map((el, index) => [
        el.id,
        `${el.name} - ${el.criterion}`,
        el.sub_sr_no,
        el.sr_no,
        el.title,
        el.upload_info.length == 1 ? "Uploaded" : "No",
        <Launch onClick={this.onActionClick.bind(this, el)} />,
      ]);
    } else {
      data = [];
    }

    return (
      <div>
        <MUIDataTable
          title={"Files SSR Wise "}
          data={data}
          columns={columns}
          options={options}
        />
      </div>
    );
  }
}
