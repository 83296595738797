import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import {
  Button,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import React from "react";
import { FormControl } from "react-bootstrap";
import FileUpload from "../AddNew/FileUpload";
import { DeleteData, GetData, PostData, PutData } from "../../api/service";
import Snack from "../Snackbar/Snack";
import Edit from "@material-ui/icons/Edit";
import Delete from "@material-ui/icons/Delete";

export default function FacultyCommitteeTask({ committee_id }) {
  const [state, setState] = React.useState({
    title: "",
    uploads: [],
    index1: "",
  });

  const [description, setDescription] = React.useState("")

  const [data, setData] = React.useState([]);
  const [isLoaded, setIsLoaded] = React.useState(false);

  function __loadData() {
    GetData(`/${committee_id}/getcommitteetask`).then((resp) => {
      setData(resp);
      setIsLoaded(true);
    });
  }

  React.useEffect(() => {
    __loadData();
  }, [committee_id]);

  const [error, setError] = React.useState({
    title: "",
    description: "",
    uploads: "",
    index1: "",
  });

  const [loadFile, setLoadFile] = React.useState(true);
  const [edit, setEdit] = React.useState(false);
  const [editData, setEditData] = React.useState(null);

  function onChange(name, value) {
    setState({
      ...state,
      [name]: value,
    });
  }

  function handleValidation() {
    let isValid = true;
    let err = [];

    if (state.title == "" || state.title == null) {
      isValid = false;
      err["title"] = "Mandatory Field";
    } else {
      err["title"] = "";
    }

    if (state.index1 == "" || state.index1 == null) {
      isValid = false;
      err["index1"] = "Mandatory Field";
    } else {
      err["index1"] = "";
    }

    setError(err);
    return isValid;
  }

  const [open, setOpen] = React.useState(false);
  const [message, setMessage] = React.useState("");

  function onSubmit() {
    if (handleValidation()) {
      if (edit) {
        // update
        PutData(`/${editData.id}/editcommitteetask`, {
          ...state,
          description,
          committee_id,
        }).then((resp) => {
          setLoadFile(false);
          setOpen(true);
          setMessage(resp);
          setTimeout(() => setLoadFile(true), 1000);
          __loadData();
        });
      } else {
        // create
        PostData(`/admin/addcommitteetask`, {
          ...state,
          description,
          committee_id,
        }).then((resp) => {
          setLoadFile(false);
          setOpen(true);
          setMessage(resp);
          setTimeout(() => setLoadFile(true), 1000);
          __loadData();
        });
      }
    }
  }

  function deleteTask(id) {
    DeleteData(`/${id}/deletecommitteetask`).then((resp) => {
      setOpen(true);
      setMessage(resp);
      __loadData();
    });
  }


  function onClear(){
    setLoadFile(false)
    setEdit(false);
    setEditData(null);
    setState({
      title: "",
      uploads: [],
      index1: "",
    });
    setDescription("")
    setTimeout(() => setLoadFile(true), 1000)
  }
  return (
    <div style={{ backgroundColor: "lightgrey", padding: 10 }}>
      {open ? (
        <Snack
          open={open}
          message={message}
          handleClose={() => {
            setOpen(false);
            setMessage("");
          }}
        />
      ) : null}
      <label style={{ fontWeight: 700, fontSize: 18 }}>
        Tasks & Activities
      </label>
      <br />{" "}
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <label style={{ fontWeight: 500 }}>Task Title</label>
          <FormControl
            as="input"
            name="title"
            
            onChange={(e) => onChange("title", e.target.value)}
            value={state.title}
            placeholder="Task Title"
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <label style={{ fontWeight: 500 }}>Task Description</label>

          <CKEditor
            editor={ClassicEditor}
            data={description}
            onReady={(editor) => {
              // You can store the "editor" and use when it is needed.
              console.log("Editor is ready to use!", editor);
            }}
            onChange={(event, editor) => {
              const data = editor.getData();
              setDescription(data);
              console.log({ event, editor, data });
            }}
            onBlur={(event, editor) => {
              console.log("Blur.", editor);
            }}
            onFocus={(event, editor) => {
              console.log("Focus.", editor);
            }}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <label style={{ fontWeight: 500 }}>Uploads (if any)</label>
          {loadFile ? (
            <FileUpload
              dp={state.uploads}
              type={"COLLEGE_COMMITTEE_TASKS"}
              setDp={(e) => onChange("uploads", e)}
            />
          ) : null}
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <label style={{ fontWeight: 500 }}>
            Order (Higher values will be in top)
          </label>
          <FormControl
            as="input"
            type="number"
            name="index1"
            required
            onChange={(e) => onChange("index1", e.target.value)}
            value={state.index1}
            placeholder="Order (Higher values will be in top)"
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          style={{ textAlign: "right" }}
        >
          {!edit && (
            <Button
              variant="contained"
              type="primary"
              size="small"
              onClick={() => onSubmit()}
            >
              Add Task
            </Button>
          )}
          {edit && (
            <>
              <Button
                variant="contained"
                type="primary"
                size="small"
                onClick={() => onSubmit()}
              >
                Update Task
              </Button>{" "}
              &nbsp;
              <Button
                variant="contained"
                type="primary"
                size="small"
                onClick={() => {
                  onClear()
                }}
              >
                Cancel Edit
              </Button>
            </>
          )}
        </Grid>

        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Paper>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell>#</TableCell>
                  <TableCell>Title</TableCell>
                  <TableCell>Description</TableCell>
                  <TableCell>Uploads</TableCell>
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {Array.isArray(data) &&
                  data.map((el, index) => (
                    <TableRow key={index}>
                      <TableCell>{el.index1}</TableCell>
                      <TableCell>{el.title}</TableCell>
                      <TableCell>
                        <span
                          dangerouslySetInnerHTML={{ __html: el.description }}
                        ></span>
                      </TableCell>
                      <TableCell>
                        <ul>
                          {Array.isArray(el.uploads) &&
                            el.uploads.map((el1, index1) => (
                              <li key={index1}>
                                <a target="_blank" href={el1.url}>
                                  Attachment {index1 + 1}
                                </a>
                              </li>
                            ))}
                        </ul>
                      </TableCell>
                      <TableCell>
                        <Edit
                          onClick={() => {
                            setLoadFile(false);
                            setEditData(el);
                            setState({
                              title: el.title,
                              uploads: el.uploads,
                              index1: el.index1,
                            }); 
                            setDescription(el.description)
                            setEdit(true);
                            setTimeout(() => setLoadFile(true), 1000);
                          }}
                        />
                        <Delete />
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
}
