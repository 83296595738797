import React, { Component } from "react";
import {
  Typography,
  Grid,
  Table,
  TableHead,
  TableCell,
  TableRow,
  TableBody,
  Chip,
} from "@material-ui/core";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { Button, FormControl, Card } from "react-bootstrap";
import { PostData, GetData, DeleteData, PutData } from "../../api/service";
import Snack from "../Snackbar/Snack";
import FileUpload from "../AddNew/FileUpload";
import Edit from "@material-ui/icons/Edit";
import Delete from "@material-ui/icons/Delete";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import history from "../../history";
import { Q_LIST, SERVER_UPLOAD } from "../../Helpers/GlobalVariables";
import FileUploadFaculty from "./FileUploadFaculty";

class FacultyCoursesCoveredResearch extends Component {
  state = {
    m_type: this.props.m_type,
    employee_id: this.props.employee_id,
    email:this.props.email,
    message: "",
    open: false,
    isLoaded: false,
    year:'',
    quarter:'',
    paper:'',
    topic:'',
    remark:'',
    courses_covered:[]
  };

  handleChange = (name, value) => {
    this.setState({
      [name]: value,
    });
  };

  onChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  componentDidMount() {
    //console.log(this.props);

    GetData(`/admin/${this.props.employee_id}/getsingleempolyeedataapi`)
    .then((resp)=>{
        if(resp){
            this.setState({
                ...resp,
          
              
              });
        }
        this.setState({
          isLoaded: true
        })
    })

    
  }

//   componentDidUpdate(prevProps){
//     if(this.props !== prevProps){
//         this.setState({
//             seminar:this.props.seminar
      
//         })
//     }
// }


  onSubmit = (e) => {
    e.preventDefault();

    let d = this.state;

    PostData(`/admin/${this.props.employee_id}/addemployeeapi`, d).then(
      (resp) => {
        //console.log(resp)
        this.setState({
          message: resp,
          open: true,
        });
      }
    );
  };


  onAddClick = () => {
    let d = {
        year:this.state.year,
    quarter: this.state.quarter,
    paper:this.state.paper,
    topic:this.state.topic,
    remark:this.state.remark,

    }

    let courses_covered = this.state.courses_covered != null ? this.state.courses_covered : [];
    courses_covered.push(d);
    this.setState({
        courses_covered
    }, () => {
            this.setState({
                year:'',
                quarter:'',
                paper:'',
                topic:'',
                remark:'',
            })
    })
}

onDelete = (index) => {
    let courses_covered = this.state.courses_covered;
    courses_covered.splice(index,1);

    this.setState({
        courses_covered
    })
}


onChange1 = (name, index, value) => {
  let d = this.state.courses_covered;
  d[index][name] = value
  this.setState({
    courses_covered: d
  })
}


onSetFile = (index, data) => {
    //console.log(data)
    let aw = this.state.courses_covered;
    aw[index]['file'] = data
    this.setState({
        courses_covered: aw
    })
  }

  render() {
    return (
      <div>
        {this.state.open ? (
          <Snack
            open={this.state.open}
            message={this.state.message}
            handleClose={() => {
              this.setState({
                open: false,
                message: "",
              });
            }}
          />
        ) : null}

{this.state.isLoaded && <form onSubmit={this.onSubmit}>
          <Typography variant="h6" gutterBottom>
            {this.props.title}
          </Typography>
          
           <table width="100%" className="table table-sm">
          <thead>
            <tr>
            <td>Year</td>
            <td>Quarter</td>
            <td>Paper/Course</td>
            <td>Topic Discussed</td>
            <td>Remark (if Any)</td>
            <td></td>
            
            {/* <td>Year</td> */}
    
              
              
              <td>Click + to Add</td>
            </tr>
          </thead>
          <tbody>
            <tr>
            <td>
                <input
                  className="form-control"
                  type="text"
                  name="year"
                  value={this.state.year}
                  onChange={this.onChange}
                  placeholder="Year"
                />
              </td>
              <td>
              <select
                      className="form-control"
                      type="text"
                      name="quarter"
                      value={this.state.quarter}
                      onChange={this.onChange}
                      placeholder="Quarter"
                    >
                      <option>select</option>
                      {Array.isArray(Q_LIST) && Q_LIST.map((el,index) => <option key={index} value={el}>{el}</option>)}
                    </select>
              </td>
              <td>
                <input
                  className="form-control"
                  type="text"
                  name="paper"
                  value={this.state.paper}
                  onChange={this.onChange}
                  placeholder="Paper/Course"
                />
              </td>

              <td>
                <input
                  className="form-control"
                  type="text"
                  name="topic"
                  value={this.state.topic}
                  onChange={this.onChange}
                  placeholder="Topic Discussed"
                />
              </td>

              <td>
                <input
                  className="form-control"
                  type="text"
                  name="remark"
                  value={this.state.remark}
                  onChange={this.onChange}
                  placeholder="Remark
                  (If Any)"
                />
              </td>
              <td>
                You can upload the file after addition
              </td>
              
              
              
            
              
              

              

             
              
              <td>
                <Button
                  variant="outlined"
                  onClick={this.onAddClick}
                  type="button"
                >
                  +
                </Button>
              </td>
            </tr>
          </tbody>
          <tbody>
            {Array.isArray(this.state.courses_covered) && this.state.courses_covered.map((el, index) => (
              <tr key={index}>
                <td>
                <textarea
                      className="form-control"
                      type="text"
                      name="year"
                      value={el.year}
                      onChange={(e) => this.onChange1('year', index, e.target.value)}
                      placeholder="year"
                    /></td>
                    <td><select
                      className="form-control"
                      type="text"
                      name="quarter"
                      value={el.quarter}
                      onChange={(e) => this.onChange1('quarter', index, e.target.value)}
                      placeholder="quarter"
                    ><option>select</option>
                    {Array.isArray(Q_LIST) && Q_LIST.map((el,index) => <option key={index} value={el}>{el}</option>)}
                      </select></td>
                <td> <textarea
                      className="form-control"
                      type="text"
                      name="paper"
                      value={el.paper}
                      onChange={(e) => this.onChange1('paper', index, e.target.value)}
                      placeholder="paper"
                    /></td>
                    <td> <textarea
                      className="form-control"
                      type="text"
                      name="topic"
                      value={el.topic}
                      onChange={(e) => this.onChange1('topic', index, e.target.value)}
                      placeholder="topic"
                    /></td>
                <td><input
                      className="form-control"
                      type="text"
                      name="remark"
                      value={el.remark}
                      onChange={(e) => this.onChange1('remark', index, e.target.value)}
                      placeholder="Remarks"
                    /></td>
                <td style={{whiteSpace: 'nowrap'}}>
                  {Array.isArray(el.file) && el.file.map((el1,index1) => <div key={index1}><a target="_blank" href={`${SERVER_UPLOAD}/${el1.type}/${el1.path}`} style={{fontSize: 10}}>[uploaded] {el1.path}</a></div>)}
                  <FileUploadFaculty files={el.file} onSetFile={(data) => this.onSetFile(index,data)} type={`FACULTY_COURSES_COVERED`} dp={el.file} apikey={`1234`} />
                  </td>
               
                
                
                
                
               
                
                
                <td>
                  <Delete onClick={this.onDelete.bind(this, index)} />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
                <br />
        <div align="right">
                <Button size="sm" variant="info" type="submit">
                  Submit
                </Button>
              </div>
        
        </form>}
      </div>
    );
  }
}

export default FacultyCoursesCoveredResearch;
