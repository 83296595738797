import { Button, Table, TableBody, TableCell, TableHead, TableRow, Typography } from "@material-ui/core";
import React from "react";
import { Card } from "react-bootstrap";
import { PostData } from "../../api/service";
import readXlsxFile from "read-excel-file";

export default function DepartmentAlumniBulk({ dept_code, reloadFunction }) {
  const textInput = React.useRef(null);
  const [data, setData] = React.useState([]);
  const [isLoaded, setIsLoaded] = React.useState(false);

  const [isSubmitting, setIsSubmitting] = React.useState(false);

  React.useEffect(() => {
    const input: any = textInput.current;
    input.addEventListener("change", () => {
      readXlsxFile(input.files[0]).then((rows: any) => {
        let temp_rows = rows;
        temp_rows.splice(0, 1);
        let dd: any = [];
        temp_rows.map((el: any) => {
          if (el[0] != "") {
            dd.push({
              name: el[0],
              batch: el[1],
              current_profession: el[2],
              qualification: el[3],
              email: el[4],
              phone: el[5],

              status: "not inserted",
            });
          }
        });
        //console.log(dd);
        setData(dd);
        setIsLoaded(true);
      });
    });
  }, []);

  const [respData, setRespData] = React.useState([]);
  const [respDataLoaded, setRespDataLoaded] = React.useState(false);

  function finalSubmit() {
    if (Array.isArray(data) && data.length > 0) {
      setIsSubmitting(true);
      PostData(`/admin/department-alumni-list-bulk`, { dept_code: dept_code, data: data })
        .then((resp: any) => {
          setIsSubmitting(false);
          if (resp.length > 0) {
            reloadFunction();
            //message.success(resp.status_text)
            alert('Alumni Uploaded Successfully');
            setData([]);
            setIsLoaded(false);
            setRespData(resp);
            setRespDataLoaded(true);

          } else {
            //message.error(resp.status_text)
            alert('Error in the file');
          }
        })
        .catch((err) => {
          setIsSubmitting(false);
          //message.error(`Error occured in the server while importing data`)
          alert('Server returned error');
        });
    } else {
      //message.error(`File must contain a row of data`)
      alert('You must add atleast one row');
    }
  }

  return (
    <div>
      {" "}
      <Card>
        <Card.Body>
          If you want to insert the list of alumni from excel file, download the
          excel file{" "}
          <a
            style={{ color: "blue", fontWeight: 700 }}
            target="_blank"
            href="/excel/prag_alumni_dept_list_import.xlsx"
          >
            from here
          </a>{" "}
          and fill up the fields accordingly. Then upload the updated file to
          the server to insert all the alumni at once.
          <br />
          <Typography variant="h5" gutterBottom style={{ fontWeight: 700 }}>
            Upload the excel file here
          </Typography>
          <input type="file" id="input" ref={textInput} />
        </Card.Body>
      </Card>
      {isLoaded && (
        <Card>
          <Card.Body>
            <Typography variant="h5" gutterBottom style={{ fontWeight: 700 }}>
              Here are the contents of the uploaded file
            </Typography>
            <Typography variant="body2">
              If you find the data to be accurate, kindly press "Final Submit"
              button to insert the data to the database. This is an irreversible
              process, however, you will be able to update the data from the
              masters panel as and when required.
            </Typography>

            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell>#</TableCell>
                  <TableCell>Name</TableCell>
                  <TableCell>Batch</TableCell>
                  <TableCell>Current Profession</TableCell>
                  <TableCell>Highest Qualification</TableCell>
                  <TableCell>Email</TableCell>
                  <TableCell>Phone</TableCell>
                 
                </TableRow>
              </TableHead>
              <TableBody>
                {Array.isArray(data) &&
                  data.map((el: any, index: number) => (
                    <TableRow key={index}>
                      <TableCell>{index + 1}</TableCell>
                      <TableCell>{el.name}</TableCell>
                      <TableCell>{el.batch}</TableCell>
                      <TableCell>{el.current_profession}</TableCell>
                      <TableCell>{el.qualification}</TableCell>
                      <TableCell>{el.email}</TableCell>
                      <TableCell>{el.phone}</TableCell>
                     
                    </TableRow>
                  ))}
              </TableBody>
            </Table>

            <br />
            <div style={{ textAlign: "right" }}>
              <Button
                onClick={() => finalSubmit()}
                disabled={isSubmitting}
                
              >
                Final Submit
              </Button>
            </div>
          </Card.Body>
        </Card>
      )}
    </div>
  );
}
