import React, { Component } from "react";
import {
  Typography,
  Paper,
  Tabs as Tabs1,
  Tab as Tab1,
} from "@material-ui/core";
import { Tabs, Tab } from "react-bootstrap";
import { GetData } from "../../api/service";
import AcademicAbout from "./AcademicAbout";

const smallTab = [
  {
    title: "Admission Procedure",
    active: 0,
  },
  {
    title: "Examination",
    active: 1,
  },
  {
    title: "Rules & Regulations",
    active: 2,
  },
];

class AcademicHomeLanding extends Component {
  state = {
    active: 0,
    dept_code: "PRAG",

    data: {},
    isDeptLoaded: false,
  };

  __getDeptData = (dept_code) => {
    GetData(`/admin/${dept_code}/getsingledeptdata`).then((resp) => {
      //console.log(resp)
      if (resp != false) {
        this.setState({
          data: resp,
          active: 0,
          isDeptLoaded: true,
        });
      } else {
        this.setState({
          data: {},
          active: 0,
          isDeptLoaded: true,
        });
      }
    });
  };

  componentDidMount() {
    this.__getDeptData(this.state.dept_code);
  }

  render() {
    return (
      <div>
        <Typography variant="h6">Academics</Typography>
        <Typography variant="subtitle2">
          Code: {this.state.dept_code}
        </Typography>

        <br />

        {this.state.isDeptLoaded && (
          <Paper square>
            <Tabs1
              value={this.state.active}
              indicatorColor="primary"
              textColor="primary"
              onChange={(e, newValue) =>
                this.setState({
                  active: newValue,
                })
              }
              aria-label=""
            >
              {smallTab.map((el, index) => (
                <Tab1 value={el.active} key={el.active} label={el.title} />
              ))}
            </Tabs1>
          </Paper>
        )}
        <br />
        {this.state.active == 0 ? (
          <AcademicAbout type={`ADMISSION_PROCEDURE`} title="Add Admission Procedure" />
        ) : null}

        {this.state.active == 1 ? (
          <AcademicAbout type={`EXAMINATION`} title="Add Examination Details" />
        ) : null}

        {this.state.active == 2 ? (
          <AcademicAbout type={`RULES_AND_REGULATIONS`} title="Add Rules & Regulations" />
        ) : null}
      </div>
    );
  }
}

export default AcademicHomeLanding;
