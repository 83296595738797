import React, { Component } from "react";
import {
  Typography,
  Grid,
  Table,
  TableHead,
  TableCell,
  TableRow,
  TableBody,
  Chip,
} from "@material-ui/core";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { Button, FormControl, Card } from "react-bootstrap";
import { PostData, GetData, DeleteData, PutData } from "../../api/service";
import Snack from "../Snackbar/Snack";
import FileUpload from "../AddNew/FileUpload";
import Edit from "@material-ui/icons/Edit";
import Delete from "@material-ui/icons/Delete";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import history from "../../history";
import { Q_LIST } from "../../Helpers/GlobalVariables";

class FacultyResearch extends Component {
  state = {
    m_type: this.props.m_type,
    employee_id: this.props.employee_id,
    email:this.props.email,
    message: "",
    open: false,
    isLoaded: false,
    year:'',
    sponsored_by:'',
    title:'',
    duration:'',
    fund:'',
    status:'',
    year: '',
    quarter: '',
    submission_date:'',
    commencement_date: '',
    co_researcher:'',
    research:[]
  };

  handleChange = (name, value) => {
    this.setState({
      [name]: value,
    });
  };

  onChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  componentDidMount() {
    //console.log(this.props);

    GetData(`/admin/${this.props.employee_id}/getsingleempolyeedataapi`)
    .then((resp)=>{
        if(resp){
            this.setState({
                ...resp,
          
              
              });
        }
        this.setState({
          isLoaded: true
        })
    })

    
  }

//   componentDidUpdate(prevProps){
//     if(this.props !== prevProps){
//         this.setState({
//             seminar:this.props.seminar
      
//         })
//     }
// }


  onSubmit = (e) => {
    e.preventDefault();

    let d = this.state;

    PostData(`/admin/${this.props.employee_id}/addemployeeapi`, d).then(
      (resp) => {
        //console.log(resp)
        this.setState({
          message: resp,
          open: true,
        });
      }
    );
  };


  onAddClick = () => {
    let d = {
        year: this.state.year,
        sponsored_by: this.state.sponsored_by,
        title: this.state.title,
        fund: this.state.fund,
        submission_date: this.state.submission_date,
        co_researcher: this.state.co_researcher,
        duration: this.state.duration,
        status: this.state.status,
        commencement_date: this.state.commencement_date,
        year: this.state.year,
        quarter: this.state.quarter,

    }

    let research = this.state.research != null ? this.state.research : [];
    research.push(d);
    this.setState({
        research
    }, () => {
            this.setState({
                year:'',
                sponsored_by:'',
                title:'',
                fund:'',
                year:'',
                quarter:'',
                submission_date:'',
                co_researcher:'',
            })
    })
}

onDelete = (index) => {
    let research = this.state.research;
    research.splice(index,1);

    this.setState({
        research
    })
}


onChange1 = (name, index, value) => {
  let d = this.state.research;
  d[index][name] = value
  this.setState({
    research: d
  })
}


  render() {
    return (
      <div>
        {this.state.open ? (
          <Snack
            open={this.state.open}
            message={this.state.message}
            handleClose={() => {
              this.setState({
                open: false,
                message: "",
              });
            }}
          />
        ) : null}

{this.state.isLoaded && <form onSubmit={this.onSubmit}>
          <Typography variant="h6" gutterBottom>
            {this.props.title}
          </Typography>
          
           <table width="100%" className="table table-sm">
          <thead>
            <tr>
            <td>Title of the project</td>
            <td>Funding Agency</td>
            <td>Duration of the Project</td>
            <td>Project Cost (in Lakhs)</td>
            <td>Co-investigator (if any)</td>
            <td>Status</td>
            <td>Date of Commencement</td>
            <td>Date of Completion</td>
            <td>Year</td>
            <td>Quarter</td>
            {/* <td>Year</td> */}
    
              
              
              <td>Click + to Add</td>
            </tr>
          </thead>
          <tbody>
            <tr>
            <td>
                <input
                  className="form-control"
                  type="text"
                  name="title"
                  value={this.state.title}
                  onChange={this.onChange}
                  placeholder="Title of the paper"
                />
              </td>
              <td>
                <input
                  className="form-control"
                  type="text"
                  name="sponsored_by"
                  value={this.state.sponsored_by}
                  onChange={this.onChange}
                  placeholder="Sponsored by"
                />
              </td>
              <td>
                <input
                  className="form-control"
                  type="text"
                  name="duration"
                  value={this.state.duration}
                  onChange={this.onChange}
                  placeholder="Duration"
                />
              </td>

              <td>
                <input
                  className="form-control"
                  type="text"
                  name="fund"
                  value={this.state.fund}
                  onChange={this.onChange}
                  placeholder="Fund Granted"
                />
              </td>

              <td>
                <input
                  className="form-control"
                  type="text"
                  name="co_researcher"
                  value={this.state.co_researcher}
                  onChange={this.onChange}
                  placeholder="Name of Co-researcher
                  (If Any)"
                />
              </td>
              <td>
                <input
                  className="form-control"
                  type="text"
                  name="status"
                  value={this.state.status}
                  onChange={this.onChange}
                  placeholder="Status"
                />
              </td>
              
              
              
              <td>
                <input
                  className="form-control"
                  type="date"
                  name="commencement_date"
                  value={this.state.commencement_date}
                  onChange={this.onChange}
                  placeholder="Date of Commencement"
                />
              </td>
              
              

              <td>
                <input
                  className="form-control"
                  type="date"
                  name="submission_date"
                  value={this.state.submission_date}
                  onChange={this.onChange}
                  placeholder="Date of Completion"
                />
              </td>

              <td>
                <input
                  className="form-control"
                  type="text"
                  name="year"
                  value={this.state.year}
                  onChange={this.onChange}
                  placeholder="Year"
                />
              </td>

              <td>
                    <select
                      className="form-control"
                      type="text"
                      name="quarter"
                      value={this.state.quarter}
                      onChange={this.onChange}
                      placeholder="Quarter"
                    >
                      <option>select</option>
                      {Array.isArray(Q_LIST) && Q_LIST.map((el,index) => <option key={index} value={el}>{el}</option>)}
                    </select>
                  </td>
              
              <td>
                <Button
                  variant="outlined"
                  onClick={this.onAddClick}
                  type="button"
                >
                  +
                </Button>
              </td>
            </tr>
          </tbody>
          <tbody>
            {Array.isArray(this.state.research) && this.state.research.map((el, index) => (
              <tr key={index}>
                <td>
                <textarea
                      className="form-control"
                      type="text"
                      name="title"
                      value={el.title}
                      onChange={(e) => this.onChange1('title', index, e.target.value)}
                      placeholder="title"
                    /></td>
                <td> <textarea
                      className="form-control"
                      type="text"
                      name="sponsored_by"
                      value={el.sponsored_by}
                      onChange={(e) => this.onChange1('sponsored_by', index, e.target.value)}
                      placeholder="sponsored_by"
                    /></td>
                <td><input
                      className="form-control"
                      type="text"
                      name="duration"
                      value={el.duration}
                      onChange={(e) => this.onChange1('duration', index, e.target.value)}
                      placeholder="duration"
                    /></td>
                <td><input
                      className="form-control"
                      type="text"
                      name="fund"
                      value={el.fund}
                      onChange={(e) => this.onChange1('fund', index, e.target.value)}
                      placeholder="fund"
                    /></td>
                <td><input
                      className="form-control"
                      type="text"
                      name="co_researcher"
                      value={el.co_researcher}
                      onChange={(e) => this.onChange1('co_researcher', index, e.target.value)}
                      placeholder="co_researcher"
                    /></td>
                <td><input
                      className="form-control"
                      type="text"
                      name="status"
                      value={el.status}
                      onChange={(e) => this.onChange1('status', index, e.target.value)}
                      placeholder="status"
                    /></td>
                <td><input
                      className="form-control"
                      type="text"
                      name="commencement_date"
                      value={el.commencement_date}
                      onChange={(e) => this.onChange1('commencement_date', index, e.target.value)}
                      placeholder="commencement_date"
                    /></td>
                <td><input
                      className="form-control"
                      type="text"
                      name="submission_date"
                      value={el.submission_date}
                      onChange={(e) => this.onChange1('submission_date', index, e.target.value)}
                      placeholder="submission_date"
                    /></td>
                <td><input
                      className="form-control"
                      type="text"
                      name="year"
                      value={el.year}
                      onChange={(e) => this.onChange1('year', index, e.target.value)}
                      placeholder="year"
                    /></td>
                <td><select
                      className="form-control"
                      type="text"
                      name="quarter"
                      value={el.quarter}
                      onChange={(e) => this.onChange1('quarter', index, e.target.value)}
                      placeholder="quarter"
                    ><option>select</option>
                    {Array.isArray(Q_LIST) && Q_LIST.map((el,index) => <option key={index} value={el}>{el}</option>)}
                      </select></td>
                
                
                
               
                
                
                <td>
                  <Delete onClick={this.onDelete.bind(this, index)} />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
                <br />
        <div align="right">
                <Button size="sm" variant="info" type="submit">
                  Submit
                </Button>
              </div>
        
        </form>}
      </div>
    );
  }
}

export default FacultyResearch;
