import { Grid, Button } from '@material-ui/core'
import React from 'react'
import { PostData } from '../../../api/service'
import Snack from '../../Snackbar/Snack'

export default function ProfileSingleInputForm({ dept_code, inputs }) {
  let names = {}

  for (let value of Object.values(inputs)) {
    names[value.name] = value.value
  }

  let err = {}

  for (let value of Object.values(inputs)) {
    err[value.name] = ''
  }

  const [open, setOpen] = React.useState(false)
  const [message,setMessage] = React.useState('')

  const [state, setState] = React.useState({
    ...names,
  })

  const [error, setError] = React.useState({
    ...err,
  })

  function onChange(name, value) {
    setState({
      ...state,
      [name]: value,
    })
  }

  function handleValidation() {
    let isValid = true
    let err = []

    if (Array.isArray(inputs)) {
      inputs.map((el, index) => {
       
          if (state[el.name] == null || state[el.name] == '') {
            isValid = false
            err[el.name] = '* Mandatory field'
          } else {
            err[el.name] = ''
          }
        
      })
    }

    setError(err)
    //console.log("🚀 ~ file: FormBuilder.tsx ~ line 71 ~ handleValidation ~ err", err)
    return isValid
  }

  function onClear() {
    setState({
      ...names,
    })
  }

  function onSubmitHere() {
    if (handleValidation()) {
      // onSubmit

      PostData(`/admin/${dept_code}/updatedeptdatafromform`, {
          ...state
      }).then((resp) => {
          setMessage(resp)
          setOpen(true)
      }).catch((err) => {
          alert('Error')
      })

      onClear()
    }
  }

  return (
    <div style={{ backgroundColor: '#f9f9f9', padding: 10, marginBottom: 10 }}>
         {open ? (
                    <Snack
                        open={open}
                        message={message}
                        handleClose={() => {
                            setOpen(false)
                            setMessage('')
                        }}
                    />
                ) : null}
      <Grid container spacing={2}>
        {Array.isArray(inputs) &&
          inputs.map((el, index) => (
            <Grid item xs={12} sm={12} md={3} lg={3} key={index}>
              <div>{el.label}</div>
              <div style={{ color: 'red', fontSize: 10 }}>{error[el.name]}</div>
              <input
                className="form-control"
                type="text"
                name={el.name}
                value={state[el.name]}
                onChange={(e) => onChange(el.name, e.target.value)}
                placeholder={el.label}
              />
            </Grid>
          ))}
        <Grid item xs={12} sm={12} md={3} lg={3}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => onSubmitHere()}
            style={{ marginTop: 25 }}
          >
            Submit
          </Button>
        </Grid>
      </Grid>
    </div>
  )
}
