import React, { Component } from "react";
import {
  Typography,
  Grid,
  Table,
  TableHead,
  TableCell,
  TableRow,
  TableBody,
  Chip,
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
} from "@material-ui/core";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { Button, FormControl, Card } from "react-bootstrap";
import { PostData, GetData, DeleteData, PutData } from "../../api/service";
import Snack from "../Snackbar/Snack";
import FileUpload from "../AddNew/FileUpload";
import Edit from "@material-ui/icons/Edit";
import Delete from "@material-ui/icons/Delete";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import history from "../../history";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { Q_LIST, SERVER_UPLOAD } from "../../Helpers/GlobalVariables";
import FileUploadFaculty from "./FileUploadFaculty";

class FacultyInvolvement extends Component {
  state = {
    m_type: this.props.m_type,
    employee_id: this.props.employee_id,
    email: this.props.email,
    message: "",
    open: false,
    isLoaded: false,
    name: "",
    position: "",
    from: "",
    till: "",
    year: "",
    quarter: "",
   
    other_info:'',
    college_activities: [],
    college_activities_panel:false,
    social_activities: [],
    social_activities_panel:false,
    
  };

  clearData = ()=>{
      this.setState({
        name: "",
        position: "",
        from: "",
        till: "",
        year: "",
    quarter: "",
        other_info:'',
      })
  }

  onPanelClick = (type,flag)=>{
        this.clearData();
        this.setState({
            college_activities_panel:type=='college_activities_panel'?flag:false,
            social_activities_panel:type=='social_activities_panel'?flag:false,

        })
    
  }

  onSetFile = (index, data) => {
    //console.log(data)
    let aw = this.state.social_activities;
    aw[index]['file'] = data
    this.setState({
      social_activities: aw
    })
  }


  onSetFile1 = (index, data) => {
    //console.log(data)
    let aw = this.state.college_activities;
    aw[index]['file'] = data
    this.setState({
      college_activities: aw
    })
  }

  handleChange = (name, value) => {
    this.setState({
      [name]: value,
    });
  };

  onChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  componentDidMount() {
    //console.log(this.props);

    GetData(`/admin/${this.props.employee_id}/getsingleempolyeedataapi`).then(
      (resp) => {
        if (resp) {
          this.setState({
            ...resp,

           
          });
        }
        this.setState({
          isLoaded: true
        })
      }
    );
  }

  //   componentDidUpdate(prevProps){
  //     if(this.props !== prevProps){
  //         this.setState({
  //             seminar:this.props.seminar

  //         })
  //     }
  // }

  onSubmit = (e) => {
    e.preventDefault();

    let d = this.state;

    PostData(`/admin/${this.props.employee_id}/addemployeeapi`, d).then(
      (resp) => {
        //console.log(resp)
        this.setState({
          message: resp,
          open: true,
        });
      }
    );
  };

  onAddClick = (type) => {
    if (type == "college_activities") {
      let d = {
        name: this.state.name,
        position: this.state.position,
        from: this.state.from,
        till: this.state.till,
        other_info: this.state.other_info,
        year: this.state.year,
        quarter: this.state.quarter,
      };

      let college_activities = this.state.college_activities != null ? this.state.college_activities : [];
      college_activities.push(d);
      this.setState(
        {
            college_activities,
        },
        () => {
          this.setState({
            name: "",
            position: "",
            from: "",
            till: "",
            
            year: "",
            quarter: "",
            other_info:'',
          });
        }
      );
    }

    else  if (type == "social_activities") {
        let d = {
            name: this.state.name,
            position: this.state.position,
            from: this.state.from,
            till: this.state.till,
            other_info: this.state.other_info,
            other_info: this.state.other_info,
            year: this.state.year,
            quarter: this.state.quarter,
          };
  
        let social_activities = this.state.social_activities != null ? this.state.social_activities : [];
        social_activities.push(d);
        this.setState(
          {
            social_activities,
          },
          () => {
            this.setState({
              name: "",
              position: "",
              from: "",
              till: "",
              year: "",
              quarter: "",
             
              other_info:'',
            });
          }
        );
      }

  };

  onDelete = (index, type) => {
    if (type == "college_activities") {
      let college_activities = this.state.college_activities;
      college_activities.splice(index, 1);

      this.setState({
        college_activities,
      });
    }

    else if (type == "social_activities") {
        let social_activities = this.state.social_activities;
        social_activities.splice(index, 1);
  
        this.setState({
            social_activities,
        });
      }

      
  };



  onChange1 = (name, index, value) => {
    let d = this.state.college_activities;
    d[index][name] = value
    this.setState({
      college_activities: d
    })
  }


  onChange2 = (name, index, value) => {
    let d = this.state.social_activities;
    d[index][name] = value
    this.setState({
      social_activities: d
    })
  }

  render() {
    return (
      <div>
        {this.state.open ? (
          <Snack
            open={this.state.open}
            message={this.state.message}
            handleClose={() => {
              this.setState({
                open: false,
                message: "",
              });
            }}
          />
        ) : null}

        {this.state.isLoaded && (
          <form onSubmit={this.onSubmit}>
            <Typography variant="h6" gutterBottom>
              {this.props.title}
            </Typography>

            <ExpansionPanel expanded={this.state.college_activities_panel} onChange={() => this.onPanelClick('college_activities_panel',!this.state.college_activities_panel)}>
                                        <ExpansionPanelSummary
                                            expandIcon={<ExpandMoreIcon />}
                                        >
                                            <Typography>Click here to Add In Campus Activities</Typography>
                                        </ExpansionPanelSummary>
                                        <ExpansionPanelDetails>

            <table width="100%" className="table table-sm">
              <thead>
                <tr>
                  <td colSpan={6} align="center">
                    <Typography>Involvement in Campus Activities</Typography>
                  </td>
                </tr>
                <tr>
                  <td rowSpan={2}>Name of the Committee / Scheme</td>

                  <td rowSpan={2}>Position/Responsibility/
Capacity Hold</td>

                  <td colSpan={2}>Period</td>
                  <td rowSpan={2}>Other Information</td>
                  <td rowSpan={2}>Year</td>
                  <td rowSpan={2}>Quarter</td>
                  <td rowSpan={2}>File</td>
                
                  <td rowSpan={2} >Click + to Add</td>
                </tr>
                <tr>
                    <td>From</td>
                    <td>To</td>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <input
                      className="form-control"
                      type="text"
                      name="name"
                      value={this.state.name}
                      onChange={this.onChange}
                      placeholder="Name of the Committee / Scheme"
                    />
                  </td>
                  <td>
                    <input
                      className="form-control"
                      type="text"
                      name="position"
                      value={this.state.position}
                      onChange={this.onChange}
                      placeholder="Position/Responsibility/
                      Capacity Hold"
                    />
                  </td>

                  <td>
                    <input
                      className="form-control"
                      type="text"
                      name="from"
                      value={this.state.from}
                      onChange={this.onChange}
                      placeholder="From"
                    />
                  </td>
                  <td>
                    <input
                      className="form-control"
                      type="text"
                      name="till"
                      value={this.state.till}
                      onChange={this.onChange}
                      placeholder="Till"
                    />
                  </td>

                  <td>
                    <input
                      className="form-control"
                      type="text"
                      name="other_info"
                      value={this.state.other_info}
                      onChange={this.onChange}
                      placeholder="Other Information"
                    />
                  </td>

                  
                  <td>
                    <input
                      className="form-control"
                      type="text"
                      name="year"
                      value={this.state.year}
                      onChange={this.onChange}
                      placeholder="Year"
                    />
                  </td>

                  <td>
                    <select
                      className="form-control"
                      type="text"
                      name="quarter"
                      value={this.state.quarter}
                      onChange={this.onChange}
                      placeholder="Quarter"
                    >
                      <option>select</option>
                      {Array.isArray(Q_LIST) && Q_LIST.map((el,index) => <option key={index} value={el}>{el}</option>)}
                    </select>
                  </td>
                  <td>
                  You can upload the files after additions
                  </td>


                  <td>
                    <Button
                      variant="outlined"
                      onClick={this.onAddClick.bind(this, "college_activities")}
                      type="button"
                    >
                      +
                    </Button>
                  </td>
                </tr>
              </tbody>
              <tbody>
                {Array.isArray(this.state.college_activities) && this.state.college_activities.map((el, index) => (
                  <tr key={index}>
                    <td>
                    <input
                      className="form-control"
                      type="text"
                      name="name"
                      value={el.name}
                      onChange={(e) => this.onChange1('name', index, e.target.value)}
                      placeholder="Name"
                    />
                    
                    </td>
                    <td>
                    <input
                      className="form-control"
                      type="text"
                      name="position"
                      value={el.position}
                      onChange={(e) => this.onChange1('position', index, e.target.value)}
                      placeholder="Position"
                    /></td>
                    <td>
                    <input
                      className="form-control"
                      type="text"
                      name="from"
                      value={el.from}
                      onChange={(e) => this.onChange1('from', index, e.target.value)}
                      placeholder="From"
                    /></td>
                    <td> <input
                      className="form-control"
                      type="text"
                      name="till"
                      value={el.till}
                      onChange={(e) => this.onChange1('till', index, e.target.value)}
                      placeholder="Till"
                    /></td>
                    <td>
                    <input
                      className="form-control"
                      type="text"
                      name="other_info"
                      value={el.other_info}
                      onChange={(e) => this.onChange1('other_info', index, e.target.value)}
                      placeholder="Other Info"
                    /></td>

                    <td><input
                      className="form-control"
                      type="text"
                      name="year"
                      value={el.year}
                      onChange={(e) => this.onChange1('year', index, e.target.value)}
                      placeholder="Year"
                    /></td>
                    <td><select
                      className="form-control"
                      type="text"
                      name="quarter"
                      value={el.quarter}
                      onChange={(e) => this.onChange1('quarter', index, e.target.value)}
                      placeholder="quarter"
                    ><option>select</option>
                    {Array.isArray(Q_LIST) && Q_LIST.map((el,index) => <option key={index} value={el}>{el}</option>)}
                      </select>
                      </td>
                    <td style={{whiteSpace: 'nowrap'}}>
                  {Array.isArray(el.file) && el.file.map((el1,index1) => <div key={index1}><a target="_blank" href={`${SERVER_UPLOAD}/${el1.type}/${el1.path}`} style={{fontSize: 10}}>[uploaded] {el1.path}</a></div>)}
                  <FileUploadFaculty files={el.file} onSetFile={(data) => this.onSetFile1(index,data)} type={`FACULTY_CAMPUS_ACTIVITY`} dp={el.file} apikey={`1234`} />
                  </td>

                    <td>
                      <Delete
                        onClick={this.onDelete.bind(
                          this,
                          index,
                          "college_activities"
                        )}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>

 </ExpansionPanelDetails>
 </ExpansionPanel>

 <br />


 <ExpansionPanel expanded={this.state.social_activities_panel} onChange={() => this.onPanelClick('social_activities_panel',!this.state.social_activities_panel)}>
                                        <ExpansionPanelSummary
                                            expandIcon={<ExpandMoreIcon />}
                                        >
                                            <Typography>Click here to Add Extension Activities</Typography>
                                        </ExpansionPanelSummary>
                                        <ExpansionPanelDetails>

            <table width="100%" className="table">
              <thead>
                <tr>
                  <td colSpan={6} align="center">
                    <Typography>Involvement in Extension Activities</Typography>
                  </td>
                </tr>
                <tr>
                  <td rowSpan={2}>Name of the Committee / Scheme</td>

                  <td rowSpan={2}>Position/Responsibility/
Capacity Hold</td>

                  <td colSpan={2}>Period</td>
                  <td rowSpan={2}>Other Information</td>

                  <td rowSpan={2}>Year</td>
                  <td rowSpan={2}>Quarter</td>
                  <td rowSpan={2}>File</td>
                  <td rowSpan={2} align="right">Click + to Add</td>
                </tr>
                <tr>
                    <td>From</td>
                    <td>To</td>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <input
                      className="form-control"
                      type="text"
                      name="name"
                      value={this.state.name}
                      onChange={this.onChange}
                      placeholder="Name of the Committee / Scheme"
                    />
                  </td>
                  <td>
                    <input
                      className="form-control"
                      type="text"
                      name="position"
                      value={this.state.position}
                      onChange={this.onChange}
                      placeholder="Position/Responsibility/
                      Capacity Hold"
                    />
                  </td>

                  <td>
                    <input
                      className="form-control"
                      type="text"
                      name="from"
                      value={this.state.from}
                      onChange={this.onChange}
                      placeholder="From"
                    />
                  </td>
                  <td>
                    <input
                      className="form-control"
                      type="text"
                      name="till"
                      value={this.state.till}
                      onChange={this.onChange}
                      placeholder="Till"
                    />
                  </td>

                  <td>
                    <input
                      className="form-control"
                      type="text"
                      name="other_info"
                      value={this.state.other_info}
                      onChange={this.onChange}
                      placeholder="Other Information"
                    />
                  </td>
                  <td>
                    <input
                      className="form-control"
                      type="text"
                      name="year"
                      value={this.state.year}
                      onChange={this.onChange}
                      placeholder="Year"
                    />
                  </td>

                  <td>
                    <select
                      className="form-control"
                      type="text"
                      name="quarter"
                      value={this.state.quarter}
                      onChange={this.onChange}
                      placeholder="Quarter"
                    >
                      <option>select</option>
                      {Array.isArray(Q_LIST) && Q_LIST.map((el,index) => <option key={index} value={el}>{el}</option>)}
                    </select>
                  </td>
                  <td>
                  You can upload the files after additions
                  </td>

                  <td>
                    <Button
                      variant="outlined"
                      onClick={this.onAddClick.bind(this, "social_activities")}
                      type="button"
                    >
                      +
                    </Button>
                  </td>
                </tr>
              </tbody>
              <tbody>
                {Array.isArray(this.state.social_activities) && this.state.social_activities.map((el, index) => (
                  <tr key={index}>
                    <td>
                    <input
                      className="form-control"
                      type="text"
                      name="name"
                      value={el.name}
                      onChange={(e) => this.onChange2('name', index, e.target.value)}
                      placeholder="Name"
                    />
                    
                    </td>
                    <td>
                    <input
                      className="form-control"
                      type="text"
                      name="position"
                      value={el.position}
                      onChange={(e) => this.onChange2('position', index, e.target.value)}
                      placeholder="Position"
                    /></td>
                    <td>
                    <input
                      className="form-control"
                      type="text"
                      name="from"
                      value={el.from}
                      onChange={(e) => this.onChange2('from', index, e.target.value)}
                      placeholder="From"
                    /></td>
                    <td> <input
                      className="form-control"
                      type="text"
                      name="till"
                      value={el.till}
                      onChange={(e) => this.onChange2('till', index, e.target.value)}
                      placeholder="Till"
                    /></td>
                    <td>
                    <input
                      className="form-control"
                      type="text"
                      name="other_info"
                      value={el.other_info}
                      onChange={(e) => this.onChange2('other_info', index, e.target.value)}
                      placeholder="Other Info"
                    /></td>

                    <td><input
                      className="form-control"
                      type="text"
                      name="year"
                      value={el.year}
                      onChange={(e) => this.onChange2('year', index, e.target.value)}
                      placeholder="Year"
                    /></td>
                    <td><select
                      className="form-control"
                      type="text"
                      name="quarter"
                      value={el.quarter}
                      onChange={(e) => this.onChange2('quarter', index, e.target.value)}
                      placeholder="quarter"
                    ><option>select</option>
                    {Array.isArray(Q_LIST) && Q_LIST.map((el,index) => <option key={index} value={el}>{el}</option>)}
                      </select>
                      </td>                    <td style={{whiteSpace: 'nowrap'}}>
                  {Array.isArray(el.file) && el.file.map((el1,index1) => <div key={index1}><a target="_blank" href={`${SERVER_UPLOAD}/${el1.type}/${el1.path}`} style={{fontSize: 10}}>[uploaded] {el1.path}</a></div>)}
                  <FileUploadFaculty files={el.file} onSetFile={(data) => this.onSetFile(index,data)} type={`FACULTY_EXTENSION_ACTIVITY`} dp={el.file} apikey={`1234`} />
                  </td>
                    <td>
                      <Delete
                        onClick={this.onDelete.bind(
                          this,
                          index,
                          "social_activities"
                        )}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>

 </ExpansionPanelDetails>
 </ExpansionPanel>
 <br />

            <div align="right">
              <Button size="sm" variant="info" type="submit">
                Submit
              </Button>
            </div>
          </form>
        )}
      </div>
    );
  }
}

export default FacultyInvolvement;
