import React, { Component } from 'react';
import './css/welcome.css';
import { GetData, PostData } from '../../api/service';
import TopLabel from '../DashBoard/TopLabel';


export default class Welcome extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: ''
    }
  }


  
  
  render() {
    return (
      <div>
        <iframe src="https://pragjyotishcollege.ac.in" width="100%" height="1000px" />
      </div>
      
    )
  }
}
