import React, { Component } from 'react'
import {
  withStyles,
  Drawer,
  Typography,
  Divider,
  TextField,
  Button,
  AppBar,
  Toolbar,
  IconButton,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Grid,
} from '@material-ui/core'
import { GetData } from '../../../api/service'
import Sel from 'react-select'
import FileUpload from '../../AddNew/FileUpload'

const drawerWidth = 500

const styles = (theme) => ({
  root: {
    width: drawerWidth,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: '100%',
  },
  textStyle: {
    fontWeight: 400,
  },
  paddingForm: {
    padding: '20px',
  },
  formControl: {
    minWidth: '100%',
  },
})

class GroupAdd extends Component {
  constructor(props) {
    super(props)

    this.state = {
      sub_sub_group_id: '',
      sr_no: '',
      title: '',
      upload_info: [],
      id: '',
      groups: [],
      isGroupLoaded: false,
    }

    this.onSubmit = this.onSubmit.bind(this)
    this.onChange = this.onChange.bind(this)
  }

  onChange(e) {
    this.setState({
      [e.target.name]: e.target.value,
    })
  }


  handleChange = (name, value) => {
    this.setState({
        [name]: value
    })
}

  componentDidMount() {
    if (this.props.edit) {
      this.setState({
        ...this.props.edit_data,
      })
    } else {
      this.setState({
        sub_sub_group_id: '',
      sr_no: '',
      title: '',
      upload_info: [],
        id: '',
      })
    }

    //get list
    GetData('/getsubsubgroup').then((resp) => {
      //console.log(resp)
      this.setState({
        groups: resp,
        isGroupLoaded: true,
      })
    })
  }

  componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {
      if (this.props.edit) {
        this.setState({
          ...this.props.edit_data,
        })
      } else {
        this.setState({
          sub_sub_group_id: '',
          sr_no: '',
          title: '',
          upload_info: [],
          id: '',
        })
      }
    }
  }

  onSubmit(e) {
    e.preventDefault()

    let { id, sub_sub_group_id, sr_no, title, upload_info } = this.state

    const data = {
      id,
      sub_sub_group_id,
      sr_no,
      title,
      upload_info
    }

    if (this.props.edit) {
      this.props.esetData(data)
    } else {
      this.props.setData(data)
    }

    this.setState({
      sub_sub_group_id: '',
          sr_no: '',
          title: '',
          upload_info: [],
      id: '',
    })
  }

  onDeleteClick(id) {
    this.props.onDeleteClick(id)
  }

  render() {
    let { classes } = this.props
    let teachers = []
    if (this.state.isGroupLoaded) {
      let t = this.state.groups
      if (Array.isArray(t) && t.length > 0) {
        teachers = t.map((el, index) => (
          <MenuItem key={index} value={el.id}>
            {el.name} - {el.criterion} - {el.type} - {el.sr_no}
          </MenuItem>
        ))
      }
    }
    return (
      <div>
        <Drawer
          className={classes.root}
          anchor="right"
          open={this.props.show}
          onClose={this.props.handleClose}
          classes={{
            paper: classes.drawerPaper,
          }}
        >
          <AppBar
            position="static"
            color={this.props.edit ? 'secondary' : 'primary'}
          >
            <Toolbar>
              <Typography variant="h6" color="inherit">
                {this.props.edit ? 'Edit DATA' : 'Add New'}
              </Typography>
            </Toolbar>
          </AppBar>

          <div className={classes.paddingForm}>
            <form onSubmit={this.onSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={12} lg={12}>
                  <FormControl className={classes.formControl}>
                    <InputLabel shrink className={classes.textStyle}>
                      SSR - Criterion
                    </InputLabel>
                    <Select
                      value={this.state.sub_sub_group_id}
                      onChange={this.onChange}
                      name="sub_group_id"
                      inputProps={{
                        name: 'sub_sub_group_id',
                      }}
                    >
                      <MenuItem value="">
                        <em>None</em>
                      </MenuItem>
                      {teachers}
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={12} lg={12}>
                  <TextField
                    label="Sr No"
                    name="sr_no"
                    className={classes.textField}
                    type="text"
                    autoComplete="current-password"
                    margin="normal"
                    required
                    InputLabelProps={{
                      classes: {
                        root: classes.textStyle,
                      },
                    }}
                    value={this.state.sr_no}
                    onChange={this.onChange}
                  />
                </Grid>

                <Grid item xs={12} lg={12}>
                  <TextField
                    label="Title"
                    name="title"
                    className={classes.textField}
                    type="text"
                    autoComplete="current-password"
                    margin="normal"
                    required
                    InputLabelProps={{
                      classes: {
                        root: classes.textStyle,
                      },
                    }}
                    value={this.state.title}
                    onChange={this.onChange}
                  />
                </Grid>

                <Grid item xs={12} lg={12}>
                <FileUpload
                                        dp={this.state.upload_info}
                                        type={`SSR_FILE`}
                                        setDp={this.handleChange.bind(this, 'upload_info')}
                                    />
                </Grid>
              </Grid>
              <br />
              <div>
                <Button
                  type="submit"
                  variant="contained"
                  color={this.props.edit ? 'secondary' : 'primary'}
                  className={classes.button}
                >
                  {this.props.edit ? 'Update' : 'Submit'}
                </Button>

                {this.props.edit ? (
                  <span>
                    &nbsp;
                    <Button
                      type="button"
                      variant="contained"
                      color="secondary"
                      className={classes.button}
                      onClick={this.onDeleteClick.bind(
                        this,
                        this.props.edit_data.id,
                      )}
                    >
                      Delete
                    </Button>
                  </span>
                ) : null}
              </div>
            </form>
          </div>
        </Drawer>
      </div>
    )
  }
}

export default withStyles(styles)(GroupAdd)
